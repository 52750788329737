import dayjs from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import {
  ApplicationVerifier, Auth, AuthCredential, PhoneAuthProvider,
  signInWithCredential, signInWithPhoneNumber,
} from '@angular/fire/auth';
import { AuthenticationResult } from '../models/authentication-result';
import { AuthenticatorType } from '../models/authenticator-type';
import { Authenticator } from './authenticator';
import { PhoneVerificationResult } from '../models/phone-verification-result';
import { isNullOrUndefined, Completer, isNotNullOrUndefined } from 'in-time-core';
import { parseFirebaseAuthError } from '../models/auth-error-utils';
import { logInfo, logWarning, logError } from 'in-time-logger';
import { ErrorType } from '../../../core/models/error-type';

export type SmsCodeVerificationCallback = () => Promise<string | null>;
export type PhoneVerificationCallback = () => PhoneVerificationResult | null;
export type VerificationCodeCallback = (verificationResult: PhoneVerificationResult) => void;

function delayed(duration: Duration, handler: () => void): void {
  setTimeout(handler, duration.asMilliseconds());
}

export class PhoneAuthenticator implements Authenticator {
  static readonly kDefaultPhoneVerificationTimeout: Duration = dayjs.duration({ seconds: 60 });

  constructor(
    private readonly auth: Auth,
    private readonly appVerifier: ApplicationVerifier,
    public readonly phoneNumber: string,
    public readonly codeRequestedFromUser: SmsCodeVerificationCallback,
    public readonly verificationResultRequested: PhoneVerificationCallback,
    public readonly codeSentByServer: VerificationCodeCallback | null = null,
    public readonly verificationFailed: VoidFunction | null = null,
    public readonly onCancel: VoidFunction | null = null,
    public readonly onSignInWillBegin: VoidFunction | null = null,
    public readonly onSignInWillEnd: VoidFunction | null = null,
    public readonly verificationTimeout: Duration = PhoneAuthenticator.kDefaultPhoneVerificationTimeout,
  ) {}

  get type(): AuthenticatorType {
    return AuthenticatorType.Phone;
  }

  async signIn(): Promise<AuthenticationResult> {
    let verificationResult = this.verificationResultRequested();

    if(isNullOrUndefined(verificationResult) || !verificationResult.success) {
      verificationResult = await PhoneAuthenticator.verifyPhoneNumber(
        this.auth,
        this.appVerifier,
        this.phoneNumber,
        this.verificationTimeout
      );
      if(!verificationResult.success) {
        return AuthenticationResult.error(verificationResult.error ?? ErrorType.InternalAuthError);
      }

      if(isNotNullOrUndefined(this.codeSentByServer)) {
        this.codeSentByServer(verificationResult);
      }
    }
    else {
      logInfo('Phone verification ID has been provided and will not be requested again.');
      logInfo('Trying to sign in with provided verification ID...');
    }

    let signInResult: AuthenticationResult | null = null;
    while(isNullOrUndefined(signInResult)) {
      const smsCode = await this.codeRequestedFromUser();
      if(isNotNullOrUndefined(smsCode)) {
        if(isNotNullOrUndefined(this.onSignInWillBegin)) {
          this.onSignInWillBegin();
        }

        try {
          verificationResult = this.verificationResultRequested() ?? verificationResult;

          const verificationId = verificationResult.verificationId;
          if(isNullOrUndefined(verificationId)) {
            throw new Error('verificationId is null or undefined');
          }

          const authCredential = PhoneAuthenticator.createCredential(verificationId, smsCode);
          const userCredential = await signInWithCredential(this.auth, authCredential);
          signInResult = AuthenticationResult.authenticated(userCredential.user, this.type);
        }
        catch(error) {
          logError(`Failed to sign in with phone: ${error}`);

          const errorType = parseFirebaseAuthError(error);
          if(errorType == ErrorType.InvalidPhoneVerificationCode) {
            if(isNotNullOrUndefined(this.verificationFailed)) {
              this.verificationFailed();
            }
            signInResult = null;
          }
          else {
            signInResult = AuthenticationResult.error(errorType);
          }
        }

        if(isNotNullOrUndefined(this.onSignInWillEnd)) {
          this.onSignInWillEnd();
        }
      }
      else {
        if(isNotNullOrUndefined(this.onCancel)) {
          this.onCancel();
        }

        logWarning('Phone sign in canceled by user.');
        signInResult = AuthenticationResult.canceledByUser();
      }
    }

    return signInResult;
  }

  signUp(): Promise<AuthenticationResult> {
    return this.signIn();
  }

  static async verifyPhoneNumber(
    auth: Auth,
    appVerifier: ApplicationVerifier,
    phoneNumber: string,
    verificationTimeout: Duration = this.kDefaultPhoneVerificationTimeout
  ): Promise<PhoneVerificationResult> {
    try {
      return await this.tryPhoneNumberVerification(auth, appVerifier, phoneNumber, verificationTimeout);
    }
    catch(error) {
      logError(`Failed to verify phone number: ${error}`);
      return PhoneVerificationResult.error(phoneNumber, ErrorType.InternalPhoneAuthError);
    }
  }

  static async resendVerificationCode(
    auth: Auth,
    appVerifier: ApplicationVerifier,
    phoneNumber: string,
    verificationTimeout: Duration = this.kDefaultPhoneVerificationTimeout
  ): Promise<PhoneVerificationResult> {
    try {
      return await this.tryPhoneNumberVerification(auth, appVerifier, phoneNumber, verificationTimeout);
    }
    catch(error) {
      logError(`Failed to resend verification code: ${error}`);
      return PhoneVerificationResult.error(phoneNumber, ErrorType.InternalPhoneAuthError);
    }
  }

  private static async tryPhoneNumberVerification(
    auth: Auth,
    appVerifier: ApplicationVerifier,
    phoneNumber: string,
    verificationTimeout: Duration = this.kDefaultPhoneVerificationTimeout
  ): Promise<PhoneVerificationResult> {
    const completer = new Completer<PhoneVerificationResult>();

    delayed(verificationTimeout, () => {
      if(!completer.isCompleted) {
        // eslint-disable-next-line max-len
        logWarning('Phone verification took too long. Something might be wrong so we will abort the operation just to be safe.');
        completer.complete(PhoneVerificationResult.error(phoneNumber, ErrorType.PhoneVerificationTimeout));
      }
    });

    signInWithPhoneNumber(auth, phoneNumber, appVerifier).then((result) => {
      if(!completer.isCompleted) {
        logInfo(`Phone verification code has been sent to user device: ${phoneNumber}`);
        completer.complete(PhoneVerificationResult.success(phoneNumber, result.verificationId));
      }
    }).catch((error) => {
      logError('Phone number verification failed: ', error);
      if(!completer.isCompleted) {
        completer.complete(PhoneVerificationResult.error(phoneNumber, parseFirebaseAuthError(error)));
      }
    });

    return completer.promise;
  }

  static createCredential(verificationId: string, smsCode: string): AuthCredential {
    return PhoneAuthProvider.credential(verificationId, smsCode);
  }
}
