import dayjs from 'dayjs';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { CookieService } from './cookie.service';
import { NavigationEnd, Params, Router, Event as RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { TrueTime, isNullOrUndefined } from 'in-time-core';
import { COOKIE_UTM_PARAMETERS } from '../core/utils/cookie-keys';

@Injectable({
  providedIn: 'root'
})
export class UtmParamsService implements OnDestroy {
  private static readonly kUtmParamsCookieExpirationDuration = dayjs.duration(30, 'minutes');

  private readonly cookieService = inject(CookieService);

  private subscription: Subscription | null = null;
  private isInitialized = false;

  initialize(router: Router): void {
    if(this.isInitialized) {
      return;
    }

    this.subscription = router.events.subscribe((event) => {
      this.handleRouterEvent(event, router);
    });

    this.isInitialized = true;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  getUtmParams(): { [key: string]: string } {
    const utmParamsJSON = this.cookieService.get(COOKIE_UTM_PARAMETERS);
    if(utmParamsJSON == null) {
      return {};
    }

    try {
      const utmParams = JSON.parse(utmParamsJSON);
      if(isNullOrUndefined(utmParams)) {
        return {};
      }

      if(typeof utmParams !== 'object') {
        return {};
      }

      return utmParams;
    }
    catch{
      return {};
    }
  }

  private handleRouterEvent(event: RouterEvent, router: Router): void {
    if(!(event instanceof NavigationEnd)) {
      return;
    }

    const route = router.routerState.root.firstChild;
    const params = route?.snapshot?.queryParams;

    if(isNullOrUndefined(params)) {
      return;
    }

    const keys = Object.keys(params);
    if(keys.length === 0 && params.constructor === Object) {
      return;
    }

    const hasUtmParams = keys.some((key) => {
      return key.startsWith('utm');
    });

    if(!hasUtmParams) {
      return;
    }

    this.saveUtmParams(params);
  }

  private saveUtmParams(params: Params): void {
    const stringifiedParams = JSON.stringify(params);
    this.cookieService.set(
      COOKIE_UTM_PARAMETERS,
      stringifiedParams, {
        expires: UtmParamsService.getCookieExpirationDate(),
      }
    );
  }

  private static getCookieExpirationDate(): Date {
    return TrueTime.now().add(UtmParamsService.kUtmParamsCookieExpirationDuration).toDate();
  }
}