import { User } from '@angular/fire/auth';
import { AuthenticatorType } from './authenticator-type';
import { isNotNullOrUndefined } from 'in-time-core';
import { ErrorType } from '../../../core/models/error-type';

export class AuthenticationState {
  constructor(
    public readonly isAuthenticated: boolean,
    public readonly isDeveloper: boolean,
    public readonly error: ErrorType | null,
    public readonly user: User | null,
    public readonly authenticators: AuthenticatorType[],
  ) {}

  get isError(): boolean {
    return isNotNullOrUndefined(this.error);
  }

  get isAnonymous(): boolean {
    return this.user?.isAnonymous ?? false;
  }

  get canceledByUser(): boolean {
    return this.error === ErrorType.CanceledByUser;
  }

  hasAuthenticator(authenticator: AuthenticatorType): boolean {
    return this.authenticators.some((e) => e === authenticator);
  }

  onlyAuthenticator(authenticator: AuthenticatorType): boolean {
    return this.authenticators.length === 1 && this.authenticators[0] === authenticator;
  }

  static none(): AuthenticationState {
    return new AuthenticationState(false, false, null, null, []);
  }

  static canceledByUser(): AuthenticationState {
    return new AuthenticationState(false, false, ErrorType.CanceledByUser, null, []);
  }

  static authenticated(user: User, isDeveloper: boolean, authenticators: AuthenticatorType[]): AuthenticationState {
    return new AuthenticationState(true, isDeveloper, null, user, authenticators);
  }

  static error(error: ErrorType, authenticators: AuthenticatorType[]): AuthenticationState {
    return new AuthenticationState(false, false, error, null, authenticators);
  }

  public toString(): string {
    return JSON.stringify({
      isAuthenticated: this.isAuthenticated,
      error: this.error,
      user: this.user?.uid ?? null,
      authenticators: this.authenticators,
      isDeveloper: this.isDeveloper,
    });
  }
}
