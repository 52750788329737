/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { logError, logInfo } from 'in-time-logger';
import { AnalyticsTracker } from './analytics-tracker';
import { COOKIE_BARION_CONSENT } from '../../core/utils/cookie-keys';
import { Renderer2 } from '@angular/core';
import { fromEnumAsString } from 'in-time-core';
import {
  BARION_EVT, BARION_EVT_STD_ADD_TO_CART, BARION_EVT_STD_INITIATE_CHECKOUT,
  BARION_EVT_STD_INITIATE_PURCHASE, BARION_EVT_STD_PURCHASE
} from './analytics.events';
import {
  AddToCartEventArgs, AnalyticsTrackerType, CompleteRegistrationEventArgs, ContentViewEventArgs,
  InitiateCheckoutEventArgs, InitiatePurchaseEventArgs, PageViewEventArgs, PromoCodeEventArgs,
  PurchaseCompleteEventArgs, PurchaseFailedEventArgs, SearchEventArgs, SignUpEventArgs, PurchaseCanceledEventArgs,
  ExploreEventArgs,
} from './analytics.types';
import { CookieService } from '../cookie.service';

const BARION_PIXEL_SCRIPT_ID: string = 'barion-pixel-script';

declare const bp: any;

export class BarionTracker extends AnalyticsTracker {
  private hasAcceptedCookies: boolean = false;
  private hasBeenInitialized: boolean = false;

  public readonly $typeId: AnalyticsTrackerType = 'barion';

  static create(opts: {
    cookieService: CookieService,
    renderer: Renderer2,
    document: Document,
    pixelId: string,
  }): BarionTracker {
    return new BarionTracker(opts.cookieService, opts.renderer, opts.pixelId, opts.document);
  }

  private constructor(
    private readonly cookieService: CookieService,
    private readonly renderer: Renderer2,
    private readonly pixelId: string,
    private readonly _document: Document,
  ) {
    super();
  }

  onInit(hasAcceptedCookies: boolean): void {
    const barionCode = `
    window["bp"] = window["bp"] || function () {
      (window["bp"].q = window["bp"].q || []).push(arguments);
    };
    window["bp"].l = 1 * new Date();
    scriptElement = document.createElement("script");
    firstScript = document.getElementsByTagName("script")[0];
    scriptElement.async = true;
    scriptElement.src = 'https://pixel.barion.com/bp.js';
    firstScript.parentNode.insertBefore(scriptElement, firstScript);
    window['barion_pixel_id'] = '${this.pixelId}';            
    bp('init', 'addBarionPixelId', window['barion_pixel_id']);`;

    const scriptElement = this.renderer.createElement('script');
    this.renderer.setAttribute(scriptElement, 'id', BARION_PIXEL_SCRIPT_ID);
    this.renderer.setAttribute(scriptElement, 'type', 'text/javascript');
    this.renderer.setProperty(scriptElement, 'innerHTML', barionCode);
    this.renderer.appendChild(this._document.head, scriptElement);

    const barionImage = this.renderer.createElement('img');
    this.renderer.setAttribute(barionImage, 'height', '1');
    this.renderer.setAttribute(barionImage, 'width', '1');
    this.renderer.setStyle(barionImage, 'display', 'none');
    this.renderer.setAttribute(barionImage, 'alt', 'Barion Pixel');
    this.renderer.setAttribute(barionImage, 'src', `https://pixel.barion.com/a.gif?ba_pixel_id='${this.pixelId}'&ev=contentView&noscript=1`);

    const imageElement = this.renderer.createElement('noscript');
    this.renderer.appendChild(imageElement, barionImage);
    this.renderer.appendChild(this._document.head, imageElement);

    this.hasAcceptedCookies = hasAcceptedCookies;
    this.hasBeenInitialized = true;
    logInfo('Barion pixel script has been added!');
  }

  onDestroy(): void {
    // nothing to do here :)
  }

  onCookiesAccepted(): void {
    this.hasAcceptedCookies = true;
    this.cookieService.set(COOKIE_BARION_CONSENT, '1');
    bp('consent', 'grantConsent');
    logInfo('Barion consent has been granted!');
  }

  onCookiesDeclined(): void {
    this.cookieService.delete(COOKIE_BARION_CONSENT);
    this.hasAcceptedCookies = false;
    bp('consent', 'rejectConsent');
    logInfo('Barion consent has been rejected!');
  }

  trackPromoCode(args: PromoCodeEventArgs): void {
    // nothing to do here :)
  }

  trackSignUp(args: SignUpEventArgs): void {
    // nothing to do here :)
  }

  trackPageView(args: PageViewEventArgs): void {
    // nothing to do here :)
  }

  trackContentView(args: ContentViewEventArgs): void {
    // nothing to do here :)
  }

  trackSearch(args: SearchEventArgs): void {
    // nothing to do here :)
  }

  trackExplore(args: ExploreEventArgs): void {
    // nothing to do here :)
  }

  trackAddToCart(args: AddToCartEventArgs): void {
    this.track(BARION_EVT_STD_ADD_TO_CART, {
      contentType: 'Product',
      currency: fromEnumAsString(args.cartPrice.currency),
      id: args.eventId,
      event_name: args.eventName,
      quantity: 1,
      unit: 'pcs',
      unitPrice: args.cartPrice.asDouble,
      totalItemPrice: args.cartPrice.asDouble,
      contents: args.cart.items.map((item) => ({
        contentType: 'Product',
        currency: fromEnumAsString(item.totalPrice.currency),
        id: item.uniqueId,
        name: item.name.getAny(args.language) ?? item.uniqueId,
        quantity: item.quantity,
        unit: 'pcs',
        unitPrice: item.unitPrice.asDouble,
        totalItemPrice: item.totalPrice.asDouble,
      })),
    });
  }

  trackInitiateCheckout(args: InitiateCheckoutEventArgs): void {
    this.track(BARION_EVT_STD_INITIATE_CHECKOUT, {
      contentType: 'Product',
      currency: fromEnumAsString(args.cartPrice.currency),
      revenue: args.cartPrice.asDouble,
      step: 1,
      contents: args.cart.items.map((item) => ({
        contentType: 'Product',
        currency: fromEnumAsString(item.totalPrice.currency),
        id: item.uniqueId,
        name: item.name.getAny(args.language) ?? item.uniqueId,
        quantity: item.quantity,
        unit: 'pcs',
        unitPrice: item.unitPrice.asDouble,
        totalItemPrice: item.totalPrice.asDouble,
      })),
    });
  }

  trackCompleteRegistration(args: CompleteRegistrationEventArgs): void {
    // nothing to do here :)
  }

  trackInitiatePurchase(args: InitiatePurchaseEventArgs): void {
    this.track(BARION_EVT_STD_INITIATE_PURCHASE, {
      contentType: 'Product',
      currency: fromEnumAsString(args.cartPrice.currency),
      revenue: args.cartPrice.asDouble,
      step: 2,
      contents: args.cart.items.map((item) => ({
        contentType: 'Product',
        currency: fromEnumAsString(item.totalPrice.currency),
        id: item.uniqueId,
        name: item.name.getAny(args.language) ?? item.uniqueId,
        quantity: item.quantity,
        unit: 'pcs',
        unitPrice: item.unitPrice.asDouble,
        totalItemPrice: item.totalPrice.asDouble,
      })),
    });
  }

  trackPurchaseComplete(args: PurchaseCompleteEventArgs): void {
    this.track(BARION_EVT_STD_PURCHASE, {
      contentType: 'Product',
      currency: fromEnumAsString(args.cartPrice.currency),
      revenue: args.cartPrice.asDouble,
      step: 3,
      contents: args.cart.items.map((item) => ({
        contentType: 'Product',
        currency: fromEnumAsString(item.totalPrice.currency),
        id: item.uniqueId,
        name: item.name.getAny(args.language) ?? item.uniqueId,
        quantity: item.quantity,
        unit: 'pcs',
        unitPrice: item.unitPrice.asDouble,
        totalItemPrice: item.totalPrice.asDouble,
      })),
    });
  }

  trackPurchaseFailed(args: PurchaseFailedEventArgs): void {
    this.track(BARION_EVT_STD_PURCHASE, {
      contentType: 'Product',
      currency: fromEnumAsString(args.cartPrice.currency),
      revenue: args.cartPrice.asDouble,
      step: -1,
      contents: args.cart.items.map((item) => ({
        contentType: 'Product',
        currency: fromEnumAsString(item.totalPrice.currency),
        id: item.uniqueId,
        name: item.name.getAny(args.language) ?? item.uniqueId,
        quantity: item.quantity,
        unit: 'pcs',
        unitPrice: item.unitPrice.asDouble,
        totalItemPrice: item.totalPrice.asDouble,
      })),
    });
  }

  trackPurchaseCanceled(args: PurchaseCanceledEventArgs): void {
    this.track(BARION_EVT_STD_PURCHASE, {
      contentType: 'Product',
      currency: fromEnumAsString(args.cartPrice.currency),
      revenue: args.cartPrice.asDouble,
      step: -1,
      contents: args.cart.items.map((item) => ({
        contentType: 'Product',
        currency: fromEnumAsString(item.totalPrice.currency),
        id: item.uniqueId,
        name: item.name.getAny(args.language) ?? item.uniqueId,
        quantity: item.quantity,
        unit: 'pcs',
        unitPrice: item.unitPrice.asDouble,
        totalItemPrice: item.totalPrice.asDouble,
      })),
    });
  }

  private track(eventId: BARION_EVT, parameters: unknown): void {
    if(!this.hasBeenInitialized) return;
    if(!this.hasAcceptedCookies) return;

    try {
      this.ensureBarionConsent();
      bp('track', eventId, parameters);
      logInfo(`Tracking Barion event {${eventId}} with params: ${JSON.stringify(parameters, null, 2)}`);
    }
    catch(error) {
      logError(`Failed to track Barion event {${eventId}} with params: ${JSON.stringify(parameters, null, 2)}`, error);
    }
  }

  private ensureBarionConsent(): void {
    const hasGivenBarionConsent = this.cookieService.get(COOKIE_BARION_CONSENT) ? true : false;
    if(!hasGivenBarionConsent) {
      this.cookieService.set(COOKIE_BARION_CONSENT, '1');
      bp('consent', 'grantConsent');
      logInfo('Barion consent has been granted!');
    }
  }
}