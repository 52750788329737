import { User } from '@angular/fire/auth';
import { isNullOrUndefined, isNullOrUndefinedOrEmpty } from 'in-time-core';

export function extractTopLevelDomainFromHost(host: string | null | undefined): string | null {
  if(isNullOrUndefinedOrEmpty(host)) {
    return null;
  }

  const parts = host.split('.');
  return parts.length > 1 ? parts[parts.length - 1] : null;
}

export function extractFirstAndLastNameOrNull(
  displayName: string | null | undefined
): { firstName: string | null, lastName: string | null} | null {
  if(displayName == null) {
    return null;
  }

  return extractFirstAndLastName(displayName);
}

export function extractFirstAndLastName(displayName: string): { firstName: string | null, lastName: string | null} {
  let firstName: string | null = null;
  let lastName: string | null = null;

  displayName = displayName?.trim();

  if(displayName != null && displayName.length > 0) {
    const indexOfSpace: number = displayName.lastIndexOf(' ');
    if(indexOfSpace > 0) {
      firstName = displayName.substring(0, indexOfSpace);
      lastName = displayName.substring(indexOfSpace + 1, displayName.length);

      lastName = lastName.length > 0 ? lastName : null;
    }
    else {
      firstName = displayName.trim();
      firstName = firstName.length > 0 ? firstName : null;
    }
  }

  return { firstName, lastName };
}

export function extractFullNameFromFirebaseUser(user: User | null): string | null {
  if(isNullOrUndefined(user)) {
    return null;
  }

  let fullName: string | null = user.displayName;
  for(let i = 0; i < user.providerData.length && isNullOrUndefinedOrEmpty(fullName); i++) {
    fullName = user.providerData[i].displayName;
  }

  return isNullOrUndefinedOrEmpty(fullName) ? null : fullName;
}

export function extractEmailFromFirebaseUser(user: User | null): string | null {
  if(isNullOrUndefined(user)) {
    return null;
  }

  let email: string | null = user.email;
  for(let i = 0; i < user.providerData.length && isNullOrUndefinedOrEmpty(email); i++) {
    email = user.providerData[i].email;
  }

  return isNullOrUndefinedOrEmpty(email) ? null : email;
}