import { Injectable, OnDestroy, inject } from '@angular/core';
import { CustomerSubscription } from 'in-time-core';
import { AuthenticationService } from './authentication.service';
import { DatabaseService } from './database.service';
import { User } from '@angular/fire/auth';
import { IAuthenticationPlugin } from './auth/utility/authentication-plugin';
import { AuthenticationState } from './auth/models/authentication-state';

@Injectable({
  providedIn: 'root'
})
export class BusinessSubscriptionService implements OnDestroy {
  private readonly subscriptionServiceImpl: BusinessSubscriptionServiceImpl;
  private readonly authenticationService = inject(AuthenticationService);
  private readonly databaseService = inject(DatabaseService);

  constructor() {
    this.subscriptionServiceImpl = new BusinessSubscriptionServiceImpl(this.databaseService);
    this.authenticationService.addPlugin(this.subscriptionServiceImpl);
  }

  addSubscription(subscription: CustomerSubscription): void {
    this.subscriptionServiceImpl.addSubscription(subscription);
  }

  getSubscription(businessId: string): CustomerSubscription | null {
    return this.subscriptionServiceImpl.getSubscription(businessId);
  }

  addInvite(invite: CustomerSubscription): void {
    this.subscriptionServiceImpl.addInvite(invite);
  }

  hasInvitationForBusiness(businessId: string): boolean {
    return this.subscriptionServiceImpl.hasInvitationForBusiness(businessId);
  }

  hasSubscriptionForBusiness(businessId: string): boolean {
    return this.subscriptionServiceImpl.hasSubscriptionForBusiness(businessId);
  }

  isBlacklistedByBusiness(businessId: string): boolean {
    return this.subscriptionServiceImpl.isBlacklistedByBusiness(businessId);
  }

  ngOnDestroy(): void {
    this.authenticationService.removePlugin(this.subscriptionServiceImpl);
  }
}

class BusinessSubscriptionServiceImpl implements IAuthenticationPlugin {
  private invites: Map<string, CustomerSubscription>;
  private subscriptions: Map<string, CustomerSubscription>;
  private isAuthenticated: boolean = false;

  constructor(private readonly databaseService: DatabaseService) {
    this.invites = new Map<string, CustomerSubscription>();
    this.subscriptions = new Map<string, CustomerSubscription>();
  }

  async onCheckSignIn(user: User): Promise<boolean | null> {
    return null;
  }

  canAddSubscription(subscription: CustomerSubscription): boolean {
    if(this.isAuthenticated) {
      return this.subscriptions.has(subscription.uniqueId);
    }

    return false;
  }

  addSubscription(subscription: CustomerSubscription): void {
    if(this.isAuthenticated) {
      this.subscriptions.set(subscription.uniqueId, subscription);
    }
  }

  getSubscription(businessId: string): CustomerSubscription | null {
    return this.subscriptions.get(businessId) ?? null;
  }

  addInvite(invite: CustomerSubscription): void {
    if(this.isAuthenticated) {
      this.invites.set(invite.uniqueId, invite);
    }
  }

  hasInvitationForBusiness(businessId: string): boolean {
    return this.invites.has(businessId);
  }

  hasSubscriptionForBusiness(businessId: string): boolean {
    return this.subscriptions.has(businessId);
  }

  isBlacklistedByBusiness(businessId: string): boolean {
    return this.subscriptions.get(businessId)?.isBlacklisted ?? false;
  }

  async onWillSignIn(): Promise<void> {
    // nothing to do here!
  }

  async onSignIn(authState: AuthenticationState): Promise<void> {
    if(authState.isAuthenticated && authState.user != null) {
      let response = await this.databaseService.fetchClientSubscriptions(authState.user.uid);
      if(response.success) {
        response.data.forEach((sub: CustomerSubscription) => this.subscriptions.set(sub.uniqueId, sub));
      }

      response = await this.databaseService.fetchClientInvites(authState.user.uid);
      if(response.success) {
        response.data.forEach((sub: CustomerSubscription) => this.invites.set(sub.uniqueId, sub));
      }

      this.isAuthenticated = true;
    }
  }

  async onSignOut(): Promise<void> {
    this.isAuthenticated = false;
    this.invites.clear();
    this.subscriptions.clear();
  }
}