import { User } from '@angular/fire/auth';
import { isNullOrUndefined } from 'in-time-core';
import { logWarning } from 'in-time-logger';

export async function isFirebaseUserDeveloper(user: User): Promise<boolean> {
  try {
    const tokenResult = await user.getIdTokenResult();
    if(tokenResult.claims['is_developer'] === true) {
      return true;
    }
  }
  catch(error) {
    logWarning(`Failed to get ID token for FirebaseUser: ${error}`);
  }

  if(isNullOrUndefined(user.email)) {
    return false;
  }

  return user.email.endsWith('@in-time.life') && user.emailVerified;
}
