import { DatabaseIdGenerator } from 'in-time-core';
import { Buffer } from 'buffer';
import md5 from 'crypto-js/md5';
import sha256 from 'crypto-js/sha256';

export function hashString(str: string, algo: 'md5' | 'sha256'): string {
  if(algo == 'md5') {
    return md5(str).toString();
  }
  else if(algo == 'sha256') {
    return sha256(str).toString();
  }
  else {
    throw new Error(`Unknown hashing algorithm: ${algo}`);
  }
}

export function toBase64(data: string): string {
  return Buffer.from(data).toString('base64');
}

export function fromBase64(data: string): string {
  return Buffer.from(data, 'base64').toString();
}

export function createBrowserDatabaseIdGenerator(): DatabaseIdGenerator {
  return new DatabaseIdGenerator((arr) => window.crypto.getRandomValues(arr));
}