
export interface ICloudFunctionsEndpointProvider {
  healthCheck: string;
  syncClientPhoneOrdersOnJoin: string;
  notifyDevelopersAboutJoinRequest: string;
  notifyDevelopersAboutTryRequest: string;
  notifyBusinessOfFormSent: string;
  createEmailVerificationCode: string,
  canSignIn: string,
  canSignUp: string,
  fetchDiscoverPageInfo: string,
}

export const FirebaseCloudFunctionsEndpointProvider: ICloudFunctionsEndpointProvider = {
  healthCheck: 'healthCheck',
  syncClientPhoneOrdersOnJoin: 'syncClientPhoneOrdersOnJoin',
  notifyDevelopersAboutJoinRequest: 'notifyDevelopersAboutJoinRequest',
  notifyDevelopersAboutTryRequest: 'notifyDevelopersAboutTryRequest',
  notifyBusinessOfFormSent: 'notifyBusinessOfFormSent',
  createEmailVerificationCode: 'createEmailVerificationCode',
  canSignIn: 'canSignIn',
  canSignUp: 'canSignUp',
  fetchDiscoverPageInfo: 'fetchDiscoverPageInfo',
};
