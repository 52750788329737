import { Injectable, inject } from '@angular/core';
import { VoidOperationResponse , kGenericBlurHash } from 'in-time-core';

import { CloudStorageService, StorageProgressCallback } from './cloud-storage.service';
import { PhotoUploadResponse } from './models/photo-upload-response';

@Injectable({
  providedIn: 'root'
})
export class PhotoUploadService {
  private readonly storageService = inject(CloudStorageService);

  async upload(
    file: File,
    storagePath: string,
    onProgressChanged?: StorageProgressCallback
  ): Promise<PhotoUploadResponse>{
    const storageResponse = await this.storageService.upload(file, storagePath, onProgressChanged);
    if(!storageResponse.success) {
      return PhotoUploadResponse.error(storageResponse.error);
    }

    return PhotoUploadResponse.success(storageResponse.storagePath, storageResponse.downloadUrl, kGenericBlurHash);
  }

  async delete(storagePath: string): Promise<VoidOperationResponse> {
    return this.storageService.delete(storagePath);
  }
}