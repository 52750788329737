import { ErrorLike, IllegalOperationException, OperationResponse, Result } from 'in-time-core';
import { ResourceStatus } from './resource-status';
import { IResourceStatusSnapshot } from '../contracts/resource-status-snapshot';

export class ResourceSnapshot<T> implements IResourceStatusSnapshot {
  private constructor(
    public readonly status: ResourceStatus,
    private readonly _error: ErrorLike | null,
    private readonly _dataOrNull: T | null,
  ) { }

  get isInitialized(): boolean {
    return this.status !== ResourceStatus.Uninitialized;
  }

  get isLoading(): boolean {
    return this.status === ResourceStatus.Loading;
  }

  get isSuccess(): boolean {
    return this.status === ResourceStatus.Success;
  }

  get isError(): boolean {
    return this.status === ResourceStatus.Error;
  }

  get error(): ErrorLike {
    if(this._error == null) {
      throw new IllegalOperationException('Error is inaccessible on a non-error instance');
    }

    return this._error;
  }

  get data(): T {
    if(this._dataOrNull == null) {
      throw new IllegalOperationException('Data value is inaccessible on a non-success instance');
    }

    return this._dataOrNull;
  }

  static uninitialized<T>(): ResourceSnapshot<T> {
    return new ResourceSnapshot<T>(ResourceStatus.Uninitialized, null, null);
  }

  static loading<T>(): ResourceSnapshot<T> {
    return new ResourceSnapshot<T>(ResourceStatus.Loading, null, null);
  }

  static success<T>(data: T): ResourceSnapshot<T> {
    return new ResourceSnapshot<T>(ResourceStatus.Success, null, data);
  }

  static error<T>(error: ErrorLike): ResourceSnapshot<T> {
    return new ResourceSnapshot<T>(ResourceStatus.Error, error, null);
  }

  static fromResponse<T>(response: OperationResponse<T>): ResourceSnapshot<T> {
    return new ResourceSnapshot<T>(
      response.success ? ResourceStatus.Success : ResourceStatus.Error,
      response.error,
      response.data
    );
  }

  static fromResult<T>(result: Result<T, ErrorLike>): ResourceSnapshot<T> {
    return new ResourceSnapshot<T>(
      result.isOk ? ResourceStatus.Success : ResourceStatus.Error,
      result.isOk ? null: result.error,
      result.isOk ? result.value : null,
    );
  }
}