/* eslint-disable max-len */
import {
  CheckoutRequestResult, CustomerOrderBase, fromDataOrNull, isNotNullOrUndefined, isNullOrUndefined,
  SerializedData, MapUtils, Result, VoidResult,
} from 'in-time-core';

import { logError, logInfo } from 'in-time-logger';
import { Injectable, inject } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { GCPPaymentEndpointProvider, IPaymentEndpointProvider } from './utility/payment-endpoint-provider';
import { CloudFunctionsHttpClient } from './utility/cloud-functions-http-client';
import { CloudFunctionsException, GCPCloudFunctionsProvider, ICloudFunctionsProvider } from 'cloud-functions';
import { environment } from '../app.environment';
import { ErrorType, parsePaymentError } from '../core/models/error-type';
import { createHttpsAuthContext, createEmptyHttpsAuthContext } from '../core/utils/cloud-function-utils';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class OnlinePaymentService {
  private readonly cloudFunctionsProvider: ICloudFunctionsProvider;
  private readonly endpointProvider: IPaymentEndpointProvider;

  private readonly authenticationService = inject(AuthenticationService);
  private readonly translocoService = inject(TranslocoService);

  constructor() {
    this.cloudFunctionsProvider = new GCPCloudFunctionsProvider({
      httpClient: new CloudFunctionsHttpClient(),
      gcpServiceUrl: `${environment.apiGatewayUrl}/payment`,
      appId: 'in-time-web',
      buildId: null,
      operatingSystem: 'browser',
    });

    if(environment.usePaymentEmulator) {
      logInfo('App will use the payment emulator!');
      this.cloudFunctionsProvider.useFunctionsEmulator('http://localhost:8080');
    }

    this.endpointProvider = GCPPaymentEndpointProvider;
  }

  async createCheckoutSession(order: CustomerOrderBase): Promise<Result<CheckoutRequestResult, ErrorType>> {
    if(isNullOrUndefined(order.onlinePaymentDetails)) {
      return Result.error(ErrorType.OrderNotValid);
    }

    const endpoint = this.endpointProvider.createCheckoutSession;

    try {
      const context = await createHttpsAuthContext(this.authenticationService.authState?.user ?? null);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(endpoint, context);

      const response = await callable.call({
        parameters: {
          'orderId': order.uniqueId,
          'locale': this.translocoService.getActiveLang(),
        }
      });

      const success = MapUtils.getOrNull<boolean>(response.data, 'success') ?? false;
      if(success) {
        const checkoutResult = fromDataOrNull<CheckoutRequestResult>(response.data['result'] as SerializedData);
        return isNotNullOrUndefined(checkoutResult) ?
          Result.ok(checkoutResult):
          Result.error(ErrorType.PaymentInternalError);
      }
      else {
        const rawErrorCode = MapUtils.getOrNull<string>(response.data, 'error');
        logError(`Failed to start payment process for order {${order.uniqueId}} of customer {${order.creatorId}} because of a cloud error: ${rawErrorCode}`);

        const errorType = parsePaymentError(rawErrorCode ?? ErrorType.PaymentInternalError);
        return Result.error(errorType);
      }
    }
    catch(error) {
      logError(`Failed to call "${endpoint}" function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return Result.error(ErrorType.FailedToCallCloudFunction);
    }
  }

  async abortCheckoutSession(order: CustomerOrderBase): Promise<VoidResult<ErrorType>> {
    if(isNullOrUndefined(order.onlinePaymentDetails) || isNullOrUndefined(order.onlinePaymentDetails.paymentRequestId)) {
      return VoidResult.error(ErrorType.OrderNotValid);
    }

    const endpoint = this.endpointProvider.abortCheckoutSession;

    try {
      const context = await createHttpsAuthContext(this.authenticationService.authState?.user ?? null);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(endpoint, context);

      const response = await callable.call({
        parameters: { 'orderId': order.uniqueId }
      });

      const success = MapUtils.getOrNull<boolean>(response.data, 'success') ?? false;
      if(success) {
        return VoidResult.ok();
      }
      else {
        const rawErrorCode = MapUtils.getOrNull<string>(response.data, 'error');
        logError(`Failed to abort payment for order {${order.uniqueId}} of customer {${order.creatorId}} because of a cloud error: ${rawErrorCode}`);

        const errorType = parsePaymentError(rawErrorCode ?? ErrorType.PaymentInternalError);
        return Result.error(errorType);
      }
    }
    catch(error) {
      logError(`Failed to call "${endpoint}" function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return VoidResult.error(ErrorType.FailedToCallCloudFunction);
    }
  }

  async createGuestCheckoutSession(order: CustomerOrderBase): Promise<Result<CheckoutRequestResult, ErrorType>> {
    if(isNullOrUndefined(order.onlinePaymentDetails)) {
      return Result.error(ErrorType.OrderNotValid);
    }

    const endpoint = this.endpointProvider.createGuestCheckoutSession;

    try {
      const callable = this.cloudFunctionsProvider.getHttpsCallable(endpoint, createEmptyHttpsAuthContext());
      const response = await callable.call({
        parameters: {
          'guestId': order.creatorId,
          'orderId': order.uniqueId,
          'locale': this.translocoService.getActiveLang(),
        }
      });

      const success = MapUtils.getOrNull<boolean>(response.data, 'success') ?? false;
      if(success) {
        const checkoutResult = fromDataOrNull<CheckoutRequestResult>(response.data['result'] as SerializedData);
        return isNotNullOrUndefined(checkoutResult) ?
          Result.ok(checkoutResult):
          Result.error(ErrorType.PaymentInternalError);
      }
      else {
        const rawErrorCode = MapUtils.getOrNull<string>(response.data, 'error');
        logError(`Failed to start guest payment process for order {${order.uniqueId}} of customer {${order.creatorId}} because of a cloud error: ${rawErrorCode}`);

        const errorType = parsePaymentError(rawErrorCode ?? ErrorType.PaymentInternalError);
        return Result.error(errorType);
      }
    }
    catch(error) {
      logError(`Failed to call "${endpoint}" function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return Result.error(ErrorType.FailedToCallCloudFunction);
    }
  }

  async abortGuestCheckoutSession(order: CustomerOrderBase): Promise<VoidResult<ErrorType>> {
    if(isNullOrUndefined(order.onlinePaymentDetails) || isNullOrUndefined(order.onlinePaymentDetails.paymentRequestId)) {
      return VoidResult.error(ErrorType.OrderNotValid);
    }

    const endpoint = this.endpointProvider.abortGuestCheckoutSession;

    try {
      const callable = this.cloudFunctionsProvider.getHttpsCallable(endpoint, createEmptyHttpsAuthContext());
      const response = await callable.call({
        parameters: { 'paymentRequestId': order.onlinePaymentDetails.paymentRequestId }
      });

      const success = MapUtils.getOrNull<boolean>(response.data, 'success') ?? false;
      if(success) {
        return VoidResult.ok();
      }
      else {
        const rawErrorCode = MapUtils.getOrNull<string>(response.data, 'error');
        logError(`Failed to abort guest payment for order {${order.uniqueId}} of customer {${order.creatorId}} because of a cloud error: ${rawErrorCode}`);

        const errorType = parsePaymentError(rawErrorCode ?? ErrorType.PaymentInternalError);
        return Result.error(errorType);
      }
    }
    catch(error) {
      logError(`Failed to call "${endpoint}" function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return VoidResult.error(ErrorType.FailedToCallCloudFunction);
    }
  }
}