import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum LayoutMode {
  None = 'None',
  MobileOnly = 'MobileOnly',
  DesktopOnly = 'DesktopOnly',
  MobileAndDesktop = 'MobileAndDesktop'
}

export interface LayoutState {
  headerStyle: string | null,
  searchMode: LayoutMode,
  headerMode: LayoutMode,
  showNavbar: boolean,
  showFooter: boolean,
  allowSignIn: boolean,
  showCookiePanel: boolean,
}

interface StackEntry {
  state: LayoutState,
  key: string | null,
}

@Injectable({ providedIn: 'root' })
export class LayoutService implements OnDestroy{
  private readonly stateSnapshots: BehaviorSubject<LayoutState>;
  private readonly layoutStack: StackEntry[];

  get state$(): Observable<LayoutState> {
    return this.stateSnapshots;
  }

  get state(): LayoutState {
    const entry = this.layoutStack[this.layoutStack.length - 1];
    return entry.state;
  }

  constructor() {
    const currentState = {
      headerStyle: null,
      searchMode: LayoutMode.MobileAndDesktop,
      headerMode: LayoutMode.DesktopOnly,
      showNavbar: true,
      showFooter: true,
      allowSignIn: true,
      showCookiePanel: true,
    };

    this.layoutStack = [ { state: currentState, key: '__ROOT' } ];
    this.stateSnapshots = new BehaviorSubject<LayoutState>(currentState);
  }

  ngOnDestroy(): void {
    this.stateSnapshots.complete();
  }

  push(args: {
    key?: string,
    headerStyle?: string | null,
    searchMode?: LayoutMode,
    headerMode?: LayoutMode,
    showNavbar?: boolean,
    showFooter?: boolean,
    showCookiePanel?: boolean,
    allowSignIn?: boolean,
  }): void {
    const currentEntry = this.layoutStack[this.layoutStack.length - 1];
    const state = {
      headerStyle: args.headerStyle !== undefined ? args.headerStyle : currentEntry.state.headerStyle,
      searchMode: args.searchMode !== undefined ? args.searchMode : currentEntry.state.searchMode,
      headerMode: args.headerMode !== undefined ? args.headerMode : currentEntry.state.headerMode,
      showNavbar: args.showNavbar !== undefined ? args.showNavbar : currentEntry.state.showNavbar,
      showFooter: args.showFooter !== undefined ? args.showFooter : currentEntry.state.showFooter,
      showCookiePanel: args.showCookiePanel !== undefined ? args.showCookiePanel : currentEntry.state.showCookiePanel,
      allowSignIn: args.allowSignIn !== undefined ? args.allowSignIn : currentEntry.state.allowSignIn,
    };

    this.layoutStack.push({ state, key: args.key ?? null });
    this.stateSnapshots.next(state);
  }

  pop(args?: { key?: string }): void {
    if(this.layoutStack.length > 1) {
      let currentEntry = this.layoutStack[this.layoutStack.length - 1];
      const key = args?.key ?? null;

      if(key == null || currentEntry.key == key) {
        this.layoutStack.pop();

        currentEntry = this.layoutStack[this.layoutStack.length - 1];
        this.stateSnapshots.next(currentEntry.state);
      }
    }
  }
}