import { User } from '@angular/fire/auth';
import { HttpsAuthContext } from 'cloud-functions';
import { isNullOrUndefined } from 'in-time-core';

export const kFirebaseCloudFunctionsRegion: string = 'europe-west1';    // DO NOT CHANGE THIS VALUE. THE APP WILL BREAK.

export function createEmptyHttpsAuthContext(): HttpsAuthContext {
  return new HttpsAuthContext(null);
}

export async function createHttpsAuthContext(user: User | null): Promise<HttpsAuthContext> {
  if(isNullOrUndefined(user)) {
    return new HttpsAuthContext(null);
  }

  const accessToken = await user.getIdToken();
  return new HttpsAuthContext(accessToken);
}