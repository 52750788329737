import { Injectable, inject } from '@angular/core';
import {
  CloudFunctionsException, FirebaseCloudFunctionsProvider,
  ICloudFunctionsProvider
} from 'cloud-functions';
import {
  BusinessModuleRef, DiscoverPageInfo, MapUtils, OperationResponse,
  SerializedData, VoidOperationResponse, fromData, isNotNullOrUndefined
} from 'in-time-core';
import { AuthenticationService } from './authentication.service';
import { FirebaseCloudFunctionsEndpointProvider, ICloudFunctionsEndpointProvider } from './utility/cloud-functions-endpoint-provider';
import { CloudFunctionsHttpClient } from './utility/cloud-functions-http-client';
import { logError } from 'in-time-logger';
import { AuthenticatorType } from './auth/models/authenticator-type';
import { ErrorType } from '../core/models/error-type';
import { CanSignInResponse, CanSignUpResponse } from './auth/utility/types';
import { environment } from '../app.environment';
import { kFirebaseCloudFunctionsRegion, createHttpsAuthContext } from '../core/utils/cloud-function-utils';

@Injectable({
  providedIn: 'root'
})
export class CloudFunctionsService {
  private readonly cloudFunctionsProvider: ICloudFunctionsProvider;
  private readonly endpointProvider: ICloudFunctionsEndpointProvider;

  private readonly authenticationService = inject(AuthenticationService);

  constructor() {
    this.cloudFunctionsProvider = new FirebaseCloudFunctionsProvider({
      httpClient: new CloudFunctionsHttpClient(),
      projectId: environment.firebase.projectId,
      region: kFirebaseCloudFunctionsRegion,
      appId: 'in-time-web',
      buildId: null,
      operatingSystem: 'browser',
    });

    this.endpointProvider = FirebaseCloudFunctionsEndpointProvider;
  }

  async healthCheck(): Promise<OperationResponse<SerializedData>> {
    const endpoint = this.endpointProvider.healthCheck;

    try {
      const context = await createHttpsAuthContext(this.authenticationService.authState?.user ?? null);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(endpoint, context);

      const response = await callable.call();

      const success = MapUtils.getOrNull<boolean>(response.data, 'success') ?? false;
      if(success) {
        return OperationResponse.success(MapUtils.getOrNull<SerializedData>(response.data, 'data'));
      }
      else {
        const errorType = MapUtils.getOrNull<string>(response.data, 'error') ?? ErrorType.Unknown;
        logError(`Health check failed: ${errorType}`);
        return OperationResponse.error(errorType);
      }
    }
    catch(error) {
      logError(`Failed to call "${endpoint}" function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return OperationResponse.error(ErrorType.FailedToCallCloudFunction);
    }
  }

  async syncPhoneOrderOnJoin(businessRef: BusinessModuleRef): Promise<VoidOperationResponse> {
    const endpoint = this.endpointProvider.syncClientPhoneOrdersOnJoin;

    try {
      const user = this.authenticationService.authState?.user ?? null;
      const context = await createHttpsAuthContext(user);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(endpoint, context);

      const response = await callable.call({
        parameters: {
          'businessRef': businessRef.encode(),
        },
      });

      const success = MapUtils.getOrNull<boolean>(response.data, 'success') ?? false;
      if(success) {
        return VoidOperationResponse.success();
      }
      else {
        const errorType = MapUtils.getOrNull<string>(response.data, 'error') ?? ErrorType.Unknown;
        logError(`Failed to sync phone orders for {${user?.uid}} because of a cloud error: ${errorType}`);
        return VoidOperationResponse.error(errorType);
      }
    }
    catch(error) {
      logError(`Failed to call "${endpoint}" function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return VoidOperationResponse.error(ErrorType.FailedToCallCloudFunction);
    }
  }

  async sendJoinRequestNotificationToDevelopers(request: {
    fullName: string,
    phoneNumber: string,
    location: string,
    businessName: string,
    message: string
  }): Promise<VoidOperationResponse> {
    const endpoint = this.endpointProvider.notifyDevelopersAboutJoinRequest;

    try {
      const context = await createHttpsAuthContext(this.authenticationService.authState?.user ?? null);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(endpoint, context);

      const response = await callable.call({
        parameters: request,
      });

      const success = MapUtils.getOrNull<boolean>(response.data, 'success') ?? false;
      if(success) {
        return VoidOperationResponse.success();
      }
      else {
        const errorType = MapUtils.getOrNull<string>(response.data, 'error') ?? ErrorType.Unknown;
        logError(`Failed to notify developers about join request: ${errorType}`);
        return VoidOperationResponse.error(errorType);
      }
    }
    catch(error) {
      logError(`Failed to call "${endpoint}" function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return VoidOperationResponse.error(ErrorType.FailedToCallCloudFunction);
    }
  }

  async sendFormFromBusiness(
    data: {destinationEmail: string, formValues: {question: string, answer: string}[]}
  ): Promise<VoidOperationResponse> {
    const endpoint = this.endpointProvider.notifyBusinessOfFormSent;

    try {
      const context = await createHttpsAuthContext(this.authenticationService.authState?.user ?? null);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(endpoint, context);
      const serializedData = { 'destinationEmail': data.destinationEmail, 'formValues': data.formValues };

      const response = await callable.call({
        parameters: serializedData,
      });

      const success = MapUtils.getOrNull<boolean>(response.data, 'success') ?? false;
      if(success) {
        return VoidOperationResponse.success();
      }
      else {
        const errorType = MapUtils.getOrNull<string>(response.data, 'error') ?? ErrorType.Unknown;
        logError(`Failed to send form: ${errorType}`);
        return VoidOperationResponse.error(errorType);
      }
    }
    catch(error) {
      logError(`Failed to call "${endpoint}" function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return VoidOperationResponse.error(ErrorType.FailedToCallCloudFunction);
    }
  }

  async sendTryOutNotificationToDevelopers(
    data: {
      businessType: string,
      numberOfEmployees: string,
      name: string,
      phoneNumber: string,
      email: string,
      businessName: string,
      businessLocation: string,
    }): Promise<VoidOperationResponse> {
    const endpoint = this.endpointProvider.notifyDevelopersAboutTryRequest;

    try {
      const context = await createHttpsAuthContext(this.authenticationService.authState?.user ?? null);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(endpoint, context);

      const response = await callable.call({
        parameters: data,
      });

      const success = MapUtils.getOrNull<boolean>(response.data, 'success') ?? false;
      if(success) {
        return VoidOperationResponse.success();
      }
      else {
        const errorType = MapUtils.getOrNull<string>(response.data, 'error') ?? ErrorType.Unknown;
        logError(`Failed to notify developers about try request: ${errorType}`);
        return VoidOperationResponse.error(errorType);
      }
    }
    catch(error) {
      logError(`Failed to call "${endpoint}" function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return VoidOperationResponse.error(ErrorType.FailedToCallCloudFunction);
    }
  }

  async createEmailVerificationCode(
    email: string,
  ): Promise<VoidOperationResponse> {
    const endpoint = this.endpointProvider.createEmailVerificationCode;

    try {
      const context = await createHttpsAuthContext(this.authenticationService.authState?.user ?? null);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(endpoint, context);

      const result = await callable.call({
        parameters: {
          'email': email,
        }
      });

      const success = MapUtils.getOrNull<boolean>(result.data, 'success') ?? false;
      if(!success) {
        const errorType = MapUtils.getOrNull<string>(result.data, 'error') ?? ErrorType.Unknown;
        logError(`Failed to create email verification code: ${errorType}`);
        return VoidOperationResponse.error(errorType);
      }

      return VoidOperationResponse.success();
    }
    catch(error) {
      logError(`Failed to call "${endpoint}" function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return VoidOperationResponse.error(ErrorType.FailedToCallCloudFunction);
    }
  }

  async canSignIn(args: {
    email?: string,
    phoneNumber?: string,
  }): Promise<OperationResponse<CanSignInResponse>> {
    const endpoint = this.endpointProvider.canSignIn;

    try {
      const context = await createHttpsAuthContext(this.authenticationService.authState?.user ?? null);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(endpoint, context);

      const result = await callable.call({
        parameters: {
          'email': args.email ?? null,
          'phoneNumber': args.phoneNumber ?? null,
        },
      });

      const success = MapUtils.getOrNull<boolean>(result.data, 'success') ?? false;
      if(!success) {
        const errorType = MapUtils.getOrNull<string>(result.data, 'error') ?? ErrorType.Unknown;
        logError(`Failed to check if user can sign in: ${errorType}`);
        return OperationResponse.error(errorType);
      }

      const response = MapUtils.getOrNull<true | AuthenticatorType[]>(result.data, 'result');
      if(isNotNullOrUndefined(response)) {
        if(typeof response === 'boolean') {
          return OperationResponse.success(response);
        }
        else {
          return OperationResponse.success(response[0]);
        }
      }

      return OperationResponse.error(ErrorType.Unknown);
    }
    catch(error) {
      logError(`Failed to call "${endpoint}" function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return OperationResponse.error(ErrorType.FailedToCallCloudFunction);
    }
  }

  async canSignUp(args: {
    email?: string,
    phoneNumber?: string,
  }): Promise<OperationResponse<CanSignUpResponse>> {
    const endpoint = this.endpointProvider.canSignUp;

    try {
      const context = await createHttpsAuthContext(this.authenticationService.authState?.user ?? null);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(endpoint, context);

      const result = await callable.call({
        parameters: {
          'email': args.email ?? null,
          'phoneNumber': args.phoneNumber ?? null,
        },
      });

      const success = MapUtils.getOrNull<boolean>(result.data, 'success') ?? false;
      if(!success) {
        const errorType = MapUtils.getOrNull<string>(result.data, 'error') ?? ErrorType.Unknown;
        logError(`Failed to check if user can sign up: ${errorType}`);
        return OperationResponse.error(errorType);
      }

      const response = MapUtils.getOrNull<true | AuthenticatorType[]>(result.data, 'result');
      if(isNotNullOrUndefined(response)) {
        if(typeof response === 'boolean') {
          return OperationResponse.success(response);
        }
        else {
          return OperationResponse.success(response[0]);
        }
      }

      return OperationResponse.error(ErrorType.Unknown);
    }
    catch(error) {
      logError(`Failed to call "${endpoint}" function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return OperationResponse.error(ErrorType.FailedToCallCloudFunction);
    }
  }

  async fetchDiscoverPageInfo(route: string): Promise<OperationResponse<DiscoverPageInfo>> {
    const endpoint = this.endpointProvider.fetchDiscoverPageInfo;

    try {
      const context = await createHttpsAuthContext(this.authenticationService.authState?.user ?? null);
      const callable = this.cloudFunctionsProvider.getHttpsCallable(endpoint, context);

      const response = await callable.call({
        parameters: {
          'route': route,
        },
      });

      const success = MapUtils.getOrNull<boolean>(response.data, 'success') ?? false;
      if(success) {
        const discoverPageInfo = fromData<DiscoverPageInfo>(
          MapUtils.get<SerializedData>(response.data, 'result')
        );

        return OperationResponse.success(discoverPageInfo);
      }
      else {
        const errorType = MapUtils.getOrNull<string>(response.data, 'error') ?? ErrorType.Unknown;
        logError(`Failed to fetch discover page info: ${errorType}`);
        return OperationResponse.error(errorType);
      }
    }
    catch(error) {
      logError(`Failed to call "${endpoint}" function: ${(error instanceof CloudFunctionsException) ? error.message : error}`);
      return OperationResponse.error(ErrorType.FailedToCallCloudFunction);
    }
  }
}
