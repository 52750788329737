import { Auth, GoogleAuthProvider, OAuthCredential, signInWithPopup } from '@angular/fire/auth';
import { AuthenticationResult } from '../models/authentication-result';
import { AuthenticatorType } from '../models/authenticator-type';
import { parseFirebaseAuthError } from '../models/auth-error-utils';
import { Authenticator } from './authenticator';
import { logError, logWarning } from 'in-time-logger';
import { OperationResponse } from 'in-time-core';
import { ErrorType } from '../../../core/models/error-type';

export class GoogleAuthenticator extends Authenticator {
  private readonly provider: GoogleAuthProvider;

  constructor(private readonly auth: Auth) {
    super();

    this.provider = new GoogleAuthProvider();
    this.provider.addScope('email');
    this.provider.addScope('profile');
  }

  get type(): AuthenticatorType {
    return AuthenticatorType.Google;
  }

  async signIn(): Promise<AuthenticationResult> {
    try {
      const userCredentials = await signInWithPopup(this.auth, this.provider);

      return AuthenticationResult.authenticated(userCredentials.user, this.type);
    }
    catch(error) {
      const errorType = parseFirebaseAuthError(error);
      if(errorType === ErrorType.UserNotFound) {
        logWarning(`Failed to sign in with google: ${errorType}`);
      }

      return AuthenticationResult.error(errorType);
    }
  }

  async signUp(): Promise<AuthenticationResult> {
    return await this.signIn();
  }

  async getAuthCredential(): Promise<OperationResponse<OAuthCredential>> {
    try {
      const userCredentials = await signInWithPopup(this.auth, this.provider);
      const oAuthCredential = GoogleAuthProvider.credentialFromResult(userCredentials);

      return OperationResponse.success(oAuthCredential);
    }
    catch(error) {
      logError(`Failed to create Google auth credential: ${error}`);
      return OperationResponse.error(ErrorType.InvalidGoogleLogin);
    }
  }
}
