import Fuse, { IFuseOptions } from 'fuse.js';
import { Injectable, inject } from '@angular/core';

import {
  EventList, KeywordList, SearchSnapshot, isNullOrUndefined,
  isNullOrUndefinedOrEmpty,
} from 'in-time-core';
import { SearchSnapshotService, SearchSnapshotStreamSnapshot } from './search-snapshot.service';

type SearchEntry = {
  name: string,
  uniqueId: string;
}

@Injectable({ providedIn: 'root' })
export class EventSearchService {
  static readonly DEFAULT_FUSE_OPTIONS = {
    includeScore: true,
    shouldSort: true,
    ignoreLocation: true,
    threshold: 0.4,
    keys: ['name'],
  } satisfies IFuseOptions<SearchEntry>;

  static readonly DEFAULT_SCORE_THRESHOLD = 0.4;

  private readonly searchSnapshotService: SearchSnapshotService = inject(SearchSnapshotService);

  private isInitialized = false;

  private fuse: Fuse<SearchEntry> | null = null;

  async ensureInitialized(): Promise<void> {
    if(this.isInitialized) {
      return;
    }

    this.isInitialized = true;

    await this.searchSnapshotService.ensureInitialized();

    this.searchSnapshotService.searchSnapshots$.subscribe(
      (snapshot) => this.onSearchSnapshot(snapshot),
    );
  }

  search(query: string): string[] | null {
    if(isNullOrUndefined(this.fuse)) {
      return null;
    }

    if(isNullOrUndefinedOrEmpty(query.trim())) {
      return null;
    }

    const fuseResult = this.fuse.search(query);
    const scoreThreshold = EventSearchService.DEFAULT_SCORE_THRESHOLD;
    // Scores are between 0.0 and 1.0, where 0.0 is the best match and 1.0 is
    // the worst match.
    return fuseResult
      .filter((result) => (result.score ?? 0.0) < scoreThreshold)
      .map((result) => result.item.uniqueId);
  }

  private onSearchSnapshot(snapshot: SearchSnapshotStreamSnapshot | null): void {
    if(isNullOrUndefined(snapshot)) {
      return;
    }

    if(!snapshot.success) {
      return;
    }

    const searchSnapshot = snapshot.data;

    const eventList = this.findEventList(searchSnapshot);
    if(isNullOrUndefined(eventList)) {
      return;
    }

    const keywordLists = this.findKeywordLists(searchSnapshot);
    if(keywordLists.length <= 0) {
      return;
    }

    const entries = [...this.generateSearchEntries(eventList, keywordLists)];

    this.fuse = new Fuse(entries, EventSearchService.DEFAULT_FUSE_OPTIONS);
  }

  private *generateSearchEntries(eventList: EventList, keywordLists: KeywordList[]): Generator<SearchEntry> {
    const keywordListTable = new Map<number, KeywordList>();
    for(const keywordList of keywordLists) {
      keywordListTable.set(keywordList.listId.asNumber(), keywordList);
    }

    for(const event of eventList.events) {
      const uniqueId = event.uniqueId.asString();

      for(const keywordRef of event.keywords) {
        const listId = keywordRef.listId.asNumber();
        const keywordList = keywordListTable.get(listId);
        if(isNullOrUndefined(keywordList)) {
          continue;
        }

        const keywordIndex = keywordRef.index.asNumber();
        const keyword = keywordList.keywords[keywordIndex]?.asString() ?? null;
        if(isNullOrUndefinedOrEmpty(keyword)) {
          continue;
        }

        yield {
          name: keyword,
          uniqueId: uniqueId
        };

        break;
      }
    }
  }

  private findEventList(searchSnapshot: SearchSnapshot): EventList | null {
    for(const element of searchSnapshot.elements) {
      if(element instanceof EventList) {
        return element;
      }
    }

    return null;
  }

  private findKeywordLists(searchSnapshot: SearchSnapshot): KeywordList[] {
    const keywordLists: KeywordList[] = [];

    for(const element of searchSnapshot.elements) {
      if(element instanceof KeywordList) {
        keywordLists.push(element);
      }
    }

    return keywordLists;
  }
}
