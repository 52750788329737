import { Injectable } from '@angular/core';
import { DynamicPricingSettings, EventProfile, PaymentSettings } from 'in-time-core';

export type EventCacheEntry = {
  event: EventProfile;
  dynamicPricingSettings: DynamicPricingSettings | null;
  productPaymentSettings: PaymentSettings | null;
};

@Injectable({ providedIn: 'root' })
export class EventCacheService {
  private readonly cache: EventCacheEntry[] = [];

  isCachedByUniqueId(eventId: string): boolean {
    for(let i = 0; i < this.cache.length; i++) {
      if(this.cache[i].event.uniqueId == eventId) {
        return true;
      }
    }

    return false;
  }

  isCachedByWebRoute(webRoute: string): boolean {
    for(let i = 0; i < this.cache.length; i++) {
      if(this.cache[i].event.webRoute == webRoute) {
        return true;
      }
    }

    return false;
  }

  set(event: EventProfile, dynamicPricingSettings: DynamicPricingSettings | null, productPaymentSettings: PaymentSettings | null): void {
    this.cache.push({
      event,
      dynamicPricingSettings: dynamicPricingSettings ?? null,
      productPaymentSettings: productPaymentSettings ?? null
    });
  }

  tryGetByUniqueId(eventId: string): EventCacheEntry | null {
    for(let i = 0; i < this.cache.length; i++) {
      if(this.cache[i].event.uniqueId == eventId) {
        return this.cache[i];
      }
    }

    return null;
  }

  tryGetByWebRoute(webRoute: string): EventCacheEntry | null {
    for(let i = 0; i < this.cache.length; i++) {
      if(this.cache[i].event.webRoute == webRoute) {
        return this.cache[i];
      }
    }

    return null;
  }
}