import { Injectable, inject } from '@angular/core';
import { DBStreamSnapshot, DatabaseService } from '.';
import { SearchSnapshot } from 'in-time-core';
import { BehaviorSubject, Observable } from 'rxjs';

export type SearchSnapshotStreamSnapshot = DBStreamSnapshot<SearchSnapshot>;

@Injectable({ providedIn: 'root' })
export class SearchSnapshotService {
  private readonly databaseService = inject(DatabaseService);

  private readonly searchSnapshots: BehaviorSubject<SearchSnapshotStreamSnapshot | null>;
  private isInitialized: boolean;

  constructor() {
    this.searchSnapshots = new BehaviorSubject<SearchSnapshotStreamSnapshot | null>(null);

    this.isInitialized = false;
  }

  get searchSnapshots$(): Observable<SearchSnapshotStreamSnapshot | null> {
    return this.searchSnapshots;
  }

  async ensureInitialized(): Promise<void> {
    if(this.isInitialized) {
      return;
    }

    this.isInitialized = true;

    const response = await this.databaseService.fetchSearchSnapshot();
    if(response.success) {
      this.searchSnapshots.next({
        success: true,
        data: response.data,
      });
    }
    else {
      this.searchSnapshots.next({
        error: response.error,
        success: false,
      });
    }
  }
}
