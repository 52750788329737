import { getDownloadURL, ref, Storage, StorageReference, uploadBytes, deleteObject } from '@angular/fire/storage';
import { Injectable, inject } from '@angular/core';
import { VoidOperationResponse } from 'in-time-core';
import { StorageUploadResponse } from './models/storage-upload-response';
import { logError } from 'in-time-logger';
import { ErrorType } from '../core/models/error-type';

export type StorageProgressCallback = (progress: number) => void;

@Injectable({
  providedIn: 'root'
})
export class CloudStorageService {
  private readonly storage = inject(Storage);

  private get rootStorageRef(): StorageReference {
    return ref(this.storage);
  }

  async upload(
    file: File,
    storagePath: string,
    onProgressChanged?: StorageProgressCallback
  ): Promise<StorageUploadResponse> {
    try {
      const progressChangeHandler = this.raiseProgressChangedEvent;

      progressChangeHandler(onProgressChanged, 0.0);

      const storageReference = ref(this.rootStorageRef, storagePath);
      const result = await uploadBytes(storageReference, file);

      progressChangeHandler(onProgressChanged, 1.0);

      const downloadUrl = await getDownloadURL(result.ref);
      return StorageUploadResponse.success(result.ref.fullPath, downloadUrl);
    }
    catch(error) {
      logError('Failed to upload file to Firebase Storage: ', error);
      return StorageUploadResponse.error(ErrorType.FailedToUploadFileToStorage);
    }
  }

  async delete(storagePath: string): Promise<VoidOperationResponse> {
    try {
      const storageReference = ref(this.rootStorageRef, storagePath);
      await deleteObject(storageReference);

      return VoidOperationResponse.success();
    }
    catch(error) {
      logError('Failed to delete file from Firebase Storage: ', error);
      return VoidOperationResponse.error(ErrorType.FailedToDeleteFileFromStorage);
    }
  }

  private raiseProgressChangedEvent(onProgressChanged: StorageProgressCallback | undefined, progress: number): void {
    if(onProgressChanged) {
      onProgressChanged(progress);
    }
  }
}