import { toSafeFilename } from 'in-time-core';
import {
  AddToCartEventArgs, AnalyticsTrackerType, CompleteRegistrationEventArgs, ContentViewEventArgs,
  InitiateCheckoutEventArgs, InitiatePurchaseEventArgs, PageViewEventArgs, PurchaseCanceledEventArgs,
  PurchaseFailedEventArgs, PurchaseCompleteEventArgs, SearchEventArgs, SignUpEventArgs,
  PromoCodeEventArgs, ExploreEventArgs
} from './analytics.types';
import { Dayjs } from 'dayjs';

export abstract class AnalyticsTracker {
  abstract get $typeId(): AnalyticsTrackerType;

  abstract onInit(hasAcceptedCookies: boolean): void;
  abstract onDestroy(): void;

  abstract onCookiesAccepted(): void;
  abstract onCookiesDeclined(): void;

  abstract trackPromoCode(args: PromoCodeEventArgs): void;
  abstract trackPageView(args: PageViewEventArgs): void;
  abstract trackSignUp(args: SignUpEventArgs): void;
  abstract trackContentView(args: ContentViewEventArgs): void;
  abstract trackAddToCart(args: AddToCartEventArgs): void;
  abstract trackInitiateCheckout(args: InitiateCheckoutEventArgs): void;
  abstract trackCompleteRegistration(args: CompleteRegistrationEventArgs): void;
  abstract trackInitiatePurchase(args: InitiatePurchaseEventArgs): void;
  abstract trackPurchaseComplete(args: PurchaseCompleteEventArgs): void;
  abstract trackPurchaseFailed(args: PurchaseFailedEventArgs): void;
  abstract trackPurchaseCanceled(args: PurchaseCanceledEventArgs): void;
  abstract trackSearch(args: SearchEventArgs): void;
  abstract trackExplore(args: ExploreEventArgs): void;

  toSafeEventName(eventName: string, eventDate?: Dayjs | null): string {
    let result = toSafeFilename(eventName);

    if(result.length > 20) {
      result = result.substring(0, 20);
    }

    if(eventDate != null) {
      result += `_${eventDate.format('YYYY_MM_DD')}`;
    }

    return result;
  }
}