import { Injectable, OnDestroy, PLATFORM_ID, inject } from '@angular/core';
import { Country, isNotNullOrUndefinedOrEmpty } from 'in-time-core';
import { AnalyticsTracker } from './analytics/analytics-tracker';
import { environment } from '../app.environment';
import { GeoLocationService } from './geo-location.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { logDebug, logInfo, logWarning } from 'in-time-logger';
import { GoogleAnalyticsTracker } from './analytics/google-analytics-tracker';
import { MetaTracker } from './analytics/meta-tracker';
import { BarionTracker } from './analytics/barion-tracker';
import { Analytics } from '@angular/fire/analytics';
import { ɵDomRendererFactory2 } from '@angular/platform-browser';
import { TiktokTracker } from './analytics/tiktok-tracker';
import { CookieService } from './cookie.service';
import {
  InitiateCheckoutEventArgs, InitiatePurchaseEventArgs, PageViewEventArgs, PurchaseFailedEventArgs,
  AddToCartEventArgs, AnalyticsTrackerType, ContentViewEventArgs, PurchaseCanceledEventArgs,
  SignUpEventArgs, PromoCodeEventArgs, SearchEventArgs, PurchaseCompleteEventArgs, ExploreEventArgs,
} from './analytics/analytics.types';
import { GtmTracker } from './analytics/gtm-tracker';

function createTrackers(opts: {
  doc: Document,
  rendererFactory: ɵDomRendererFactory2,
  analytics: Analytics,
  cookieService: CookieService,
  isInHungary: boolean,
  allowPixelTracking: boolean,
}): AnalyticsTracker[] {
  const renderer = opts.rendererFactory.createRenderer(null, null);
  const trackers: AnalyticsTracker[] = [];

  trackers.push(GoogleAnalyticsTracker.create({
    analytics: opts.analytics,
  }));

  trackers.push(GtmTracker.create({
    renderer: renderer,
    document: opts.doc,
  }));

  if(opts.allowPixelTracking) {
    logInfo('Platform is browser and pixel tracking is allowed!');

    trackers.push(MetaTracker.create({
      renderer: renderer,
      document: opts.doc,
    }));

    trackers.push(TiktokTracker.create({
      renderer: renderer,
      document: opts.doc,
    }));

    if(opts.isInHungary) {
      const barionPixelId = environment.barionPixelId;
      if(barionPixelId != null) {
        trackers.push(BarionTracker.create({
          cookieService: opts.cookieService,
          pixelId: barionPixelId,
          renderer: renderer,
          document: opts.doc,
        }));
      }
      else {
        logWarning('Barion pixel tracking is not available in this environment.');
      }
    }
    else {
      logWarning('Barion pixel tracking is not available in your country.');
    }
  }
  else {
    logWarning('Pixel tracking is not available in this environment.');
  }

  return trackers;
}

@Injectable({ providedIn: 'root' })
export class AnalyticsService implements OnDestroy {
  private readonly rendererFactory = inject(ɵDomRendererFactory2);
  private readonly analytics = inject(Analytics);
  private readonly _doc = inject(DOCUMENT);
  private readonly cookieService = inject(CookieService);
  private readonly geoLocationService = inject(GeoLocationService);
  private readonly isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  private readonly trackers: AnalyticsTracker[];

  constructor() {
    const isInHungary = this.geoLocationService.country === Country.Hungary;
    const allowPixelTracking = environment.allowPixelTracking;

    this.trackers = [];

    if(this.isBrowser) {
      this.trackers.push(...createTrackers({
        rendererFactory: this.rendererFactory,
        analytics: this.analytics,
        cookieService: this.cookieService,
        doc: this._doc,
        isInHungary,
        allowPixelTracking,
      }));
    }
  }

  onInitTracking(): void {
    const hasAcceptedCookies = this.cookieService.hasAcceptedCookies();

    for(let i = 0; i < this.trackers.length; i++) {
      this.trackers[i].onInit(hasAcceptedCookies);
    }

    if(hasAcceptedCookies) {
      logDebug('Cookies have been accepted before, starting with tracking enabled.')
    }
    else {
      logDebug('Cookies have not been accepted before, starting with tracking disabled.')
    }
  }

  ngOnDestroy(): void {
    for(let i = 0; i < this.trackers.length; i++) {
      this.trackers[i].onDestroy();
    }

    this.trackers.length = 0;
  }

  onCookiesAccepted(): void {
    this.cookieService.accept();

    for(let i = 0; i < this.trackers.length; i++) {
      this.trackers[i].onCookiesAccepted();
    }

    logDebug('Cookies have been accepted.');
  }

  onCookiesDeclined(): void {
    this.cookieService.decline();

    for(let i = 0; i < this.trackers.length; i++) {
      this.trackers[i].onCookiesDeclined();
    }

    logDebug('Cookies have been declined.');
  }

  activateCustomTracker(opts: {
    context: string,
    customMetaPixelId: string | null,
    customTikTokPixel: string | null,
    customTagManagerId: string | null,
    trackPageView: boolean
  }): void {
    if(isNotNullOrUndefinedOrEmpty(opts.customMetaPixelId)) {
      const metaTracker = this.getTrackerOrNull<MetaTracker>('meta');
      metaTracker?.activateCustomPixel({
        context: opts.context,
        customPixelId: opts.customMetaPixelId,
        trackPageView: opts.trackPageView
      });
    }
    if(isNotNullOrUndefinedOrEmpty(opts.customTikTokPixel)) {
      const ttqTracker = this.getTrackerOrNull<TiktokTracker>('tiktok');
      ttqTracker?.activateCustomPixel({
        context: opts.context,
        customPixelId: opts.customTikTokPixel,
      });
    }
    if(isNotNullOrUndefinedOrEmpty(opts.customTagManagerId)) {
      const gtmTracker = this.getTrackerOrNull<GtmTracker>('gtm');
      gtmTracker?.activateCustomTagManager({
        context: opts.context,
        customTagManagerId: opts.customTagManagerId,
      });
    }
  }

  deactivateCustomTracker(opts: {
    context: string,
    customMetaPixelId: string | null,
    customTikTokPixel: string | null,
    customTagManagerId: string | null,
  }): void {
    if(opts.customMetaPixelId != null) {
      const metaTracker = this.getTrackerOrNull<MetaTracker>('meta');
      metaTracker?.deactivateCustomPixel({
        context: opts.context,
        customPixelId: opts.customMetaPixelId,
      });
    }
    if(opts.customTikTokPixel != null) {
      const ttqTracker = this.getTrackerOrNull<TiktokTracker>('tiktok');
      ttqTracker?.deactivateCustomPixel({
        context: opts.context,
        customPixelId: opts.customTikTokPixel,
      });
    }
    if(opts.customTagManagerId != null) {
      const gtmTracker = this.getTrackerOrNull<GtmTracker>('gtm');
      gtmTracker?.deactivateCustomTagManager({
        context: opts.context,
        customTagManagerId: opts.customTagManagerId,
      });
    }
  }

  trackPromoCode(args: PromoCodeEventArgs): void {
    for(let i = 0; i < this.trackers.length; i++) {
      this.trackers[i].trackPromoCode(args);
    }
  }

  trackPageView(args: PageViewEventArgs): void {
    for(let i = 0; i < this.trackers.length; i++) {
      this.trackers[i].trackPageView(args);
    }
  }

  trackSignUp(args: SignUpEventArgs): void {
    for(let i = 0; i < this.trackers.length; i++) {
      this.trackers[i].trackSignUp(args);
    }
  }

  trackContentView(args: ContentViewEventArgs): void {
    for(let i = 0; i < this.trackers.length; i++) {
      this.trackers[i].trackContentView(args);
    }
  }

  trackAddToCart(args: AddToCartEventArgs): void {
    for(let i = 0; i < this.trackers.length; i++) {
      this.trackers[i].trackAddToCart(args);
    }
  }

  trackInitiateCheckout(args: InitiateCheckoutEventArgs): void {
    for(let i = 0; i < this.trackers.length; i++) {
      this.trackers[i].trackInitiateCheckout(args);
    }
  }

  trackCompleteRegistration(args: InitiateCheckoutEventArgs): void {
    for(let i = 0; i < this.trackers.length; i++) {
      this.trackers[i].trackCompleteRegistration(args);
    }
  }

  trackInitiatePurchase(args: InitiatePurchaseEventArgs): void {
    for(let i = 0; i < this.trackers.length; i++) {
      this.trackers[i].trackInitiatePurchase(args);
    }
  }

  trackPurchaseComplete(args: PurchaseCompleteEventArgs): void {
    for(let i = 0; i < this.trackers.length; i++) {
      this.trackers[i].trackPurchaseComplete(args);
    }
  }

  trackPurchaseFailed(args: PurchaseFailedEventArgs): void {
    for(let i = 0; i < this.trackers.length; i++) {
      this.trackers[i].trackPurchaseFailed(args);
    }
  }

  trackPurchaseCanceled(args: PurchaseCanceledEventArgs): void {
    for(let i = 0; i < this.trackers.length; i++) {
      this.trackers[i].trackPurchaseCanceled(args);
    }
  }

  trackSearch(args: SearchEventArgs): void {
    if(args.query.length > 0) {
      for(let i = 0; i < this.trackers.length; i++) {
        this.trackers[i].trackSearch(args);
      }
    }
  }

  trackExplore(args: ExploreEventArgs): void {
    for(let i = 0; i < this.trackers.length; i++) {
      this.trackers[i].trackExplore(args);
    }
  }

  private getTrackerOrNull<T extends AnalyticsTracker>(typeId: AnalyticsTrackerType): T | null {
    for(let i = 0; i < this.trackers.length; i++) {
      if(this.trackers[i].$typeId == typeId) {
        return this.trackers[i] as T;
      }
    }

    return null;
  }
}