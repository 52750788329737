import { AuthenticationResult } from '../models/authentication-result';
import { AuthenticatorType } from '../models/authenticator-type';

export abstract class Authenticator {
  abstract get type(): AuthenticatorType

  abstract signIn(): Promise<AuthenticationResult>

  abstract signUp(): Promise<AuthenticationResult>
}
