import { fromEnumAsString, isNotNullOrUndefined, isNullOrUndefined, Language, toEnumFromString, toEnumFromStringOrNull } from 'in-time-core';
import { inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { logInfo } from 'in-time-logger';
import { CookieService } from './cookie.service';
import { map } from 'rxjs/operators';
import { Locale } from '../core/models/locale';
import { TranslocoService } from '@ngneat/transloco';
import { STORAGE_LANGUAGE } from '../core/utils/storage-keys';
import { extractTopLevelDomainFromHost } from '../core/utils/sign-in-utils';
import { UserPrefsService } from './cloud-save.service';
import { PREFS_LANGUAGE } from '../core/utils/prefs-keys';
import { RequestToken } from '../core/utils/type-utils';
import { isPlatformBrowser } from '@angular/common';
import { REQUEST } from '../../express.tokens';

@Injectable({
  providedIn: 'root'
})
export class LanguageService implements OnDestroy {
  public static readonly LANGUAGE_CODES = ['en', 'hu', 'ro'];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static readonly SUPPORTED_LANGUAGES: any = {
    'en': true,
    'hu': true,
    'ro': true,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static readonly LOCALES: any = {
    'en': <Locale>{ name: 'en-US', displayName: 'English', languageCode: 'en', countryCode: 'US' },
    'hu': <Locale>{ name: 'hu-HU', displayName: 'Magyar', languageCode: 'hu', countryCode: 'HU' },
    'ro': <Locale>{ name: 'ro-RO', displayName: 'Română', languageCode: 'ro', countryCode: 'RO' },
  };

  private readonly request: RequestToken | null = inject(REQUEST, { optional: true });
  private readonly translocoService = inject(TranslocoService);
  private readonly cookieService = inject(CookieService);
  private readonly userPrefsService = inject(UserPrefsService);
  private readonly isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  private subscription: Subscription | null = null;
  private currentLanguage: Language;
  private cachedSupportedLocales: Locale[] = [];
  private currentLocaleSnapshot: BehaviorSubject<Locale>;

  get language(): Language {
    return this.currentLanguage;
  }

  get locale(): Locale {
    return LanguageService.LOCALES[this.currentLanguage];
  }

  get supportedLocales(): Locale[] {
    return this.cachedSupportedLocales;
  }

  get locale$(): Observable<Locale> {
    return this.currentLocaleSnapshot;
  }

  get language$(): Observable<Language> {
    return this.currentLocaleSnapshot.pipe(map((l) => toEnumFromString(l.languageCode, Language)));
  }

  constructor() {
    this.currentLanguage = toEnumFromString(this.translocoService.getActiveLang(), Language);
    const supportedLanguage = LanguageService.SUPPORTED_LANGUAGES;

    for(const key in supportedLanguage) {
      // eslint-disable-next-line no-prototype-builtins
      if(supportedLanguage.hasOwnProperty(key) && supportedLanguage[key]) {
        this.cachedSupportedLocales.push(LanguageService.LOCALES[key]);
      }
    }

    this.currentLocaleSnapshot = new BehaviorSubject<Locale>(LanguageService.LOCALES[this.currentLanguage]);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subscription = null;
    this.currentLocaleSnapshot.complete();
  }

  load(): void {
    const cookieValue = this.cookieService.get(STORAGE_LANGUAGE);
    console.log('Language cookie: ', cookieValue);

    let currentLanguage: Language | null = toEnumFromStringOrNull(cookieValue, Language);
    if(isNullOrUndefined(currentLanguage)) {
      currentLanguage = LanguageService.tryGetDefaultLanguage(this.request, this.isBrowser);
    }

    this.changeLanguage(currentLanguage ?? Language.Hungarian);
    this.onCloudDataSynced();
    this.subscription = this.userPrefsService.sync$.subscribe(() => this.onCloudDataSynced());
  }

  setLocale(locale: Locale) {
    const language: Language | null = toEnumFromStringOrNull(locale.languageCode, Language);
    if(isNotNullOrUndefined(language) && language !== this.currentLanguage) {
      this.changeLanguage(language);
      this.cacheCurrentLanguage();
    }
  }

  private changeLanguage(language: Language): void {
    const encodedLanguage = fromEnumAsString(language);

    this.currentLanguage = language;
    this.translocoService.setActiveLang(encodedLanguage);
    this.currentLocaleSnapshot.next(LanguageService.LOCALES[encodedLanguage]);
    logInfo(`Language set to: ${encodedLanguage}`);
  }

  private cacheCurrentLanguage(): void {
    const encodedLanguage = fromEnumAsString(this.currentLanguage);
    this.cookieService.set(STORAGE_LANGUAGE, encodedLanguage);
    this.userPrefsService.setString(PREFS_LANGUAGE, encodedLanguage);
  }

  private onCloudDataSynced(): void {
    const language = this.tryGetCachedLanguage();
    if(language != null && language !== this.currentLanguage) {
      this.changeLanguage(language);
    }

    if(!this.userPrefsService.containsKey(PREFS_LANGUAGE)) {
      this.cacheCurrentLanguage();
    }
  }

  private tryGetCachedLanguage(): Language | null {
    let language: Language | null = toEnumFromStringOrNull(this.userPrefsService.getString(PREFS_LANGUAGE), Language);

    // If there's no language in the cloud save data try to find a locally saved language.
    language ??= toEnumFromStringOrNull(this.cookieService.get(STORAGE_LANGUAGE), Language);

    return language;
  }

  static tryGetDefaultLanguage(request: RequestToken | null, isBrowser: boolean): Language {
    let tld: string | null = null;

    if(isBrowser) {
      tld = extractTopLevelDomainFromHost(window?.location?.hostname);
    }
    else {
      tld = extractTopLevelDomainFromHost(request?.hostname);
    }

    if(tld === 'ro') {
      let language: Language | null = null;

      if(isBrowser) {
        try {
          const navigatorLanguage = navigator.language?.toLowerCase() ?? null;
          if(isNotNullOrUndefined(navigatorLanguage)) {
            const languageCode = navigatorLanguage.length > 2 ? navigatorLanguage.split('-')[0] : navigatorLanguage;
            if(isNotNullOrUndefined(this.LOCALES[languageCode])) {
              language = toEnumFromString(languageCode, Language);
            }
          }
        }
        catch{
          language = null;
        }
      }

      if(isNotNullOrUndefined(language)) {
        console.log(`Browser language detected: ${language}`);
      }

      return Language.Romanian;
    }

    return Language.Hungarian;
  }
}