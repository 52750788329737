import {
  OnlineEventTicketOrder, isNullOrUndefined, ITicketPool, CustomerProfile, CustomerSubscription, WeeklySchedule,
  DailySchedule, CustomerAccount, VoidOperationResponse, toData, OperationResponse, fromData, Service,
  SerializedData, ClientInviteCode, BusinessModuleRef, StaffProfile, BusinessProfile, EventProfile, BusinessRoute,
  FeaturedMetadata, TrueTime, PaymentIntent, TrackingPromoCode, SeatingLayout, ErrorLike, HomePageInfo,
  BusinessPromoCode, BillingDetailsComponent, fromDataSafe, fromDataOrNull, SearchSnapshot, PaymentSettings,
  generateUniqueDatabaseId, DiscoverContent, isNotNullOrUndefined, Voucher, VoucherStatus, EventSeatSelectionLock,
  NewsContent, Review, EventPromoCode, DynamicPricingSettings, BillingProfile, LegacyBillingDetailsComponent,
  TaxIdentificationRequest,
} from 'in-time-core';
import {
  Firestore, DocumentSnapshot, getDoc, setDoc, getDocs, query, where, orderBy, startAfter, limit, runTransaction,
  Transaction, docSnapshots, doc,
} from '@angular/fire/firestore';
import { logError, logInfo } from 'in-time-logger';
import { Injectable, inject } from '@angular/core';
import { FirestoreDocumentLocator } from './utility/firestore-document-locator';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ErrorType } from '../core/models/error-type';

export type ReviewBatch = {
  reviews: Review[],
  startAfter: DocumentSnapshot | null,
  canLoadMore: boolean
}

export type FullSchedule = {
  weekly: Map<string, WeeklySchedule>,
  daily: Map<string, Map<number, DailySchedule>>,
}

export type DBStreamSnapshot<T> = {
  success: true,
  data: T,
} | {
  success: false,
  error: ErrorLike,
}

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  private readonly locator: FirestoreDocumentLocator;
  private readonly firestore = inject(Firestore);

  constructor() {
    this.locator = new FirestoreDocumentLocator(this.firestore);
  }

  async logUserAgent(userAgent: { userAgent: string | null, source: string | null }): Promise<VoidOperationResponse> {
    try {
      const document = this.locator.userAgentLog(generateUniqueDatabaseId());
      await setDoc(document, {
        uniqueId: document.id,
        userAgent: userAgent.userAgent,
        source: userAgent.source,
        creationDate: TrueTime.now().toISOString()
      });

      logInfo(`User agent has been logged: ${JSON.stringify(userAgent)}`,);
      return VoidOperationResponse.success();
    }
    catch(error) {
      logError('Failed to log user agent: ', error);
      return VoidOperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchTaxIdentificationRequest(requestId: string): Promise<OperationResponse<TaxIdentificationRequest>> {
    try {
      const document = this.locator.taxIdentificationRequest(requestId);
      const snapshot = await getDoc(document);
      const data = snapshot.data();

      return data != null ?
        OperationResponse.success(fromData<TaxIdentificationRequest>(data)):
        OperationResponse.error(ErrorType.DocumentNotFound);
    }
    catch(error) {
      logError('Failed to fetch tax identification request: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchCloudData(userId: string): Promise<OperationResponse<SerializedData>> {
    try {
      const document = this.locator.cloudSaveData(userId);
      const snapshot = await getDoc(document);

      return OperationResponse.success(snapshot.exists() ? (snapshot.data() as SerializedData) : {});
    }
    catch(error) {
      logError('Failed to fetch cloud data: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async updateCloudData(userId: string, data: SerializedData): Promise<VoidOperationResponse> {
    try {
      const document = this.locator.cloudSaveData(userId);
      await setDoc(document, data);

      return VoidOperationResponse.success();
    }
    catch(error) {
      logError(`Failed to update cloud data: ${error}`);
      return VoidOperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchCloudPrefs(userId: string): Promise<OperationResponse<SerializedData>> {
    try {
      const document = this.locator.cloudPrefs(userId);
      const snapshot = await getDoc(document);

      return OperationResponse.success(snapshot.exists() ? (snapshot.data() as SerializedData) : {});
    }
    catch(error) {
      logError('Failed to fetch cloud prefs: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async updateCloudPrefs(userId: string, data: SerializedData): Promise<VoidOperationResponse> {
    try {
      const document = this.locator.cloudPrefs(userId);
      await setDoc(document, data);

      return VoidOperationResponse.success();
    }
    catch(error) {
      logError(`Failed to update cloud prefs: ${error}`);
      return VoidOperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async createAccount(account: CustomerAccount): Promise<OperationResponse<CustomerAccount>> {
    try {
      const document = this.locator.customerAccount(account.uniqueId);
      await setDoc(document, toData(account));

      return OperationResponse.success(account);
    }
    catch(error) {
      logError('Failed to create account: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchCustomerAccount(userId: string): Promise<OperationResponse<CustomerAccount | null>> {
    try {
      const document = this.locator.customerAccount(userId);
      const snapshot = await getDoc(document);

      return snapshot.exists() ?
        OperationResponse.success(fromData<CustomerAccount>(snapshot.data() as SerializedData)):
        OperationResponse.error(ErrorType.DocumentNotFound);
    }
    catch(error) {
      logError('Failed to get user account: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async updateBillingProfileForCustomer(accountId: string, billingProfile: BillingProfile): Promise<OperationResponse<CustomerAccount>> {
    try {
      const transaction = async (tx: Transaction): Promise<OperationResponse<CustomerAccount>> => {
        const document = this.locator.customerAccount(accountId);
        const snapshot = await tx.get(document);
        const customer = fromDataOrNull<CustomerAccount>(snapshot.data() ?? null);

        if(isNullOrUndefined(customer)) {
          return OperationResponse.error(ErrorType.AccountNotFound);
        }

        let component = customer.findComponentByTypeId<BillingDetailsComponent>(BillingDetailsComponent.TYPE_ID);
        component ??= BillingDetailsComponent.empty();
        component.updateBillingProfile(billingProfile);

        customer.removeComponentByTypeId(LegacyBillingDetailsComponent.TYPE_ID);
        customer.addOrReplaceComponent(component);

        tx.set(document, toData(customer));

        return OperationResponse.success(customer);
      };

      return await runTransaction(this.firestore, transaction);
    }
    catch(error) {
      logError('Failed to update customer account: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchClientInviteCode(uniqueId: string): Promise<OperationResponse<ClientInviteCode>>{
    try {
      const document = this.locator.redeemableCode(uniqueId);
      const snapshot = await getDoc(document);
      const data = snapshot.data();

      if(data && data['$type_id'] === ClientInviteCode.TYPE_ID) {
        return OperationResponse.success(fromData<ClientInviteCode>(data));
      }
      else {
        return OperationResponse.error(ErrorType.DocumentNotFound);
      }
    }
    catch(error) {
      logError(`Failed to get client invite code {${uniqueId}}: `, error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchStaffMembers(businessRef: BusinessModuleRef): Promise<OperationResponse<StaffProfile[]>>{
    try {
      const collection = this.locator.staffMembers(businessRef);
      const snapshot = await getDocs(collection);
      const staffMembers = snapshot.docs.map((ds) => fromData<StaffProfile>(ds.data()));

      return OperationResponse.success(staffMembers);
    }
    catch(error) {
      logError('Failed to fetch staff members: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchBusinessProfile(businessId: string): Promise<OperationResponse<BusinessProfile>>{
    try {
      const document = this.locator.business(businessId);
      const snapshot = await getDoc(document);
      const data = snapshot.data();

      return data ?
        OperationResponse.success(fromData<BusinessProfile>(data)):
        OperationResponse.error(ErrorType.BusinessNotFound);
    }
    catch(error) {
      logError('Failed to fetch business profile: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchBusinessProfileByWebRoute(webRoute: string): Promise<OperationResponse<BusinessProfile>> {
    try {
      const docQuery = query(this.locator.businesses, where('webRoute', '==', webRoute), limit(1));
      const snapshot = await getDocs(docQuery);

      const result = snapshot.docs.map((ds) => fromData<BusinessProfile>(ds.data()));
      if(result.length < 1) {
        return OperationResponse.error(ErrorType.BusinessNotFound);
      }

      return OperationResponse.success(result[0]);
    }
    catch(error) {
      logError(`Failed to fetch business profile of web route {${webRoute}}: `, error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchBusinessProfileByLegacyRoute(routeName: string): Promise<OperationResponse<BusinessProfile>> {
    const response = await this.fetchLegacyBusinessRoute(routeName);
    if(!response.success) {
      return OperationResponse.error(ErrorType.BusinessNotFound);
    }

    return this.fetchBusinessProfile(response.data.businessId);
  }

  async fetchEventProfiles(eventIds: string[]): Promise<OperationResponse<EventProfile[]>> {
    try {
      const collection = this.locator.eventProfiles;
      const snapshots = await Promise.all(eventIds.map((eventId) => {
        const document = doc(collection, eventId);
        return getDoc(document);
      }));

      const events: EventProfile[] = [];
      for(const ds of snapshots) {
        const event = fromDataOrNull<EventProfile>(ds.data() ?? null);
        if(event != null) {
          events.push(event);
        }
      }

      return OperationResponse.success(events);
    }
    catch(error) {
      logError('Failed to fetch event profiles: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchAllEventProfiles(): Promise<OperationResponse<EventProfile[]>> {
    try {
      const collection = this.locator.eventProfiles;
      const snapshot = await getDocs(collection);

      return OperationResponse.success(snapshot.docs.map((value) => fromData<EventProfile>(value.data())));
    }
    catch(error) {
      logError('Failed to fetch event profiles: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchEventProfile(eventId: string): Promise<OperationResponse<EventProfile>> {
    try {
      const document = this.locator.eventProfile(eventId);
      const snapshot = await getDoc(document);

      return snapshot.exists() ?
        OperationResponse.success(fromData<EventProfile>(snapshot.data())) :
        OperationResponse.error(ErrorType.DocumentNotFound);
    }
    catch(error) {
      logError(`Failed to fetch event profile {${eventId}}: `, error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchEventProfileOfWebRoute(webRoute: string): Promise<OperationResponse<EventProfile>> {
    try {
      const docQuery = query(this.locator.eventProfiles, where('webRoute', '==', webRoute), limit(1));
      const snapshot = await getDocs(docQuery);

      const result = snapshot.docs.map((ds) => fromData<EventProfile>(ds.data()));
      if(result.length < 1) {
        return OperationResponse.error(ErrorType.DocumentNotFound);
      }

      return OperationResponse.success(result[0]);
    }
    catch(error) {
      logError(`Failed to fetch event profile of web route {${webRoute}}: `, error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchDynamicPricingSettings(businessRef: BusinessModuleRef, eventId: string): Promise<OperationResponse<DynamicPricingSettings>> {
    try {
      const document = this.locator.dynamicPricingSettings(businessRef, eventId);
      const snapshot = await getDoc(document);

      return snapshot.exists() ?
        OperationResponse.success(fromData<DynamicPricingSettings>(snapshot.data())) :
        OperationResponse.error(ErrorType.DocumentNotFound);
    }
    catch(error) {
      logError(`Failed to fetch dynamic pricing settings for event {${eventId}} of business {${businessRef}}: `, error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchProductPaymentSettings(businessRef: BusinessModuleRef, eventId: string): Promise<OperationResponse<PaymentSettings>> {
    try {
      const document = this.locator.productPaymentSettings(businessRef);
      const snapshot = await getDoc(document);

      return snapshot.exists() ?
        OperationResponse.success(fromData<PaymentSettings>(snapshot.data())) :
        OperationResponse.error(ErrorType.DocumentNotFound);
    }
    catch(error) {
      logError(`Failed to fetch product payment settings for event {${eventId}} of business {${businessRef}}: `, error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchBusinessRoutes(): Promise<OperationResponse<BusinessRoute[]>>{
    try {
      const collection = this.locator.businessRoutes;
      const snapshot = await getDocs(collection);

      return OperationResponse.success(snapshot.docs.map((value) => fromData<BusinessRoute>(value.data())));
    }
    catch(error) {
      logError('Failed to fetch business routes: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchServices(businessRef: BusinessModuleRef): Promise<OperationResponse<Service[]>>{
    try {
      const collection = this.locator.services(businessRef);
      const snapshot = await getDocs(collection);
      const data = snapshot.docs.map((value) => fromData<Service>(value.data()));

      return OperationResponse.success(data);
    }
    catch(error) {
      logError('Failed to fetch services: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchReviews(businessRef: BusinessModuleRef, startAfterDocument: DocumentSnapshot | null, batchSize: number): Promise<OperationResponse<ReviewBatch>>{
    try {
      let docQuery = query(this.locator.reviews(businessRef));

      if(startAfterDocument) {
        docQuery = query(docQuery, orderBy('creationDate', 'desc'), startAfter(startAfterDocument), limit(batchSize + 1));
      }
      else {
        docQuery = query(docQuery, orderBy('creationDate', 'desc'), limit(batchSize + 1));
      }

      const snapshot = await getDocs(docQuery);
      const reviews = snapshot.docs.map((value) => fromData<Review>(value.data()));

      const lastSnapshot = snapshot.docs.length > batchSize ?
        snapshot.docs[batchSize - 1] :
        snapshot.docs.length > 0 ? snapshot.docs[snapshot.docs.length - 1] : null;

      return OperationResponse.success({
        'reviews': reviews,
        'startAfter': lastSnapshot,
        'canLoadMore': snapshot.docs.length > batchSize
      } as ReviewBatch);
    }
    catch(error) {
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchLegacyBusinessRoute(routeId: string): Promise<OperationResponse<BusinessRoute>>{
    try {
      const document = this.locator.businessRoute(routeId);
      const snapshot = await getDoc(document);
      const data = snapshot.data();

      return data ?
        OperationResponse.success(fromData<BusinessRoute>(data)):
        OperationResponse.error(ErrorType.DocumentNotFound);
    }
    catch(error) {
      logError('Failed to fetch business route: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchBusinessRouteByBusinessId(businessId: string): Promise<OperationResponse<BusinessRoute>>{
    try {
      const docQuery = query(this.locator.businessRoutes, where('businessId', '==', businessId));
      const snapshot = await getDocs(docQuery);

      if(snapshot.docs.length > 0) {
        const route = fromData<BusinessRoute>(snapshot.docs[0].data());
        return OperationResponse.success(route);
      }

      return OperationResponse.error(ErrorType.DocumentNotFound);
    }
    catch(error) {
      logError('Failed to fetch business route: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchMetadata(): Promise<OperationResponse<FeaturedMetadata>>{
    try {
      const snapshot = await getDoc(this.locator.featuredBusinesses);
      const data = snapshot.data();

      return data ?
        OperationResponse.success(fromData<FeaturedMetadata>(data)):
        OperationResponse.error(ErrorType.DocumentNotFound);
    }
    catch(error) {
      logError('Failed to fetch metadata: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchClientSubscriptions(userId: string): Promise<OperationResponse<CustomerSubscription[]>> {
    try {
      const document = this.locator.customerSubscriptions(userId);
      const subscriptions: CustomerSubscription[] = [];
      const snapshot = await getDoc(document);

      const data = snapshot.data();
      if(data) {
        for(const key of Object.keys(data)) {
          subscriptions.push(fromData<CustomerSubscription>(data[key]));
        }
      }

      return OperationResponse.success(subscriptions);
    }
    catch(error) {
      logError('Failed to get customer business subscriptions: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchClientSubscriptionForBusiness(businessRef: BusinessModuleRef, userId: string): Promise<OperationResponse<CustomerSubscription>> {
    try {
      const document = this.locator.customerSubscriptions(userId);
      let subscription: CustomerSubscription;
      const snapshot = await getDoc(document);

      const data = snapshot.data();
      if(data) {
        for(const key of Object.keys(data)) {
          subscription = fromData<CustomerSubscription>(data[key]);
          if(subscription.businessRef.encoded == businessRef.encoded) {
            return OperationResponse.success(subscription);
          }
        }
      }

      return OperationResponse.error(ErrorType.DocumentNotFound);
    }
    catch(error) {
      logError('Failed to get customer business subscription: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async hasRecentReview(businessRef: BusinessModuleRef, userId: string): Promise<boolean> {
    try {
      const q = query(this.locator.reviews(businessRef), where('reviewerId', '==', userId), orderBy('creationDate', 'desc'), limit(1));
      const qs = await getDocs(q);

      if(qs.size == 0) {
        return false;
      }

      const review = fromData<Review>(qs.docs[0].data());
      const now = TrueTime.now();

      return now.diff(review.creationDate, 'days') < 30;
    }
    catch(error) {
      logError('Failed to fetch last review: ', error);
      return false;
    }
  }

  async fetchClientInvites(userId: string): Promise<OperationResponse<CustomerSubscription[]>> {
    try {
      const document = this.locator.customerInvites(userId);
      const subscriptions: CustomerSubscription[] = [];
      const snapshot = await getDoc(document);

      const data = snapshot.data();
      if(data) {
        for(const key of Object.keys(data)) {
          subscriptions.push(fromData<CustomerSubscription>(data[key]));
        }
      }

      return OperationResponse.success(subscriptions);
    }
    catch(error) {
      logError('Failed to get customer business invites: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchDiscoverContent(route: string): Promise<OperationResponse<DiscoverContent>> {
    try {
      const docQuery = query(this.locator.discover, where('webRoute', '==', route), limit(1));
      const snapshot = await getDocs(docQuery);

      if(snapshot.docs.length > 0) {
        const route = fromData<DiscoverContent>(snapshot.docs[0].data());
        return OperationResponse.success(route);
      }

      return OperationResponse.error(ErrorType.DocumentNotFound);
    }
    catch(error) {
      logError('Failed to fetch discover page content: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchNewsContent(route: string): Promise<OperationResponse<NewsContent>> {
    try {
      const docQuery = query(this.locator.news, where('webRoute', '==', route), limit(1));
      const snapshot = await getDocs(docQuery);

      if(snapshot.docs.length > 0) {
        const route = fromData<NewsContent>(snapshot.docs[0].data());
        return OperationResponse.success(route);
      }

      return OperationResponse.error(ErrorType.DocumentNotFound);
    }
    catch(error) {
      logError('Failed to fetch news page content: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchClientById(businessRef: BusinessModuleRef, clientId: string | null): Promise<OperationResponse<CustomerProfile>> {
    if(clientId === null) {
      return OperationResponse.error(ErrorType.DocumentNotFound);
    }

    try {
      const document = this.locator.customer(businessRef, clientId);
      const snapshot = await getDoc(document);

      return snapshot.exists() ?
        OperationResponse.success(fromData<CustomerProfile>(snapshot.data() as SerializedData)):
        OperationResponse.error(ErrorType.DocumentNotFound);
    }
    catch(error) {
      logError('Failed to fetch client by ID: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchPaymentIntent(eventId: string): Promise<OperationResponse<PaymentIntent>> {
    try {
      const document = this.locator.paymentIntent(eventId);
      const snapshot = await getDoc(document);

      return snapshot.exists() ?
        OperationResponse.success(fromData<PaymentIntent>(snapshot.data())) :
        OperationResponse.error(ErrorType.DocumentNotFound);
    }
    catch(error) {
      logError(`Failed to fetch payment event {${eventId}}: `, error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchTicketPool(businessRef: BusinessModuleRef, eventId: string): Promise<OperationResponse<ITicketPool>> {
    try {
      const document = this.locator.ticketPool(businessRef, eventId);
      const response = await getDoc(document);

      if(response.exists()) {
        return OperationResponse.success(fromData<ITicketPool>(response.data()));
      }

      return OperationResponse.error(ErrorType.DocumentNotFound);
    }
    catch(error) {
      logError('Failed to fetch ticket pool: ', error);
      return OperationResponse.error(ErrorType.DocumentNotFound);
    }
  }

  streamTicketPool(businessRef: BusinessModuleRef, eventId: string): Observable<ITicketPool | null> {
    try {
      const document = this.locator.ticketPool(businessRef, eventId);
      return docSnapshots(document).pipe(
        map((ds) => {
          const data = ds.data();
          return isNotNullOrUndefined(data) ? fromData<ITicketPool>(data) : null;
        })
      );
    }
    catch(error) {
      console.error('Failed to stream ticket pool: ', error);
      return of<ITicketPool | null>(null);
    }
  }

  streamSeatSelectionLock(businessRef: BusinessModuleRef, eventId: string): Observable<EventSeatSelectionLock | null> {
    try {
      const document = this.locator.seatSelectionLock(businessRef, eventId);
      return docSnapshots(document).pipe(
        map((ds) => {
          const data = ds.data();
          return isNotNullOrUndefined(data) ? fromData<EventSeatSelectionLock>(data) : null;
        })
      );
    }
    catch(error) {
      console.error('Failed to stream seat selection lock: ', error);
      return of<EventSeatSelectionLock | null>(null);
    }
  }

  async fetchCustomerTicketOrders(userId: string, filter?: (order: OnlineEventTicketOrder) => boolean): Promise<OperationResponse<OnlineEventTicketOrder[]>> {
    try {
      const customerOrders = query(
        this.locator.customerOrders(userId),
        where('$type_id', '==', OnlineEventTicketOrder.TYPE_ID),
      );

      const response = await getDocs(customerOrders);
      const orders: OnlineEventTicketOrder[] = [];

      for(const order of response.docs) {
        const result = fromDataSafe<OnlineEventTicketOrder>(order.data());
        if(result.isOk && (filter == null || filter(result.value))) {
          orders.push(result.value);
        }
      }

      return OperationResponse.success(orders);
    }
    catch(error) {
      logError('Failed to fetch customer ticket orders: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchPromoCode(businessRef: BusinessModuleRef, eventId: string, voucherId: string): Promise<OperationResponse<Voucher>> {
    try {
      const promoCodeQuery = query(
        this.locator.promoCodes(businessRef),
        where('code', '==', voucherId),
        where('$status', '==', Voucher.encodedStatusSafe(VoucherStatus.Live)),
      );

      const snapshot = await getDocs(promoCodeQuery);
      let result: Voucher | null = null;

      for(const ds of snapshot.docs) {
        const promoCode = fromData<Voucher>(ds.data());
        if(EventPromoCode.assertType(promoCode)) {
          if(promoCode.eventId === eventId) {
            result = promoCode;
            break;
          }
        }
        else if(TrackingPromoCode.assertType(promoCode)) {
          result = promoCode;
          if(promoCode.eventId === eventId) break;
        }
        else if(BusinessPromoCode.assertType(promoCode)) {
          result = promoCode;
          break;
        }
      }

      return isNotNullOrUndefined(result) ?
        OperationResponse.success(result):
        OperationResponse.error(ErrorType.PromoCodeNotFound);
    }
    catch(error) {
      logError(`Failed to fetch promo code {${voucherId}} of {${businessRef}}: `, error);
      return OperationResponse.error(ErrorType.PromoCodeNotFound);
    }
  }

  async fetchVoucher(businessRef: BusinessModuleRef, uniqueId: string): Promise<OperationResponse<Voucher>> {
    try {
      const document = this.locator.voucher(businessRef, uniqueId.toUpperCase());
      const snapshot = await getDoc(document);
      const result = fromDataOrNull<Voucher>(snapshot.data() ?? null);

      return isNotNullOrUndefined(result) && result.status === VoucherStatus.Live ?
        OperationResponse.success(result):
        OperationResponse.error(ErrorType.PromoCodeNotFound);
    }
    catch(error) {
      logError(`Failed to fetch voucher {${uniqueId}} of {${businessRef}}: `, error);
      return OperationResponse.error(ErrorType.PromoCodeNotFound);
    }
  }

  async fetchSeatingLayout(businessRef: BusinessModuleRef, eventId: string, seatingLayoutId: string): Promise<OperationResponse<SeatingLayout>> {
    try {
      const document = this.locator.seatingLayout(businessRef, eventId, seatingLayoutId);
      const snapshot = await getDoc(document);
      const result = fromDataOrNull<SeatingLayout>(snapshot.data() ?? null);

      return isNotNullOrUndefined(result) ?
        OperationResponse.success(result):
        OperationResponse.error(ErrorType.DocumentNotFound);
    }
    catch(error) {
      logError(`Failed to fetch seating layout {${seatingLayoutId}} for event {${eventId}} of {${businessRef}}: ${error}`);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchHomePageInfo(): Promise<OperationResponse<HomePageInfo>> {
    try {
      const doc = this.locator.homePageInfo;
      const snapshot = await getDoc(doc);

      const data = snapshot.data();
      if(isNullOrUndefined(data)) {
        return OperationResponse.error(ErrorType.DocumentNotFound);
      }

      const result = fromDataSafe<HomePageInfo>(data as SerializedData);
      if(result.isOk) {
        return OperationResponse.success(result.value);
      }
      else {
        logError('Failed to deserialize home page info: ', result.error);
        return OperationResponse.error(ErrorType.DatabaseError);
      }
    }
    catch(error) {
      logError('Failed to fetch home page info: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }

  async fetchSearchSnapshot(): Promise<OperationResponse<SearchSnapshot>> {
    try {
      const doc = this.locator.searchSnapshot;
      const snapshot = await getDoc(doc);

      const data = snapshot.data();
      if(isNullOrUndefined(data)) {
        return OperationResponse.error(ErrorType.DocumentNotFound);
      }

      const result = fromDataSafe<SearchSnapshot>(data as SerializedData);
      if(result.isOk) {
        return OperationResponse.success(result.value);
      }
      else {
        logError('Failed to deserialize search snapshot: ', result.error);
        return OperationResponse.error(ErrorType.DatabaseError);
      }
    }
    catch(error) {
      logError('Failed to fetch search snapshot: ', error);
      return OperationResponse.error(ErrorType.DatabaseError);
    }
  }
}
