import { BehaviorSubject, Observable, debounceTime, of } from 'rxjs';

const kSearchDebounceTime: number = 300;

export abstract class IQueryHandler {
  abstract get query$(): Observable<string>;
  abstract get query(): string;
  abstract get uniqueId(): string;
  abstract reset(): void;
  abstract update(query: string): void;
}

export class QueryHandler extends IQueryHandler {
  private readonly querySnapshots: BehaviorSubject<string>;

  override get query$(): Observable<string> {
    return this.querySnapshots.pipe(debounceTime(kSearchDebounceTime));
  }

  override get query(): string {
    return this.querySnapshots.value;
  }

  constructor(public readonly uniqueId: string) {
    super();
    this.querySnapshots = new BehaviorSubject<string>('');
  }

  override reset(): void {
    this.querySnapshots.next('');
  }

  override update(query: string): void {
    this.querySnapshots.next(query);
  }
}

export class RedirectQueryHandler extends IQueryHandler {
  private readonly querySnapshots: Observable<string>;
  private readonly initialQuery: string;

  override get query$(): Observable<string> {
    return this.querySnapshots;
  }

  override get query(): string {
    return this.initialQuery;
  }

  constructor(
    public readonly uniqueId: string,
    private readonly redirectHandler: () => void,
    private readonly source: IQueryHandler | null
  ) {
    super();
    this.initialQuery = source?.query ?? '';
    this.querySnapshots = of(this.initialQuery);
  }

  override reset(): void {
    // nothing to do here!
  }

  override update(query: string): void {
    if(this.source != null) {
      this.source.update(query);
    }

    this.redirectHandler();
  }
}