
export enum ResourceStatus {
  /// The initial data stream state, before any data has been fetched from the database.
  Uninitialized,
  /// Data is currently being fetched from the database.
  Loading,
  /// Data has been successfully fetched from the database.
  Success,
  /// There was an error while fetching data from the database.
  Error
}
