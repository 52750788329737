import { Injectable, inject } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { STORAGE_MENU_FAVORITES } from '../core/utils/storage-keys';
import { FavoriteMenuItem } from '../core/models/favorite-menu-item';

@Injectable({
  providedIn: 'root'
})
export class MenuFavoritesService {
  private favorites: Map<string, FavoriteMenuItem[]> = new Map<string, FavoriteMenuItem[]>();

  private readonly localStorageService: LocalStorageService | null = inject(LocalStorageService, { optional: true });

  getFavorites(businessId: string): FavoriteMenuItem[] {
    return this.favorites.get(businessId) ?? [];
  }

  hasFavorite(businessId: string, itemId: string): boolean {
    const list = this.favorites.get(businessId);
    if(list != null) {
      for(let i = 0; i < list.length; i++) {
        if(list[i].uniqueId === itemId) {
          return true;
        }
      }
    }

    return false;
  }

  addFavorite(businessId: string, favorite: FavoriteMenuItem): void {
    this.updateFavorites(businessId, (list) => {
      list.push(favorite);
      return true;
    });
  }

  removeFavorite(businessId: string, itemId: string): boolean {
    return this.updateFavorites(businessId, (list) => {
      const index = list.findIndex((item) => item.uniqueId === itemId);
      if(index >= 0) {
        list.splice(index, 1);
      }

      return index >= 0;
    });
  }

  private updateFavorites(businessId: string, callback: (list: FavoriteMenuItem[]) => boolean): boolean {
    let list = this.favorites.get(businessId);
    if(list == null) {
      list = [];
      this.favorites.set(businessId, list);
    }

    const hasChanged = callback(list);
    if(hasChanged) {
      this.save();
    }

    return hasChanged;
  }

  load(): void {
    const serializedFavorites = this.localStorageService?.retrieve(STORAGE_MENU_FAVORITES);
    if(serializedFavorites != null) {
      this.favorites = new Map<string, FavoriteMenuItem[]>(JSON.parse(serializedFavorites));
    }
    else {
      this.favorites = new Map<string, FavoriteMenuItem[]>();
    }
  }

  private save(): void {
    if(this.localStorageService != null) {
      this.localStorageService.store(STORAGE_MENU_FAVORITES, JSON.stringify(Array.from(this.favorites.entries())));
    }
  }
}