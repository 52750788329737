import { IDataComparable, OperationResponse, SerializedData } from 'in-time-core';
import { ErrorType } from '../../../core/models/error-type';

export class PhoneVerificationResult implements IDataComparable {
  constructor(
    public readonly success: boolean,
    public readonly error: ErrorType | null,
    public readonly phoneNumber: string,
    public readonly verificationId: string | null,
    public readonly resendToken: number | null, // TODO(emil): figure out if we need this.
  ) {}

  static success(
    phoneNumber: string,
    verificationId: string,
    resendToken: number | null = null
  ): PhoneVerificationResult {
    return new PhoneVerificationResult(true, null, phoneNumber, verificationId, resendToken);
  }

  static error(phoneNumber: string, error: ErrorType): PhoneVerificationResult {
    return new PhoneVerificationResult(false, error, phoneNumber, null, null);
  }

  toAsyncResponse(): OperationResponse<SerializedData> {
    if(this.success) {
      return OperationResponse.success({
        'verificationId': this.verificationId,
        'resendToken': this.resendToken,
        'phoneNumber': this.phoneNumber,
      });
    }
    else {
      return OperationResponse.error(this.error ?? '');
    }
  }

  isSame(other: this): boolean {
    if(other === this) {
      return true;
    }

    return other.success === this.success &&
        other.error === this.error &&
        other.phoneNumber === this.phoneNumber &&
        other.verificationId === this.verificationId &&
        other.resendToken === this.resendToken;
  }

  toString(): string {
    if(this.success) {
      return `
  {
    success: true,
    phoneNumber: ${this.phoneNumber},
    verificationId: ${this.verificationId},
    resendToken: ${this.resendToken},
  }
      `;
    }
    else {
      return `
  {
    success: true,
    error: ${this.error},
  }
      `;
    }
  }
}
