import { User } from '@angular/fire/auth';
import { AuthenticatorType } from './authenticator-type';
import { ErrorType } from '../../../core/models/error-type';

export class AuthenticationResult {
  constructor(
    public readonly canceledByUser: boolean,
    public readonly success: boolean,
    public readonly error: ErrorType | null,
    public readonly user: User | null,
    public readonly authenticator: AuthenticatorType | null,
  ) {}

  static authenticated(user: User, authenticator: AuthenticatorType): AuthenticationResult {
    return new AuthenticationResult(false, true, null, user, authenticator);
  }

  static canceledByUser(): AuthenticationResult {
    return new AuthenticationResult(true, false, null, null, null);
  }

  static error(error: ErrorType): AuthenticationResult {
    return new AuthenticationResult(false, false, error, null, null);
  }
}
