/* eslint-disable @typescript-eslint/no-unused-vars */
import { TrueTime, fromEnumAsString, toSafeFilename } from 'in-time-core';
import { AnalyticsTracker } from './analytics-tracker';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { logError, logInfo } from 'in-time-logger';
import {
  GA_EVT, GA_EVT_CTM_COMPLETE_REGISTRATION, GA_EVT_CTM_CONTENT_VIEW, GA_EVT_CTM_COOKIES_ACCEPTED,
  GA_EVT_CTM_COOKIES_DECLINED, GA_EVT_CTM_EXPLORE, GA_EVT_CTM_INITIATE_CHECKOUT, GA_EVT_CTM_INITIATE_PURCHASE,
  GA_EVT_CTM_PROMO_CODE_ENTERED, GA_EVT_CTM_PURCHASE_CANCELED, GA_EVT_CTM_PURCHASE_FAILED,
  GA_EVT_CTM_SEARCH, GA_EVT_CTM_SIGN_UP, GA_EVT_STD_ADD_TO_CART, GA_EVT_STD_PAGE_VIEW,
  GA_EVT_STD_PURCHASE,
} from './analytics.events';
import {
  AddToCartEventArgs, AnalyticsTrackerType, CompleteRegistrationEventArgs, ContentViewEventArgs,
  InitiateCheckoutEventArgs, InitiatePurchaseEventArgs, PageViewEventArgs, PurchaseCanceledEventArgs,
  PurchaseFailedEventArgs, PurchaseCompleteEventArgs, SearchEventArgs, SignUpEventArgs,
  PromoCodeEventArgs,
  ExploreEventArgs
} from './analytics.types';

export class GoogleAnalyticsTracker extends AnalyticsTracker {
  private hasAcceptedCookies: boolean = false;
  private hasBeenInitialized: boolean = false;

  public readonly $typeId: AnalyticsTrackerType = 'google-analytics';

  static create(opts: { analytics: Analytics }): GoogleAnalyticsTracker {
    return new GoogleAnalyticsTracker(opts.analytics);
  }

  private constructor(
    private readonly analytics: Analytics,
  ) {
    super();
  }

  onInit(hasAcceptedCookies: boolean): void {
    this.hasAcceptedCookies = hasAcceptedCookies;
    this.hasBeenInitialized = true;
  }

  onDestroy(): void {
    // nothing to do here :)
  }

  onCookiesAccepted(): void {
    this.hasAcceptedCookies = true;
    this.track(GA_EVT_CTM_COOKIES_ACCEPTED, {});
  }

  onCookiesDeclined(): void {
    this.track(GA_EVT_CTM_COOKIES_DECLINED, {});
    this.hasAcceptedCookies = false;
  }

  trackPromoCode(args: PromoCodeEventArgs): void {
    this.track(GA_EVT_CTM_PROMO_CODE_ENTERED, {
      promo_code: args.promoCode,
    });
  }

  trackSignUp(args: SignUpEventArgs): void {
    this.track(GA_EVT_CTM_SIGN_UP, {
      auth_providers: args.authProviders,
      marketing_consent: args.marketingConsent ?? 'N/A',
    });
  }

  trackPageView(args: PageViewEventArgs): void {
    this.track(GA_EVT_STD_PAGE_VIEW, {
      page_title: args.title,
      ...args.queryParams,
    });
  }

  trackContentView(args: ContentViewEventArgs): void {
    this.track(GA_EVT_CTM_CONTENT_VIEW, {
      page_title: args.title,
      category: args.category,
      title: args.title,
      eventId: args.contentId,
      businessId: args.businessId,
      organizationId: args.organizationId,
      ...args.queryParams,
    });
  }

  trackSearch(args: SearchEventArgs): void {
    this.track(GA_EVT_CTM_SEARCH, {
      search_string: args.query,
      content_category: args.category,
      ...args.queryParams,
    });
  }

  trackExplore(args: ExploreEventArgs): void {
    this.track(GA_EVT_CTM_EXPLORE, {
      route: args.id,
      via: args.via,
      event_name: args.eventName,
      event_id: args.eventId,
      business_id: args.businessId,
      organization_id: args.organizationId,
    });
  }

  trackAddToCart(args: AddToCartEventArgs): void {
    this.track(GA_EVT_STD_ADD_TO_CART, {
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      event_name: args.eventName,
      event_id: args.eventId,
      business_id: args.businessId,
      organization_id: args.organizationId,
      items: args.cart.items.map((item) => ({
        item_id: item.uniqueId,
        item_name: item.name.getAny(args.language) ?? item.uniqueId,
        currency: fromEnumAsString(item.unitPrice.currency),
        price: item.unitPrice.asDouble,
        quantity: item.quantity,
      }))
    });

    this.track(`AddToCart_${this.toSafeEventName(args.eventName, args.eventDate)}`, {
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      event_name: args.eventName,
      event_id: args.eventId,
      business_id: args.businessId,
      organization_id: args.organizationId,
      items: args.cart.items.map((item) => ({
        item_id: item.uniqueId,
        item_name: item.name.getAny(args.language) ?? item.uniqueId,
        currency: fromEnumAsString(item.unitPrice.currency),
        price: item.unitPrice.asDouble,
        quantity: item.quantity,
      }))
    });
  }

  trackInitiateCheckout(args: InitiateCheckoutEventArgs): void {
    this.track(GA_EVT_CTM_INITIATE_CHECKOUT, {
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      event_name: args.eventName,
      event_id: args.eventId,
      business_id: args.businessId,
      organization_id: args.organizationId,
      items: args.cart.items.map((item) => ({
        item_id: item.uniqueId,
        item_name: item.name.getAny(args.language) ?? item.uniqueId,
        currency: fromEnumAsString(item.totalPrice.currency),
        price: item.unitPrice.asDouble,
        quantity: item.quantity,
      }))
    });
  }

  trackCompleteRegistration(args: CompleteRegistrationEventArgs): void {
    this.track(GA_EVT_CTM_COMPLETE_REGISTRATION, {
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      event_name: args.eventName,
      event_id: args.eventId,
      business_id: args.businessId,
      organization_id: args.organizationId,
      items: args.cart.items.map((item) => ({
        item_id: item.uniqueId,
        item_name: item.name.getAny(args.language) ?? item.uniqueId,
        currency: fromEnumAsString(item.totalPrice.currency),
        price: item.unitPrice.asDouble,
        quantity: item.quantity,
      })),
    });
  }

  trackInitiatePurchase(args: InitiatePurchaseEventArgs): void {
    this.track(GA_EVT_CTM_INITIATE_PURCHASE, {
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      event_name: args.eventName,
      event_id: args.eventId,
      business_id: args.businessId,
      organization_id: args.organizationId,
      items: args.cart.items.map((item) => ({
        item_id: item.uniqueId,
        item_name: item.name.getAny(args.language) ?? item.uniqueId,
        currency: fromEnumAsString(item.totalPrice.currency),
        price: item.unitPrice.asDouble,
        quantity: item.quantity,
      }))
    });
  }

  trackPurchaseComplete(args: PurchaseCompleteEventArgs): void {
    this.track(GA_EVT_STD_PURCHASE, {
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      event_name: args.eventName,
      event_id: args.eventId,
      business_id: args.businessId,
      organization_id: args.organizationId,
      items: args.cart.items.map((item) => ({
        item_id: item.uniqueId,
        item_name: item.name.getAny(args.language) ?? item.uniqueId,
        currency: fromEnumAsString(item.unitPrice.currency),
        price: item.unitPrice.asDouble,
        quantity: item.quantity,
      }))
    });

    this.track(`Purchase_${this.toSafeEventName(args.eventName, args.eventDate)}`, {
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      event_name: args.eventName,
      event_id: args.eventId,
      business_id: args.businessId,
      organization_id: args.organizationId,
      items: args.cart.items.map((item) => ({
        item_id: item.uniqueId,
        item_name: item.name.getAny(args.language) ?? item.uniqueId,
        currency: fromEnumAsString(item.unitPrice.currency),
        price: item.unitPrice.asDouble,
        quantity: item.quantity,
      }))
    });
  }

  trackPurchaseFailed(args: PurchaseFailedEventArgs): void {
    this.track(GA_EVT_CTM_PURCHASE_FAILED, {
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      event_name: args.eventName,
      event_id: args.eventId,
      business_id: args.businessId,
      organization_id: args.organizationId,
      items: args.cart.items.map((item) => ({
        item_id: item.uniqueId,
        item_name: item.name.getAny(args.language) ?? item.uniqueId,
        currency: fromEnumAsString(item.unitPrice.currency),
        price: item.unitPrice.asDouble,
        quantity: item.quantity,
      }))
    });
  }

  trackPurchaseCanceled(args: PurchaseCanceledEventArgs): void {
    this.track(GA_EVT_CTM_PURCHASE_CANCELED, {
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      event_name: args.eventName,
      event_id: args.eventId,
      business_id: args.businessId,
      organization_id: args.organizationId,
      items: args.cart.items.map((item) => ({
        item_id: item.uniqueId,
        item_name: item.name.getAny(args.language) ?? item.uniqueId,
        currency: fromEnumAsString(item.unitPrice.currency),
        price: item.unitPrice.asDouble,
        quantity: item.quantity,
      }))
    });
  }

  private track(eventId: string, parameters: object): void {
    if(!this.hasBeenInitialized) return;
    if(!this.hasAcceptedCookies) return;

    try {
      const now = TrueTime.now();
      const hour = now.hour();
      const weekday = now.format('dddd');

      logEvent(
        this.analytics,
        eventId as string, {
          web: true,
          creation_hour: hour,
          creation_weekday: weekday,
          ...parameters,
        }
      );
      logInfo(`Tracking Google Analytics event {${eventId}} with params: ${JSON.stringify(parameters, null, 2)}`);
    }
    catch(error) {
      logError(`Failed to Google Analytics event {${eventId}} with params: ${JSON.stringify(parameters, null, 2)}`, error);
    }
  }
}