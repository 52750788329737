/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { PhoneNumber, fromEnumAsString, generateUniqueDatabaseId, isNullOrUndefinedOrEmpty, toSafeFilename } from 'in-time-core';
import { logError, logInfo } from 'in-time-logger';
import { Renderer2 } from '@angular/core';
import { AnalyticsTracker } from './analytics-tracker';
import { hashString } from '../../core/utils/crypto-utils';
import {
  AnalyticsTrackerType, CartEventArgs, ContentViewEventArgs, ExploreEventArgs, PageViewEventArgs,
  PromoCodeEventArgs, SearchEventArgs, SignUpEventArgs
} from './analytics.types';
import {
  TTK_EVT, TTK_EVT_STD_ADD_TO_CART, TTK_EVT_STD_COMPLETE_REGISTRATION, TTK_EVT_STD_INITIATE_CHECKOUT,
  TTK_EVT_STD_INITIATE_PURCHASE, TTK_EVT_STD_PURCHASE, TTK_EVT_STD_SEARCH, TTK_EVT_STD_VIEW_CONTENT
} from './analytics.events';

const DEFAULT_TIKTOK_PIXEL_ID: string = 'CGR3TU3C77U5RBGMFLDG';
declare const ttq: any;

function getTiktokPixelCode(pixelId: string): string {
  return `!function (w, d, t) {
    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
  )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
  
    ttq.load('${pixelId}');
    ttq.page();
  }(window, document, 'ttq');`;
}

function createTiktokPixel(
  _document: Document,
  renderer: Renderer2,
  pixelId: string,
): string {
  const elementId = `tiktok-pixel-${generateUniqueDatabaseId({ length: 5, alphabet: 'UC_LETTERS_DIGITS' })}`;
  const scriptElement = renderer.createElement('script');

  renderer.setAttribute(scriptElement, 'id', elementId);
  renderer.setAttribute(scriptElement, 'type', 'text/javascript');
  renderer.setProperty(scriptElement, 'innerHTML', getTiktokPixelCode(pixelId));
  renderer.appendChild(_document.head, scriptElement);

  return elementId;
}

function destroyTiktokPixel(
  _document: Document,
  elementId: string,
): void {
  const element = _document.getElementById(elementId);
  if(element != null) {
    element.remove();
  }
}

export class TiktokTracker extends AnalyticsTracker {
  private readonly initializedPixels: { pixelId: string, elementId: string }[] = [];
  private readonly customPixelStack: string[] = [];
  private hasAcceptedCookies: boolean = false;
  private hasBeenInitialized: boolean = false;

  public readonly $typeId: AnalyticsTrackerType = 'tiktok';

  static create(opts: {
    renderer: Renderer2,
    document: Document,
  }): TiktokTracker {
    return new TiktokTracker(opts.renderer, opts.document);
  }

  private constructor(
    private readonly renderer: Renderer2,
    private readonly _document: Document,
  ) { super(); }

  onInit(hasAcceptedCookies: boolean): void {
    const elementId = createTiktokPixel(this._document, this.renderer, DEFAULT_TIKTOK_PIXEL_ID)

    this.initializedPixels.push({ pixelId: DEFAULT_TIKTOK_PIXEL_ID, elementId });
    this.hasAcceptedCookies = hasAcceptedCookies;
    this.hasBeenInitialized = true;
    logInfo('TikTok pixel script has been added!');
  }

  onDestroy(): void {
    // nothing to do here :)
  }

  onCookiesAccepted(): void {
    this.hasAcceptedCookies = true;
  }

  onCookiesDeclined(): void {
    this.hasAcceptedCookies = false;
  }

  activateCustomPixel(opts: { context: string, customPixelId: string }): void {
    if(!this.hasBeenInitialized || isNullOrUndefinedOrEmpty(opts.customPixelId)) return;

    try {
      if(!this.hasCustomPixel(opts.customPixelId)) {
        const elementId = createTiktokPixel(this._document, this.renderer, opts.customPixelId)
        this.initializedPixels.push({ pixelId: opts.customPixelId, elementId });
      }

      this.customPixelStack.push(opts.customPixelId);
      logInfo(`Custom TikTok pixel has been activated for {${opts.context}}.`);
    }
    catch(error) {
      logError(`Failed to activate custom TikTok pixel for {${opts.context}}.`, error);
    }
  }

  deactivateCustomPixel(opts: { context: string, customPixelId: string }): void {
    for(let i = this.customPixelStack.length - 1; i >= 0; i--) {
      if(this.customPixelStack[i] == opts.customPixelId) {
        this.destroyPixel(opts.customPixelId);
        this.customPixelStack.splice(i, 1);
        logInfo(`Custom TikTok pixel has been deactivated for {${opts.context}}.`);
        return;
      }
    }
  }

  trackPromoCode(args: PromoCodeEventArgs): void {
    this.track('PromoCode', {
      promo_code: args.promoCode,
    });
  }

  trackPageView(args: PageViewEventArgs): void {
    // nothing to do here :)
  }

  trackSignUp(args: SignUpEventArgs): void {
    // nothing to do here :)
  }

  trackSearch(args: SearchEventArgs): void {
    this.tryIdentify(args);
    this.track(TTK_EVT_STD_SEARCH, {
      'query': args.query, // string. The user-entered string for search.
    });
  }

  trackExplore(args: ExploreEventArgs): void {
    // nothing to do here :)
  }

  trackContentView(args: ContentViewEventArgs): void {
    this.tryIdentify(args);
    this.track(TTK_EVT_STD_VIEW_CONTENT, {
      'contents': [
        {
          'content_id': args.contentId, // string. ID of the product. Example: "1077218".
          'content_name': args.title, // string. The name of the page or product. Example: "shirt".
          'content_category': args.category, // string. The category of the page or product. Example: "apparel".
        }
      ],
      'content_type': 'product', // string. Either product or product_group.
    });
  }

  trackAddToCart(args: CartEventArgs): void {
    this.tryIdentify(args);

    this.track(TTK_EVT_STD_ADD_TO_CART, {
      'contents': [
        {
          'content_id': args.eventId, // string. ID of the product. Example: "1077218".
          'content_name': args.eventName, // string. The name of the page or product. Example: "shirt".
          'content_category': 'event', // string. The category of the page or product. Example: "apparel".
          'quantity': args.cart.totalQuantity // number. The number of items. Example: 4.
        }
      ],
      'content_type': 'product', // string. Either product or product_group.
      'value': args.cartPrice.asDouble, // number. Value of the order or items sold. Example: 100.
      'currency': fromEnumAsString(args.cartPrice.currency) // string. The 4217 currency code. Example: "USD".
    });

    this.track(`AddToCart_${this.toSafeEventName(args.eventName, args.eventDate)}`, {
      'contents': [
        {
          'content_id': args.eventId, // string. ID of the product. Example: "1077218".
          'content_name': args.eventName, // string. The name of the page or product. Example: "shirt".
          'content_category': 'event', // string. The category of the page or product. Example: "apparel".
          'quantity': args.cart.totalQuantity // number. The number of items. Example: 4.
        }
      ],
      'content_type': 'product', // string. Either product or product_group.
      'value': args.cartPrice.asDouble, // number. Value of the order or items sold. Example: 100.
      'currency': fromEnumAsString(args.cartPrice.currency) // string. The 4217 currency code. Example: "USD".
    });
  }

  trackInitiateCheckout(args: CartEventArgs): void {
    this.tryIdentify(args);
    this.track(TTK_EVT_STD_INITIATE_CHECKOUT, {
      'contents': [
        {
          'content_id': args.eventId, // string. ID of the product. Example: "1077218".
          'content_name': args.eventName, // string. The name of the page or product. Example: "shirt".
          'content_category': 'event', // string. The category of the page or product. Example: "apparel".
          'quantity': args.cart.totalQuantity // number. The number of items. Example: 4.
        }
      ],
      'content_type': 'product', // string. Either product or product_group.
      'value': args.cartPrice.asDouble, // number. Value of the order or items sold. Example: 100.
      'currency': fromEnumAsString(args.cartPrice.currency) // string. The 4217 currency code. Example: "USD".
    });
  }

  trackCompleteRegistration(args: CartEventArgs): void {
    this.tryIdentify(args);
    this.track(TTK_EVT_STD_COMPLETE_REGISTRATION, {
      'contents': [
        {
          'content_id': args.eventId, // string. ID of the product. Example: "1077218".
          'content_name': args.eventName, // string. The name of the page or product. Example: "shirt".
          'content_category': 'event', // string. The category of the page or product. Example: "apparel".
        },
      ],
      'content_type': 'product', // string. Either product or product_group.
    });
  }

  trackInitiatePurchase(args: CartEventArgs): void {
    this.tryIdentify(args);
    this.track(TTK_EVT_STD_INITIATE_PURCHASE, {
      'contents': [
        {
          'content_id': args.eventId, // string. ID of the product. Example: "1077218".
          'content_name': args.eventName, // string. The name of the page or product. Example: "shirt".
          'content_category': 'event', // string. The category of the page or product. Example: "apparel".
          'quantity': args.cart.totalQuantity // number. The number of items. Example: 4.
        }
      ],
      'content_type': 'product', // string. Either product or product_group.
      'value': args.cartPrice.asDouble, // number. Value of the order or items sold. Example: 100.
      'currency': fromEnumAsString(args.cartPrice.currency) // string. The 4217 currency code. Example: "USD".
    });
  }

  trackPurchaseComplete(args: CartEventArgs): void {
    this.tryIdentify(args);

    this.track(TTK_EVT_STD_PURCHASE, {
      'contents': [
        {
          'content_id': args.eventId, // string. ID of the product. Example: "1077218".
          'content_name': args.eventName, // string. The name of the page or product. Example: "shirt".
          'content_category': 'event', // string. The category of the page or product. Example: "apparel".
          'quantity': args.cart.totalQuantity // number. The number of items. Example: 4.
        }
      ],
      'content_type': 'product', // string. Either product or product_group.
      'value': args.cartPrice.asDouble, // number. Value of the order or items sold. Example: 100.
      'currency': fromEnumAsString(args.cartPrice.currency) // string. The 4217 currency code. Example: "USD".
    });

    this.track(`Purchase_${this.toSafeEventName(args.eventName, args.eventDate)}`, {
      'contents': [
        {
          'content_id': args.eventId, // string. ID of the product. Example: "1077218".
          'content_name': args.eventName, // string. The name of the page or product. Example: "shirt".
          'content_category': 'event', // string. The category of the page or product. Example: "apparel".
          'quantity': args.cart.totalQuantity // number. The number of items. Example: 4.
        }
      ],
      'content_type': 'product', // string. Either product or product_group.
      'value': args.cartPrice.asDouble, // number. Value of the order or items sold. Example: 100.
      'currency': fromEnumAsString(args.cartPrice.currency) // string. The 4217 currency code. Example: "USD".
    });
  }

  trackPurchaseFailed(args: CartEventArgs): void {
    // nothing to do here :)
  }

  trackPurchaseCanceled(args: CartEventArgs): void {
    // nothing to do here :)
  }

  private track(eventId: string, parameters: unknown): void {
    if(!this.hasBeenInitialized) return;
    if(!this.hasAcceptedCookies) return;

    try {
      ttq.instance(DEFAULT_TIKTOK_PIXEL_ID).track(eventId, parameters);
      if(this.customPixelStack.length > 0) {
        ttq.instance(this.customPixelStack[this.customPixelStack.length - 1]).track(eventId, parameters);
      }

      logInfo(`Tracking TikTok event {${eventId}} with params: ${JSON.stringify(parameters, null, 2)}`);
    }
    catch(error) {
      logError(`Failed to track TikTok event {${eventId}} with params: ${JSON.stringify(parameters, null, 2)}`, error);
    }
  }

  private tryIdentify(identity: { email: string | null, phoneNumber: PhoneNumber | null }): void {
    if(!this.hasBeenInitialized) return;
    if(!this.hasAcceptedCookies) return;

    if(identity.email == null && identity.phoneNumber == null) {
      return;
    }

    try {
      const email: string | undefined = identity.email == null ? undefined :
        hashString(identity.email.trim().toLowerCase(), 'sha256'); // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.

      const phone_number: string | undefined = identity.phoneNumber == null ? undefined :
        hashString(identity.phoneNumber.toISOE164(), 'sha256'); // string. The phone number of the customer if available. Normalize your phone numbers to E.164 format and hash with SHA-256 on the client side.

      ttq.instance(DEFAULT_TIKTOK_PIXEL_ID).identify({ email, phone_number });
      if(this.customPixelStack.length > 0) {
        ttq.instance(this.customPixelStack[this.customPixelStack.length - 1]).identify({ email, phone_number });
      }
    }
    catch(error) {
      logError('Failed to identify user when tracking TikTok event.', error);
    }
  }

  private hasCustomPixel(pixelId: string): boolean {
    for(let i = 0; i < this.initializedPixels.length; i++) {
      if(this.initializedPixels[i].pixelId == pixelId) {
        return true;
      }
    }

    return false;
  }

  private destroyPixel(pixelId: string): boolean {
    for(let i = this.initializedPixels.length - 1; i >= 0; i--) {
      if(this.initializedPixels[i].pixelId == pixelId) {
        destroyTiktokPixel(this._document, this.initializedPixels[i].elementId);
        this.initializedPixels.splice(i, 1);
        return true;
      }
    }

    return false;
  }
}