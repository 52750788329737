import { DataModel, fromDataOrNull, isNullOrUndefined, MapUtils, OperationResponse, SerializedData, toData } from 'in-time-core';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { DatabaseService } from './database.service';
import { User } from '@angular/fire/auth';
import { logInfo, logWarning } from 'in-time-logger';
import { ErrorType } from '../core/models/error-type';
import { AutoSaveController } from '../core/utils/auto-save.controller';
import { IAuthenticationPlugin } from './auth/utility/authentication-plugin';
import { AuthenticationState } from './auth/models/authentication-state';

type CloudSaveImplementationBuilder = (authenticationService: AuthenticationService, databaseService: DatabaseService) => CloudSaveServiceImplBase;

abstract class CloudSaveServiceBase {
  private readonly accountServiceImpl: CloudSaveServiceImplBase;
  private readonly authenticationService: AuthenticationService;

  constructor(
    authenticationService: AuthenticationService,
    databaseService: DatabaseService,
    builder: CloudSaveImplementationBuilder,
  ) {
    this.authenticationService = authenticationService;

    this.accountServiceImpl = builder(authenticationService, databaseService);
    this.authenticationService.addPlugin(this.accountServiceImpl);
  }

  get sync$(): Observable<void> {
    return this.accountServiceImpl.sync$;
  }

  getDataModel<T extends DataModel>(key: string): T | null {
    return this.accountServiceImpl.getDataModel<T>(key);
  }

  getBool(key: string, defValue: boolean | null = null): boolean | null {
    return this.accountServiceImpl.getBool(key, defValue);
  }

  getNumber(key: string, defValue: number | null = null): number | null {
    return this.accountServiceImpl.getNumber(key, defValue);
  }

  getString(key: string, defValue: string | null = null): string | null {
    return this.accountServiceImpl.getString(key, defValue);
  }

  containsKey(key: string): boolean {
    return this.accountServiceImpl.containsKey(key);
  }

  setDataModel(key: string, model: DataModel): void {
    this.accountServiceImpl.setDataModel(key, model);
  }

  setBool(key: string, value: boolean) {
    this.accountServiceImpl.setBool(key, value);
  }

  setNumber(key: string, value: number) {
    this.accountServiceImpl.setNumber(key, value);
  }

  setString(key: string, value: string) {
    this.accountServiceImpl.setString(key, value);
  }

  remove(key: string) {
    this.accountServiceImpl.remove(key);
  }

  clear(): void {
    this.accountServiceImpl.clear();
  }

  protected dispose(): void {
    this.authenticationService.removePlugin(this.accountServiceImpl);
    this.accountServiceImpl.dispose();
  }
}

abstract class CloudSaveServiceImplBase implements IAuthenticationPlugin {
  private autoSaveController: AutoSaveController<SerializedData> | null = null;
  private syncSnapshots: Subject<void> = new Subject<void>();
  private data: SerializedData = {};

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly databaseService: DatabaseService,
  ) {
  }

  get sync$(): Observable<void> {
    return this.syncSnapshots;
  }

  private get authenticatedUserId(): string | null {
    return this.authenticationService.authState.user?.uid ?? null;
  }

  async onCheckSignIn(user: User): Promise<boolean | null> {
    return null;
  }

  async onWillSignIn(): Promise<void> {
    // nothing to do here!
  }

  async onSignIn(authState: AuthenticationState): Promise<void> {
    this.resetInternalState({ notifyListeners: false });

    if(authState.isAuthenticated && authState.user != null) {
      const response = await this.downloadDataFromCloud(authState.user.uid);
      if(response.success) {
        this.data = response.data;
        this.autoSaveController = new AutoSaveController<SerializedData>(
          (data) => {
            if(this.authenticatedUserId != null && this.authenticatedUserId === authState.user?.uid) {
              this.updateCloudData(this.databaseService, authState.user.uid, data);
            }
          },
        );

        this.autoSaveController.onChange(this.data);
      }
      else {
        logWarning(`Failed to download data from cloud: ${response.error}`);
      }
    }

    this.syncSnapshots.next();
  }

  async onSignOut(): Promise<void> {
    this.resetInternalState();
  }

  dispose(): void {
    this.resetInternalState();
  }

  getDataModel<T extends DataModel>(key: string): T | null {
    return fromDataOrNull<T>(MapUtils.getOrNull<SerializedData>(this.data, key));
  }

  getBool(key: string, defValue: boolean | null = null): boolean | null {
    return MapUtils.getOrNull<boolean>(this.data, key) ?? defValue;
  }

  getNumber(key: string, defValue: number | null = null): number | null {
    return MapUtils.getOrNull<number>(this.data, key) ?? defValue;
  }

  getString(key: string, defValue: string | null): string | null {
    return MapUtils.getOrNull<string>(this.data, key) ?? defValue;
  }

  containsKey(key: string): boolean {
    // eslint-disable-next-line no-prototype-builtins
    return this.data.hasOwnProperty(key);
  }

  setDataModel(key: string, model: DataModel): void {
    this.updateData((data) => data[key] = toData(model));
  }

  setBool(key: string, value: boolean) {
    this.updateData((data) => data[key] = value);
  }

  setNumber(key: string, value: number) {
    this.updateData((data) => data[key] = value);
  }

  setString(key: string, value: string) {
    this.updateData((data) => data[key] = value);
  }

  remove(key: string) {
    this.updateData((data) => delete data[key]);
  }

  clear(): void {
    if(isNullOrUndefined(this.autoSaveController)) {
      logInfo('Unable to update cloud data. User is not authenticated.');
      return;
    }

    this.data = {};
    this.autoSaveController.onChange(this.data);
  }

  private async updateData(action: (sd: SerializedData) => void): Promise<void> {
    if(isNullOrUndefined(this.autoSaveController)) {
      logInfo('Unable to update cloud data. User is not authenticated.');
      return;
    }

    action(this.data);
    this.autoSaveController.onChange(this.data);
  }

  private resetInternalState(args?: {notifyListeners?: boolean}): void {
    this.autoSaveController?.dispose();
    this.autoSaveController = null;
    this.data = {};

    if(args?.notifyListeners) {
      this.syncSnapshots.next();
    }
  }

  private async downloadDataFromCloud(userId: string): Promise<OperationResponse<SerializedData>> {
    const response = await this.fetchCloudData(this.databaseService, userId);
    const currentUserId = this.authenticatedUserId;

    if(response.success) {
      if(isNullOrUndefined(currentUserId) || currentUserId === userId) {
        return OperationResponse.success(response.data);
      }
      else {
        return OperationResponse.error(ErrorType.IllegalOperation);
      }
    }
    else {
      return OperationResponse.error(response.error);
    }
  }

  protected abstract updateCloudData(databaseService: DatabaseService, userId: string, data: SerializedData): void;

  protected abstract fetchCloudData(databaseService: DatabaseService, userId: string): Promise<OperationResponse<SerializedData>>;
}

class CloudSaveServiceImpl extends CloudSaveServiceImplBase {
  constructor(authenticationService: AuthenticationService, databaseService: DatabaseService) {
    super(authenticationService, databaseService);
  }

  fetchCloudData(databaseService: DatabaseService, userId: string): Promise<OperationResponse<SerializedData>> {
    return databaseService.fetchCloudData(userId);
  }

  updateCloudData(databaseService: DatabaseService, userId: string, data: SerializedData): void {
    databaseService.updateCloudData(userId, data);
  }
}

class UserPrefsServiceImpl extends CloudSaveServiceImplBase {
  constructor(authenticationService: AuthenticationService, databaseService: DatabaseService) {
    super(authenticationService, databaseService);
  }

  fetchCloudData(databaseService: DatabaseService, userId: string): Promise<OperationResponse<SerializedData>> {
    return databaseService.fetchCloudPrefs(userId);
  }

  updateCloudData(databaseService: DatabaseService, userId: string, data: SerializedData): void {
    databaseService.updateCloudPrefs(userId, data);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CloudSaveService extends CloudSaveServiceBase implements OnDestroy {
  constructor(authenticationService: AuthenticationService, databaseService: DatabaseService) {
    super(
      authenticationService,
      databaseService,
      (auth, db) => new CloudSaveServiceImpl(auth, db),
    );
  }

  ngOnDestroy(): void {
    this.dispose();
  }
}

@Injectable({
  providedIn: 'root',
})
export class UserPrefsService extends CloudSaveServiceBase implements OnDestroy {
  constructor(authenticationService: AuthenticationService, databaseService: DatabaseService) {
    super(
      authenticationService,
      databaseService,
      (auth, db) => new UserPrefsServiceImpl(auth, db),
    );
  }

  ngOnDestroy(): void {
    this.dispose();
  }
}