import dayjs from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export class AutoSaveController<T> {
  private readonly saveController = new Subject<T>();
  private readonly subscription: Subscription;

  constructor(
    onSave: (value: T) => void,
    autoSaveInterval: Duration = dayjs.duration(1, 'second'),
  ) {
    this.subscription = this.saveController
      .pipe(debounceTime(autoSaveInterval.asMilliseconds()))
      .subscribe((v) => onSave(v));
  }

  onChange(event: T): void {
    this.saveController.next(event);
  }

  dispose(): void {
    this.subscription.unsubscribe();
  }
}