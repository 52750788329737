import { ErrorType } from '../../../core/models/error-type';
import { AuthenticationResult } from '../models/authentication-result';
import { AuthenticatorType } from '../models/authenticator-type';
import { Authenticator } from './authenticator';

export class AppleAuthenticator implements Authenticator {
  get type(): AuthenticatorType {
    return AuthenticatorType.Apple;
  }

  async signIn(): Promise<AuthenticationResult> {
    return AuthenticationResult.error(ErrorType.NotSupported);
  }

  async signUp(): Promise<AuthenticationResult> {
    return AuthenticationResult.error(ErrorType.NotSupported);
  }
}
