import { BillingProfile, isNotNullOrUndefined, isNullOrUndefined, VoidOperationResponse , CustomerAccount } from 'in-time-core';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DatabaseService } from './database.service';
import { User } from '@angular/fire/auth';
import { ErrorType } from '../core/models/error-type';
import { AuthenticationService } from './authentication.service';
import { IAuthenticationPlugin } from './auth/utility/authentication-plugin';
import { AuthenticationState } from './auth/models/authentication-state';

@Injectable({
  providedIn: 'root'
})
export class AccountService implements OnDestroy {
  private readonly authenticationService = inject(AuthenticationService);
  private readonly databaseService = inject(DatabaseService);
  private readonly accountServiceImpl: AccountServiceImpl;

  constructor() {
    this.accountServiceImpl = new AccountServiceImpl(this.databaseService);
    this.authenticationService.addPlugin(this.accountServiceImpl);
  }

  get account$(): Observable<CustomerAccount | null> {
    return this.accountServiceImpl.account$;
  }

  get account(): CustomerAccount | null {
    return this.accountServiceImpl.account;
  }

  get error$(): Observable<ErrorType | null> {
    return this.accountServiceImpl.error$;
  }

  get error(): ErrorType | null {
    return this.accountServiceImpl.error;
  }

  get hasAccount(): boolean {
    return this.accountServiceImpl.hasAccount;
  }

  onNewAccountCreated(account: CustomerAccount): void {
    this.accountServiceImpl.onNewAccountCreated(account);
  }

  async updateBillingProfile(billingProfile: BillingProfile): Promise<VoidOperationResponse> {
    return this.accountServiceImpl.updateBillingProfile(billingProfile);
  }

  ngOnDestroy(): void {
    this.authenticationService.removePlugin(this.accountServiceImpl);
    this.accountServiceImpl.dispose();
  }
}

class AccountServiceImpl implements IAuthenticationPlugin {
  private _accountSnapshots: BehaviorSubject<CustomerAccount | null>;
  private _errorSnapshots: BehaviorSubject<ErrorType | null>;
  private _account: CustomerAccount | null;
  private _error: ErrorType | null;

  constructor(
    private readonly databaseService: DatabaseService
  ) {
    this._error = ErrorType.NotAuthenticated;
    this._account = null;
    this._errorSnapshots = new BehaviorSubject<ErrorType | null>(null);
    this._accountSnapshots = new BehaviorSubject<CustomerAccount | null>(null);
  }

  async onCheckSignIn(user: User): Promise<boolean | null> {
    const response = await this.databaseService.fetchCustomerAccount(user.uid);
    return response.success;
  }

  get account$(): Observable<CustomerAccount | null> {
    return this._accountSnapshots;
  }

  get account(): CustomerAccount | null {
    return this._account;
  }

  get error$(): Observable<ErrorType | null> {
    return this._errorSnapshots;
  }

  get error(): ErrorType | null {
    return this._error;
  }

  get hasAccount(): boolean {
    return this._account != null;
  }

  async onWillSignIn(): Promise<void> {
    // nothing to do here!
  }

  async onSignIn(authState: AuthenticationState): Promise<void> {
    let account: CustomerAccount | null = null;
    let error: ErrorType | null = null;

    if(authState.isAuthenticated && authState.user != null) {
      const response = await this.databaseService.fetchCustomerAccount(authState.user.uid);
      if(response.success) {
        account = response.data;
      }
      else {
        error = response.error as ErrorType;
      }
    }
    else {
      error = ErrorType.NotAuthenticated;
    }

    this.changeAccount(account, error);
  }

  async onSignOut(): Promise<void> {
    this.clearAccount();
  }

  onNewAccountCreated(account: CustomerAccount): void {
    if(isNotNullOrUndefined(account)) {
      this.changeAccount(account, null);
    }
  }

  async updateBillingProfile(billingProfile: BillingProfile): Promise<VoidOperationResponse> {
    if(isNullOrUndefined(this._account)) {
      return VoidOperationResponse.error(ErrorType.NotAuthenticated);
    }

    const response = await this.databaseService.updateBillingProfileForCustomer(this._account.uniqueId, billingProfile);
    if(!response.success) {
      return response;
    }

    this.changeAccount(response.data, null);
    return VoidOperationResponse.success();
  }

  private changeAccount(account: CustomerAccount | null, error: ErrorType | null): void {
    this._error = error;
    this._account = account;

    this._errorSnapshots.next(error);
    this._accountSnapshots.next(account);
  }

  private clearAccount(): void {
    this._error = ErrorType.NotAuthenticated;
    this._account = null;

    this._errorSnapshots.next(this._error);
    this._accountSnapshots.next(this._account);
  }

  dispose(): void {
    this._accountSnapshots.complete();
    this._errorSnapshots.complete();
  }
}