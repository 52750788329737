import URLParse from 'url-parse';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { isNullOrUndefined, OperationResponse } from 'in-time-core';
import { logDebug, logError } from 'in-time-logger';
import { REQUEST } from '../../express.tokens';
import { ErrorType } from '../core/models/error-type';
import { RequestToken } from '../core/utils/type-utils';

@Injectable({
  providedIn: 'root'
})
export class SEOService {
  static readonly kCanonicalHostnameSuffix = 'hu';

  private readonly request: RequestToken | null = inject(REQUEST, { optional: true });
  private readonly platformId = inject(PLATFORM_ID);
  private readonly meta = inject(Meta);
  private readonly _doc = inject(DOCUMENT);

  setCanonicalURLTag() {
    const currentUrl = this.getCurrentUrl();
    if(currentUrl == null) {
      return;
    }

    const parseResponse = this.parseUrl(currentUrl);
    if(!parseResponse.success) {
      return;
    }

    const parseResult = parseResponse.data;
    const canonicalUrl = this.generateCanonicalUrl(parseResult);
    if(isNullOrUndefined(canonicalUrl)) {
      logDebug('Generated canonical url is null, skipping...');
      return;
    }

    logDebug(`Setting canonical url to {${canonicalUrl}}`);
    this.meta.updateTag({ rel: 'canonical', href: canonicalUrl });
  }

  private getCurrentUrl(): string | null {
    if(isPlatformServer(this.platformId)) {
      return this.getCurrentUrlServerSide();
    }
    else {
      return this.getCurrentUrlClientSide();
    }
  }

  private getCurrentUrlServerSide(): string | null {
    if(this.request == null) {
      return null;
    }

    const host = this.request.hostname;
    const protocol = this.request.protocol;
    const port = this.request.port;
    if(isNullOrUndefined(port)) {
      return `${protocol}://${host}${this.request.url}`;
    }

    return `${protocol}://${host}:${port}${this.request.url}`;
  }

  private getCurrentUrlClientSide(): string {
    return this._doc.URL;
  }

  private parseUrl(url: string): OperationResponse<URLParse<string>> {
    try {
      const result = new URLParse(url);
      if(isNullOrUndefined(result)) {
        return OperationResponse.error(ErrorType.InvalidURL);
      }

      return OperationResponse.success(result);
    }
    catch(error) {
      logError(`Failed to parse url {${url}}: `, error);
      return OperationResponse.error(ErrorType.URLParsingFailed);
    }
  }

  private generateCanonicalUrl(url: URLParse<string>): string | null {
    const hostName = url.hostname;
    if(hostName.endsWith(`.${SEOService.kCanonicalHostnameSuffix}`)) {
      return null;
    }

    const parts = hostName.split('.');
    if(parts.length <= 1) {
      return null;
    }

    const lastIndex = parts.length - 1;
    parts[lastIndex] = SEOService.kCanonicalHostnameSuffix;

    const canonicalHostName = parts.join('.');
    url.set('hostname', canonicalHostName);

    const canonicalUrl = url.toString();
    return canonicalUrl;
  }
}