import { Address, AddressComponent, isNullOrUndefined } from 'in-time-core';
import { logError, logWarning } from 'in-time-logger';

export interface BillingAddressDetails {
  country: string | null,
  postalCode: string | null,
  county: string | null,
  city: string | null,
  address: string | null,
}

export function createAddressFromBillingDetails(details: BillingAddressDetails | null): Address | null {
  if(isNullOrUndefined(details)) {
    logWarning('Billing details were not provided when trying to sign up.');
    return null;
  }

  if(isNullOrUndefined(details.country) ||
      isNullOrUndefined(details.postalCode) ||
      isNullOrUndefined(details.county) ||
      isNullOrUndefined(details.city) ||
      isNullOrUndefined(details.address)) {
    logError(`Billing details were incomplete when trying to sign up: ${JSON.stringify(details)}`);
    return null;
  }

  return Address.create({
    country: details.country,
    administrativeAreaLevel1: AddressComponent.fromName(details.county),
    locality: AddressComponent.fromName(details.city),
    postalCode: details.postalCode,
    formattedAddress: details.address,
  });
}