import { AuthError, AuthErrorCodes } from '@angular/fire/auth';
import { isNullOrUndefined } from 'in-time-core';
import { logWarning } from 'in-time-logger';
import { ErrorType } from '../../../core/models/error-type';

function isAuthError(error: unknown): error is AuthError {
  if(typeof error === 'object') {
    if(isNullOrUndefined(error)) {
      return false;
    }

    // TODO(emil): refactor when we can upgrade typescript to at least 4.9.
    return 'code' in error && typeof (error as { code: string }).code === 'string';
  }

  return false;
}

export function parseFirebaseAuthError(error: unknown) {
  if(!isAuthError(error)) {
    return ErrorType.Unknown;
  }

  switch(error.code) {
  case AuthErrorCodes.WEAK_PASSWORD:
    return ErrorType.WeakPassword;
  case AuthErrorCodes.INVALID_EMAIL:
    return ErrorType.InvalidEmail;
  case AuthErrorCodes.EMAIL_EXISTS:
    return ErrorType.EmailAlreadyInUse;
  case AuthErrorCodes.INVALID_IDP_RESPONSE:
    return ErrorType.InvalidCredential;
  case AuthErrorCodes.USER_DISABLED:
    return ErrorType.UserDisabled;
  case AuthErrorCodes.INVALID_PASSWORD:
    return ErrorType.WrongPassword;
  case AuthErrorCodes.USER_DELETED:
    return ErrorType.UserNotFound;
  case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
    return ErrorType.TooManyRequests;
  case AuthErrorCodes.OPERATION_NOT_ALLOWED:
    return ErrorType.OperationNotAllowed;
  case AuthErrorCodes.NEED_CONFIRMATION:
    return ErrorType.AccountExistsWithDifferentCredential;
  case AuthErrorCodes.INVALID_CODE:
    return ErrorType.InvalidPhoneVerificationCode;
  case AuthErrorCodes.INVALID_SESSION_INFO:
    return ErrorType.InvalidVerificationId;
  case AuthErrorCodes.INVALID_PHONE_NUMBER:
    return ErrorType.InvalidPhoneNumber;
  case AuthErrorCodes.NO_SUCH_PROVIDER:
    return ErrorType.NoSuchProvider;
  default:
    logWarning(`Unknown Firebase Auth error code: ${error.code}`);
    return ErrorType.Unknown;
  }
}
