import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SearchUtils } from '../core/utils/search-utils';
import { IQueryHandler } from './contracts/query.handler';

@Injectable({ providedIn: 'root' })
export class SearchService {
  private readonly handlerSnapshots: BehaviorSubject<IQueryHandler | null>;
  private readonly syncSnapshots: Subject<IQueryHandler>;
  private readonly resetSnapshots: Subject<void>;
  private readonly searchHandlerStack: IQueryHandler[];

  get sync$(): Observable<IQueryHandler> {
    return this.syncSnapshots;
  }

  get reset$(): Observable<void> {
    return this.resetSnapshots;
  }

  get handler$(): Observable<IQueryHandler | null> {
    return this.handlerSnapshots;
  }

  get handler(): IQueryHandler | null {
    return this.handlerSnapshots.value;
  }

  constructor() {
    this.handlerSnapshots = new BehaviorSubject<IQueryHandler | null>(null);
    this.syncSnapshots = new Subject<IQueryHandler>();
    this.resetSnapshots = new Subject<void>();
    this.searchHandlerStack = [];
  }

  pushHandler(handler: IQueryHandler): void {
    this.searchHandlerStack.push(handler);
    this.handlerSnapshots.next(handler);
  }

  popHandler(test?: (handler: IQueryHandler) => boolean): IQueryHandler | null {
    if(this.searchHandlerStack.length > 0) {
      const top = this.searchHandlerStack[this.searchHandlerStack.length - 1];
      if(test == null || test(top)) {
        this.searchHandlerStack.pop();
        this.handlerSnapshots.next(this.peekHandler());

        return top;
      }
    }

    return null;
  }

  peekHandler(): IQueryHandler | null {
    const index = this.searchHandlerStack.length - 1;
    return index >= 0 ? this.searchHandlerStack[index] : null;
  }

  search(value: string, sync?: boolean): void {
    const queryHandler = this.peekHandler();
    if(queryHandler == null) {
      return;
    }

    let sanitizedValue = value.trim();
    if(sanitizedValue.length > 0) {
      sanitizedValue = SearchUtils.normalizeForSearch(sanitizedValue);
    }

    if(sanitizedValue.localeCompare(queryHandler.query) != 0) {
      queryHandler.update(sanitizedValue);
      if(sync) {
        this.syncSnapshots.next(queryHandler);
      }
    }
  }

  reset(): void {
    this.resetSnapshots.next();
  }
}