import {
  Auth, signInWithEmailAndPassword, createUserWithEmailAndPassword,
  AuthCredential, EmailAuthProvider,
} from '@angular/fire/auth';

import { AuthenticationResult } from '../models/authentication-result';
import { AuthenticatorType } from '../models/authenticator-type';
import { Authenticator } from './authenticator';
import { parseFirebaseAuthError } from '../models/auth-error-utils';
import { logWarning } from 'in-time-logger';
import { ErrorType } from '../../../core/models/error-type';

export class EmailAndPasswordAuthenticator implements Authenticator {
  constructor(
    private readonly auth: Auth,
    public readonly email: string,
    public readonly password: string,
  ) {}

  get type(): AuthenticatorType {
    return AuthenticatorType.EmailAndPassword;
  }

  async signIn(): Promise<AuthenticationResult> {
    try {
      const authCredential = await signInWithEmailAndPassword(
        this.auth,
        this.email,
        this.password
      );

      return AuthenticationResult.authenticated(authCredential.user, this.type);
    }
    catch(error) {
      const errorType = parseFirebaseAuthError(error);
      if(errorType !== ErrorType.UserNotFound) {
        logWarning(`Failed to sign in with email and password: ${error}`);
      }

      return AuthenticationResult.error(errorType);
    }
  }

  async signUp(): Promise<AuthenticationResult> {
    try {
      const authCredential = await createUserWithEmailAndPassword(
        this.auth,
        this.email,
        this.password
      );

      return AuthenticationResult.authenticated(authCredential.user, this.type);
    }
    catch(error) {
      logWarning(`Failed to sign up with email and password: ${error}`);
      return AuthenticationResult.error(parseFirebaseAuthError(error));
    }
  }

  getAuthCredential(): AuthCredential {
    return EmailAuthProvider.credential(
      this.email,
      this.password
    );
  }
}
