import { ErrorType } from '../../core/models/error-type';

export class StorageUploadResponse {
  private constructor(
    public readonly success: boolean,
    private readonly _downloadUrl: string | null,
    private readonly _storagePath: string | null,
    private readonly _error: ErrorType | null,
  ) {}

  get downloadUrl(): string {
    if(this._downloadUrl != null) {
      return this._downloadUrl;
    }

    throw new Error('Download URL of [StorageUploadResponse] is not available on an error instance.');
  }

  get storagePath(): string {
    if(this._storagePath != null) {
      return this._storagePath;
    }

    throw new Error('Storage path of [StorageUploadResponse] is not available on an error instance.');
  }

  get error(): ErrorType {
    if(this._error != null) {
      return this._error;
    }

    throw new Error('Error of [StorageUploadResponse] is not available on a success instance.');
  }


  static success(storagePath: string, downloadUrl: string): StorageUploadResponse {
    return new StorageUploadResponse(
      true,
      storagePath,
      downloadUrl,
      null
    );
  }

  static error(error: ErrorType): StorageUploadResponse {
    return new StorageUploadResponse(
      false,
      null,
      null,
      error
    );
  }
}
