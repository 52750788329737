import { HttpClient, HttpClientResponse } from 'cloud-functions';
import { SerializedData } from 'in-time-core';

export class CloudFunctionsHttpClient extends HttpClient {
  async post(endpoint: string, headers: Map<string, string>, body: SerializedData): Promise<HttpClientResponse> {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: this.mapToHeaders(headers),
      body: JSON.stringify(body)
    });

    if(response.status === 200) {
      const data = await response.json() as SerializedData;
      return {
        statusCode: response.status,
        data: data,
        headers: this.headersToMap(response.headers),
      };
    }
    else {
      return {
        statusCode: response.status,
        data: null,
        headers: this.headersToMap(response.headers),
      };
    }
  }

  private mapToHeaders(map: Map<string, string>): Record<string, string> {
    const record: Record<string, string> = {};
    for(const [key, value] of map) {
      record[key] = value;
    }

    return record;
  }

  private headersToMap(headers: Headers): Map<string, string> {
    const map: Map<string, string> = new Map<string, string>();
    headers.forEach((v, k) => map.set(k, v));

    return map;
  }
}