import {
  Completer, OperationResponse, VoidOperationResponse, isNotNullOrUndefined, isNullOrUndefined,
} from 'in-time-core';
import {
  ApplicationVerifier, Auth, RecaptchaParameters, RecaptchaVerifier, User,
  linkWithCredential, sendPasswordResetEmail, unlink, updatePassword,
} from '@angular/fire/auth';
import {
  PhoneAuthenticator, PhoneVerificationCallback, SmsCodeVerificationCallback, VerificationCodeCallback,
} from './auth/authenticators/phone-authenticator';
import dayjs from 'dayjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { Duration } from 'dayjs/plugin/duration';
import { IAuthenticationPlugin } from './auth/utility/authentication-plugin';
import { AuthenticationState } from './auth/models/authentication-state';
import { EmailAndPasswordAuthenticator } from './auth/authenticators/email-and-password-authenticator';
import { Authenticator } from './auth/authenticators/authenticator';
import { AuthenticatorType, getAuthenticatorTypeFromProviderId } from './auth/models/authenticator-type';
import { isFirebaseUserDeveloper } from './auth/utility/user-utils';
import { logError, logInfo, logWarning } from 'in-time-logger';
import { parseFirebaseAuthError } from './auth/models/auth-error-utils';
import { GoogleAuthenticator } from './auth/authenticators/google-authenticator';
import { FacebookAuthenticator } from './auth/authenticators/facebook-authenticator';
import { AppleAuthenticator } from './auth/authenticators/apple-authenticator';
import { PhoneVerificationResult } from './auth/models/phone-verification-result';
import { ErrorType } from '../core/models/error-type';
import { Injectable } from '@angular/core';
import { environment } from '../app.environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  protected readonly plugins: IAuthenticationPlugin[];
  protected readonly phoneVerificationTimeout: Duration;

  protected readonly authStateSnapshots: BehaviorSubject<AuthenticationState>;
  protected authenticationState: AuthenticationState;

  constructor(protected readonly firebaseAuth: Auth) {
    this.plugins = [];
    this.phoneVerificationTimeout = environment.production ?
      PhoneAuthenticator.kDefaultPhoneVerificationTimeout:
      dayjs.duration({ seconds: 60 });

    this.authenticationState = AuthenticationState.none();
    this.authStateSnapshots = new BehaviorSubject<AuthenticationState>(this.authenticationState);
  }

  get authState$(): Observable<AuthenticationState> {
    return this.authStateSnapshots;
  }

  get authState(): AuthenticationState {
    return this.authenticationState;
  }

  addPlugin(plugin: IAuthenticationPlugin): void {
    this.plugins.push(plugin);
  }

  removePlugin(plugin: IAuthenticationPlugin): void {
    for(let i = 0; i < this.plugins.length; ++i) {
      if(this.plugins[i] === plugin) {
        this.plugins.splice(i, 1);
        return;
      }
    }
  }

  signInWithEmailAndPassword(email: string, password: string): Promise<AuthenticationState> {
    const authenticator = new EmailAndPasswordAuthenticator(this.firebaseAuth, email, password);
    return this.signInWithAuthenticator(authenticator);
  }

  signUpWithEmailAndPassword(email: string, password: string): Promise<AuthenticationState> {
    const authenticator = new EmailAndPasswordAuthenticator(this.firebaseAuth, email, password);
    return this.signUpWithAuthenticator(authenticator);
  }

  signInWithPhone(args: {
    appVerifier: ApplicationVerifier,
    phoneNumber: string,
    codeRequestedFromUser: SmsCodeVerificationCallback,
    verificationResultRequested: PhoneVerificationCallback,
    codeSentByServer?: VerificationCodeCallback,
    verificationFailed: VoidFunction,
    onCancel: VoidFunction,
    onSignInWillBegin: VoidFunction,
    onSignInWillEnd: VoidFunction,
  }): Promise<AuthenticationState> {
    const authenticator = new PhoneAuthenticator(
      this.firebaseAuth,
      args.appVerifier,
      args.phoneNumber,
      args.codeRequestedFromUser,
      args.verificationResultRequested,
      args.codeSentByServer,
      args.verificationFailed,
      args.onCancel,
      args.onSignInWillBegin,
      args.onSignInWillEnd,
    );

    return this.signInWithAuthenticator(authenticator);
  }

  signInWithGoogle(): Promise<AuthenticationState> {
    const authenticator = new GoogleAuthenticator(this.firebaseAuth);
    return this.signInWithAuthenticator(authenticator);
  }

  signInWithFacebook(): Promise<AuthenticationState> {
    const authenticator = new FacebookAuthenticator(this.firebaseAuth);
    return this.signInWithAuthenticator(authenticator);
  }

  signInWithApple(): Promise<AuthenticationState> {
    const authenticator = new AppleAuthenticator();
    return this.signInWithAuthenticator(authenticator);
  }

  async signInWithAuthenticator(authenticator: Authenticator): Promise<AuthenticationState> {
    if(this.authenticationState.isAuthenticated) {
      return this.authenticationState;
    }

    let authState: AuthenticationState;

    for(const plugin of this.plugins) {
      await plugin.onWillSignIn();
    }

    const signInResult = await authenticator.signIn();
    if(signInResult.success) {
      if(isNullOrUndefined(signInResult.user)) {
        authState = AuthenticationState.error(ErrorType.FirebaseAuthUserNullOrUndefined, [authenticator.type]);
      }
      else {
        const authenticators = this.extractAuthenticatorTypeFromFirebaseUser(signInResult.user);
        const isDeveloper = await isFirebaseUserDeveloper(signInResult.user);

        authState = AuthenticationState.authenticated(signInResult.user, isDeveloper, authenticators);
      }
    }
    else if(signInResult.canceledByUser) {
      authState = AuthenticationState.canceledByUser();
    }
    else {
      authState = AuthenticationState.error(signInResult.error ?? ErrorType.InternalAuthError, [authenticator.type]);
    }

    for(const plugin of this.plugins) {
      await plugin.onSignIn(authState);
    }

    this.pushState(authState);
    return this.authenticationState;
  }

  async signUpWithAuthenticator(authenticator: Authenticator): Promise<AuthenticationState> {
    if(this.authenticationState.isAuthenticated) {
      return this.authenticationState;
    }

    let authState: AuthenticationState;

    for(const plugin of this.plugins) {
      await plugin.onWillSignIn();
    }

    const signUpResult = await authenticator.signUp();
    if(signUpResult.success) {
      if(isNullOrUndefined(signUpResult.user)) {
        authState = AuthenticationState.error(ErrorType.FirebaseAuthUserNullOrUndefined, [authenticator.type]);
      }
      else {
        const authenticators = this.extractAuthenticatorTypeFromFirebaseUser(signUpResult.user);
        const isDeveloper = await isFirebaseUserDeveloper(signUpResult.user);

        authState = AuthenticationState.authenticated(signUpResult.user, isDeveloper, authenticators);
      }
    }
    else if(signUpResult.canceledByUser) {
      authState = AuthenticationState.canceledByUser();
    }
    else {
      authState = AuthenticationState.error(signUpResult.error ?? ErrorType.InternalAuthError, [authenticator.type]);
    }

    for(const plugin of this.plugins) {
      await plugin.onSignIn(authState);
    }

    this.pushState(authState);
    return this.authenticationState;
  }

  async reauthenticateWithEmailAndPassword(email: string, password: string): Promise<VoidOperationResponse> {
    if(!this.authenticationState.isAuthenticated) {
      return VoidOperationResponse.error(ErrorType.NotAuthenticated);
    }

    if(!this.authenticationState.hasAuthenticator(AuthenticatorType.EmailAndPassword)) {
      return VoidOperationResponse.error(ErrorType.InvalidCredential);
    }

    const authenticator = new EmailAndPasswordAuthenticator(this.firebaseAuth, email, password);
    const signInResult = await authenticator.signIn();

    return signInResult.success ?
      VoidOperationResponse.success():
      VoidOperationResponse.error(signInResult.error ?? '');
  }

  async updatePassword(password: string): Promise<VoidOperationResponse> {
    if(!this.authenticationState.isAuthenticated) {
      return VoidOperationResponse.error(ErrorType.NotAuthenticated);
    }

    if(!this.authenticationState.hasAuthenticator(AuthenticatorType.EmailAndPassword)) {
      return OperationResponse.error(ErrorType.InvalidCredential);
    }

    try {
      const user = this.authenticationState.user;
      if(isNullOrUndefined(user)) {
        return VoidOperationResponse.error(ErrorType.FirebaseAuthUserNullOrUndefined);
      }

      await updatePassword(user, password);
      await this.refreshAuthenticationToken();

      return VoidOperationResponse.success();
    }
    catch(error) {
      return VoidOperationResponse.error(parseFirebaseAuthError(error));
    }
  }

  async checkIfSignedIn(): Promise<boolean> {
    if(this.authenticationState.isAuthenticated) {
      return true;
    }

    const checkCurrentlySignedInUser = async () => {
      const user = await this.fetchCurrentlySignedInUser();
      if(isNullOrUndefined(user)) {
        return AuthenticationState.none();
      }

      const authenticators = this.extractAuthenticatorTypeFromFirebaseUser(user);
      if(authenticators.length <= 0) {
        return AuthenticationState.none();
      }

      for(const plugin of this.plugins) {
        const isSignedIn = await plugin.onCheckSignIn(user);
        if(isSignedIn === false) {
          try {
            await this.firebaseAuth.signOut();
          }
          catch(error) {
            logError('Failed to sign out: ', error);
          }

          return AuthenticationState.none();
        }
      }

      const isDeveloper = await isFirebaseUserDeveloper(user);
      return AuthenticationState.authenticated(user, isDeveloper, authenticators);
    };

    const authState = await checkCurrentlySignedInUser();
    for(const plugin of this.plugins) {
      await plugin.onSignIn(authState);
    }

    this.pushState(authState);
    return this.authenticationState.isAuthenticated;
  }

  async signOut(): Promise<void> {
    if(this.authenticationState.isAuthenticated) {
      this.firebaseAuth.signOut().catch((error) => {
        logError('Failed to sign out: ', error);
      });

      for(const plugin of this.plugins) {
        await plugin.onSignOut();
      }

      this.pushState(AuthenticationState.none());
    }
  }

  async refreshAuthenticationToken(): Promise<boolean> {
    let success = false;

    if(this.authenticationState.isAuthenticated) {
      try {
        const user = this.firebaseAuth.currentUser;
        await user?.getIdTokenResult(true);
        success = true;
      }
      catch(error) {
        logError('Failed to refresh authentication token: ', error);
      }
    }
    else {
      logWarning('Failed to refresh authentication token because the user is not signed in.');
    }

    return success;
  }

  async sendPasswordResetEmail(email: string): Promise<VoidOperationResponse> {
    try {
      await sendPasswordResetEmail(this.firebaseAuth, email);
      return VoidOperationResponse.success();
    }
    catch(error) {
      logError('Failed to send password reset email: ', error);
      return VoidOperationResponse.error(parseFirebaseAuthError(error));
    }
  }

  async linkPhoneNumber(args: {
    appVerifier: ApplicationVerifier,
    phoneNumber: string,
    codeRequestedFromUser: SmsCodeVerificationCallback,
    verificationResultRequested: PhoneVerificationCallback,
    codeSentByServer?: VerificationCodeCallback,
    verificationFailed?: VoidFunction,
    onCancel?: VoidFunction,
    onLinkingWillBegin?: VoidFunction,
    onLinkingWillEnd?: VoidFunction,
  }): Promise<VoidOperationResponse> {
    if(!this.authenticationState.isAuthenticated) {
      return VoidOperationResponse.error(ErrorType.NotAuthenticated);
    }
    if(this.authenticationState.hasAuthenticator(AuthenticatorType.Phone)) {
      return VoidOperationResponse.error(ErrorType.PhoneNumberAlreadyInUse);
    }

    let verificationResult = args.verificationResultRequested();

    if(isNullOrUndefined(verificationResult) || !verificationResult.success) {
      verificationResult = await PhoneAuthenticator.verifyPhoneNumber(
        this.firebaseAuth,
        args.appVerifier,
        args.phoneNumber,
        this.phoneVerificationTimeout
      );
      if(!verificationResult.success) {
        return verificationResult.toAsyncResponse();
      }

      if(isNotNullOrUndefined(args.codeSentByServer)) {
        args.codeSentByServer(verificationResult);
      }
    }
    else {
      logInfo('Phone verification ID has been provided and will not be requested again.');
      logInfo('Trying to link phone number with provided verification ID...');
    }

    let linkResponse: VoidOperationResponse | null = null;

    while(isNullOrUndefined(linkResponse)) {
      const smsCode = await args.codeRequestedFromUser();
      if(isNotNullOrUndefined(smsCode)) {
        verificationResult = args.verificationResultRequested() ?? verificationResult;

        const verificationId = verificationResult.verificationId;
        if(isNullOrUndefined(verificationId)) {
          throw new Error('verification id is null or undefined');
        }

        const authCredential = PhoneAuthenticator.createCredential(verificationId, smsCode);
        if(isNotNullOrUndefined(args.onLinkingWillBegin)) {
          args.onLinkingWillBegin();
        }

        try {
          if(isNullOrUndefined(this.authenticationState.user)) {
            throw new Error('user is null or undefined');
          }

          await linkWithCredential(this.authenticationState.user, authCredential);
          this.pushState(AuthenticationState.authenticated(
            this.authenticationState.user,
            this.authenticationState.isDeveloper,
            [...this.authenticationState.authenticators, AuthenticatorType.Phone]
          ));

          linkResponse = VoidOperationResponse.success();
        }
        catch(error) {
          logError('Failed to link phone number: ', error);

          const errorType = parseFirebaseAuthError(error);
          if(errorType === ErrorType.InvalidPhoneVerificationCode) {
            if(isNotNullOrUndefined(args.verificationFailed)) {
              args.verificationFailed();
            }
            linkResponse = null;
          }
          else {
            linkResponse = VoidOperationResponse.error(errorType);
          }
        }

        if(isNotNullOrUndefined(args.onLinkingWillEnd)) {
          args.onLinkingWillEnd();
        }
      }
      else {
        if(isNotNullOrUndefined(args.onCancel)) {
          args.onCancel();
        }
        logWarning('Phone verification canceled by user.');
        linkResponse = VoidOperationResponse.error(ErrorType.CanceledByUser);
      }
    }

    return linkResponse;
  }

  async unlinkPhoneNumber(): Promise<VoidOperationResponse> {
    if(!this.authenticationState.isAuthenticated) {
      return OperationResponse.error(ErrorType.NotAuthenticated);
    }
    if(this.authenticationState.onlyAuthenticator(AuthenticatorType.Phone)) {
      return OperationResponse.error(ErrorType.UnableToUnlinkPhoneNumberWhileSignedIn);
    }

    try {
      if(isNullOrUndefined(this.authenticationState.user)) {
        return VoidOperationResponse.error(ErrorType.FirebaseAuthUserNullOrUndefined);
      }

      await unlink(this.authenticationState.user, AuthenticatorType.Phone);
      this.pushState(AuthenticationState.authenticated(
        this.authenticationState.user,
        this.authenticationState.isDeveloper,
        this.authenticationState.authenticators.filter((e) => e !== AuthenticatorType.Phone),
      ));

      return OperationResponse.success();
    }
    catch(error) {
      return OperationResponse.error(parseFirebaseAuthError(error));
    }
  }

  resendVerificationCode(
    appVerifier: ApplicationVerifier,
    phoneNumber: string,
  ): Promise<PhoneVerificationResult> {
    return PhoneAuthenticator.resendVerificationCode(
      this.firebaseAuth,
      appVerifier,
      phoneNumber,
    );
  }

  dispose(): void {
    this.authStateSnapshots.complete();
  }

  createRecaptchaVerifier(containerOrId: HTMLElement | string, parameters: RecaptchaParameters): RecaptchaVerifier {
    return new RecaptchaVerifier(this.firebaseAuth, containerOrId, parameters);
  }

  protected async fetchCurrentlySignedInUser(): Promise<User | null> {
    const completer = new Completer<User | null>();

    const unsubscribe = this.firebaseAuth.onAuthStateChanged((user) => {
      if(!completer.isCompleted) {
        completer.complete(user);
      }
    });

    const currentUser = await completer.promise;
    unsubscribe();

    try {
      await currentUser?.reload();
    }
    catch(error) {
      logWarning(`Failed to reload Firebase User: ${error}`);
    }

    return currentUser;
  }


  protected pushState(authState: AuthenticationState) {
    this.authenticationState = authState;
    this.authStateSnapshots.next(authState);
    logInfo(`Authentication state changed: ${authState}`);
  }

  protected extractAuthenticatorTypeFromFirebaseUser(user: User): AuthenticatorType[] {
    const authenticators: AuthenticatorType[] = [];

    for(const data of user.providerData) {
      if(data.providerId !== 'firebase') {
        authenticators.push(getAuthenticatorTypeFromProviderId(data.providerId));
      }
    }

    return authenticators;
  }
}
