/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { generateUniqueDatabaseId, isNullOrUndefinedOrEmpty } from 'in-time-core';
import { logError, logInfo } from 'in-time-logger';
import { Renderer2 } from '@angular/core';
import { AnalyticsTracker } from './analytics-tracker';
import {
  AnalyticsTrackerType, CartEventArgs, ContentViewEventArgs, ExploreEventArgs, PageViewEventArgs,
  PromoCodeEventArgs, SearchEventArgs, SignUpEventArgs
} from './analytics.types';

function getGoogleTagManagerCode(tagManagerId: string, dataLayerName: string, elementId: string): string {
  return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.id='${elementId}';j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','${dataLayerName}','${tagManagerId}');`;
}

function getGoogleTagManagerIframeCode(tagManagerId: string, elementId: string): string {
  return `<iframe src="https://www.googletagmanager.com/ns.html?id=${tagManagerId}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
}

function createGoogleTagManager(
  _document: Document,
  renderer: Renderer2,
  tagManagerId: string,
): string {
  const tagId = tagManagerId.substring(4);
  const elementId = `gtm-elem-${tagId}`;
  const dataLayerName = `dataLayer${tagId}`;

  const iframeNoScriptElement = renderer.createElement('noscript');
  renderer.setAttribute(iframeNoScriptElement, 'id', `iframe-${elementId}`);
  renderer.setProperty(iframeNoScriptElement, 'innerHTML', getGoogleTagManagerIframeCode(tagManagerId, elementId));
  renderer.appendChild(_document.body, iframeNoScriptElement);

  const loaderScriptElement = renderer.createElement('script');

  renderer.setAttribute(loaderScriptElement, 'id', `loader-${elementId}`);
  renderer.setAttribute(loaderScriptElement, 'type', 'text/javascript');
  renderer.setProperty(loaderScriptElement, 'innerHTML', getGoogleTagManagerCode(tagManagerId, dataLayerName, elementId));
  renderer.appendChild(_document.head, loaderScriptElement);

  return elementId;
}

function destroyGoogleTagManager(
  _document: Document,
  elementId: string,
): void {
  const iframe = _document.getElementById(`iframe-${elementId}`);
  if(iframe != null) {
    iframe.remove();
  }

  const loader = _document.getElementById(`loader-${elementId}`);
  if(loader != null) {
    loader.remove();
  }

  const tagManager = _document.getElementById(elementId);
  if(tagManager != null) {
    tagManager.remove();
  }
}

export class GtmTracker extends AnalyticsTracker {
  private readonly initializedTagManager: { tagManagerId: string, elementId: string }[] = [];
  private readonly customTagManagerStack: string[] = [];
  private hasAcceptedCookies: boolean = false;
  private hasBeenInitialized: boolean = false;

  public readonly $typeId: AnalyticsTrackerType = 'gtm';

  static create(opts: {
    renderer: Renderer2,
    document: Document,
  }): GtmTracker {
    return new GtmTracker(opts.renderer, opts.document);
  }

  private constructor(
    private readonly renderer: Renderer2,
    private readonly _document: Document,
  ) { super(); }

  onInit(hasAcceptedCookies: boolean): void {
    this.hasAcceptedCookies = hasAcceptedCookies;
    this.hasBeenInitialized = true;
  }

  onDestroy(): void {
    // nothing to do here :)
  }

  onCookiesAccepted(): void {
    this.hasAcceptedCookies = true;
  }

  onCookiesDeclined(): void {
    this.hasAcceptedCookies = false;
  }

  activateCustomTagManager(opts: { context: string, customTagManagerId: string }): void {
    if(!this.hasBeenInitialized || isNullOrUndefinedOrEmpty(opts.customTagManagerId)) return;

    try {
      if(!this.hasCustomTagManager(opts.customTagManagerId)) {
        const elementId = createGoogleTagManager(this._document, this.renderer, opts.customTagManagerId)
        this.initializedTagManager.push({ tagManagerId: opts.customTagManagerId, elementId });
      }

      this.customTagManagerStack.push(opts.customTagManagerId);
      logInfo(`Custom Google Tag Manager has been activated for {${opts.context}}.`);
    }
    catch(error) {
      logError(`Failed to activate custom Google Tag Manager for {${opts.context}}.`, error);
    }
  }

  deactivateCustomTagManager(opts: { context: string, customTagManagerId: string }): void {
    for(let i = this.customTagManagerStack.length - 1; i >= 0; i--) {
      if(this.customTagManagerStack[i] == opts.customTagManagerId) {
        this.destroyTagManager(opts.customTagManagerId);
        this.customTagManagerStack.splice(i, 1);
        logInfo(`Custom Google Tag Manager has been deactivated for {${opts.context}}.`);
        return;
      }
    }
  }

  trackPromoCode(args: PromoCodeEventArgs): void {
    // nothing to do here :)
  }

  trackPageView(args: PageViewEventArgs): void {
    // nothing to do here :)
  }

  trackSignUp(args: SignUpEventArgs): void {
    // nothing to do here :)
  }

  trackSearch(args: SearchEventArgs): void {
    // nothing to do here :)
  }

  trackExplore(args: ExploreEventArgs): void {
    // nothing to do here :)
  }

  trackContentView(args: ContentViewEventArgs): void {
    // nothing to do here :)
  }

  trackAddToCart(args: CartEventArgs): void {
    // nothing to do here :)
  }

  trackInitiateCheckout(args: CartEventArgs): void {
    // nothing to do here :)
  }

  trackCompleteRegistration(args: CartEventArgs): void {
    // nothing to do here :)
  }

  trackInitiatePurchase(args: CartEventArgs): void {
    // nothing to do here :)
  }

  trackPurchaseComplete(args: CartEventArgs): void {
    // nothing to do here :)
  }

  trackPurchaseFailed(args: CartEventArgs): void {
    // nothing to do here :)
  }

  trackPurchaseCanceled(args: CartEventArgs): void {
    // nothing to do here :)
  }

  private hasCustomTagManager(tagManagerId: string): boolean {
    for(let i = 0; i < this.initializedTagManager.length; i++) {
      if(this.initializedTagManager[i].tagManagerId == tagManagerId) {
        return true;
      }
    }

    return false;
  }

  private destroyTagManager(tagManagerId: string): boolean {
    for(let i = this.initializedTagManager.length - 1; i >= 0; i--) {
      if(this.initializedTagManager[i].tagManagerId == tagManagerId) {
        destroyGoogleTagManager(this._document, this.initializedTagManager[i].elementId);
        this.initializedTagManager.splice(i, 1);
        return true;
      }
    }

    return false;
  }
}