import {
  BillingDetailsComponent, ComponentList, ConsentComponent,
  OperationResponse, TrueTime, CustomerAccount, BillingProfile, InvoiceType
} from 'in-time-core';

import { Injectable, inject } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { DatabaseService } from './database.service';
import { PhotoUploadService } from './photo-upload.service';
import { AccountService } from './account.service';
import { SignUpCredentials } from './models/sign-up-credentials';
import { AnalyticsService } from './analytics.service';
import { ErrorType } from '../core/models/error-type';
import { AuthenticationState } from './auth/models/authentication-state';
import { createAddressFromBillingDetails } from '../core/models/billing-address-details';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {
  private readonly analyticsService = inject(AnalyticsService);
  private readonly photoService = inject(PhotoUploadService);
  private readonly databaseService = inject(DatabaseService);
  private readonly authService = inject(AuthenticationService);
  private readonly accountService = inject(AccountService);

  async signUpWithEmailAndPassword(credentials: SignUpCredentials): Promise<OperationResponse<CustomerAccount>> {
    const accountResponse = this.generateCustomerAccount(credentials);
    if(!accountResponse.success) {
      return accountResponse;
    }

    if(this.authService.authState.isAuthenticated) {
      await this.authService.signOut();
    }

    if(credentials.email == null || credentials.password == null) {
      return OperationResponse.error(ErrorType.CredentialsNotValid);
    }

    const authState = await this.authService.signUpWithEmailAndPassword(credentials.email, credentials.password);
    if(!authState.isAuthenticated) {
      return OperationResponse.error(authState.error!);
    }

    return this.createAccount(
      accountResponse.data.copyWith({ uniqueId: authState.user!.uid }),
      authState,
      credentials.marketingConsent,
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async signUpWithSocial(credentials: SignUpCredentials): Promise<OperationResponse<CustomerAccount>> {
    return OperationResponse.error(ErrorType.NotSupported);
  }

  private async createAccount(
    account: CustomerAccount,
    authState: AuthenticationState,
    marketingConsent: boolean | null,
  ): Promise<OperationResponse<CustomerAccount>> {
    const response = await this.databaseService.createAccount(account);
    if(response.success) {
      this.analyticsService.trackSignUp({
        authProviders: authState.authenticators,
        marketingConsent: marketingConsent,
        email: account.email,
        phoneNumber: account.phoneNumber,
      });
      this.accountService.onNewAccountCreated(account);
    }

    return response;
  }

  private generateCustomerAccount(credentials: SignUpCredentials): OperationResponse<CustomerAccount> {
    const account = new CustomerAccount(
      '',
      credentials.firstName,
      credentials.lastName,
      credentials.email,
      credentials.phoneNumber,
      null,
      null,
      TrueTime.now(),
      new ComponentList(),
    );

    if(credentials.marketingConsent != null) {
      const consentComponent = new ConsentComponent(credentials.marketingConsent);
      account.addOrReplaceComponent(consentComponent);
    }

    if(credentials.billingDetails != null) {
      const billingDetails = credentials.billingDetails;
      const address = createAddressFromBillingDetails(billingDetails.address);

      if(billingDetails.invoiceType == InvoiceType.PhysicalPerson) {
        account.addOrReplaceComponent(BillingDetailsComponent.fromProfile(BillingProfile.personal({
          name: billingDetails.billingName,
          email: billingDetails.email,
          phoneNumber: billingDetails.phoneNumber,
          address: address,
        })));
      }
      else if(billingDetails.invoiceType == InvoiceType.LegalEntity) {
        if(billingDetails.phoneNumber == null || billingDetails.taxCode == null || address == null) {
          return OperationResponse.error(ErrorType.BillingDetailsNotValid);
        }

        account.addOrReplaceComponent(BillingDetailsComponent.fromProfile(BillingProfile.legalEntity({
          name: billingDetails.billingName,
          email: billingDetails.email,
          phoneNumber: billingDetails.phoneNumber,
          address: address,
          taxCode: billingDetails.taxCode
        })));
      }
    }

    return OperationResponse.success(account);
  }
}
