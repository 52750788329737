
export enum ErrorType {
  // GENERAL
  None = 'error/none',
  Unknown = 'error/unknown',
  NotImplemented = 'error/not-implemented',
  NotInitialized = 'error/not-initialized',
  NotSupported = 'error/not-supported',
  NotAuthenticated = 'error/not-authenticated',
  NotAuthorized = 'error/not-authorized',
  ResourceNotLoaded = 'error/resource-not-loaded',
  NotRunning = 'error/not-running',
  AlreadyRunning = 'error/already-running',
  CanceledByUser = 'error/canceled-by-user',
  CartIsEmpty = 'error/cart-is-empty',
  TicketIsSoldOut = 'error/ticket-is-sold-out',
  SeatsAlreadyReserved = 'error/seats-already-reserved',
  HttpBadRequest = 'error/http-bad-request',
  IllegalOperation = 'error/illegal-operation',
  GeoLocationNotAvailable = 'error/geolocation-not-available',
  InvalidLocation = 'error/invalid-location',
  InvalidJSON = 'error/invalid-json',
  AccountNotFound = 'error/account-not-found',
  BusinessNotFound = 'error/business-not-found',
  PromoCodeAlreadyApplied = 'error/promo-code-already-applied',
  PromoCodeUserConsentRequired = 'error/promo-code-user-consent-required',
  PromoCodeNotFound = 'error/promo-code-not-found',
  PromoCodeNotValid = 'error/promo-code-not-valid',
  CredentialsNotValid = 'error/credentials-not-valid',
  BillingDetailsNotValid = 'error/billing-details-not-valid',
  PhoneNumberNotValid = 'error/phone-number-not-valid',
  NameNotValid = 'error/name-not-valid',
  EmailNotValid = 'error/email-not-valid',
  PasswordNotValid = 'error/password-not-valid',
  MissingTransferStateKey = 'error/missing-transfer-state-key',
  ArgumentNotValid = 'error/argument-not-valid',
  HasRecentReview = 'error/has-recent-review',
  BillingDetailsRequired = 'error/billing-details-required',
  KeyNotFound = 'error/key-not-found',
  FormNotValid = 'error/form-not-valid',

  OrderHasExpired = 'error/order-has-expired',
  OrderNotValid = 'error/order-not-valid',
  OrderCreationFailed = 'error/order-creation-failed',
  OnlinePaymentNotSupported = 'error/online-payment-not-supported',
  CheckoutRequestResultNotValid = 'error/checkout-request-result-not-valid',
  GatewayUrlMissing = 'error/gateway-url-missing',
  CurrencyNotSupported = 'error/currency-not-supported',
  NoLongerEligible = 'error/no-longer-eligible',

  InvalidURL = 'error/invalid-url',
  URLParsingFailed = 'error/url-parsing-failed',

  // AUTH
  InternalAuthError = 'auth/internal-auth-error',
  AlreadySignedInWithEmail = 'error/already-signed-in-with-email',
  AlreadySingedInWithPhone = 'error/already-signed-in-with-phone',
  AlreadySignedInWithGoogle = 'error/already-signed-in-with-google',
  AlreadySignedInWithFacebook = 'error/already-signed-in-with-facebook',
  FacebookSignInUnavailable = 'error/facebook-sign-in-unavailable',
  GoogleSignInUnavailable = 'error/google-sign-in-unavailable',
  WeakPassword = 'error/weak-password',
  InvalidEmail = 'error/invalid-email',
  EmailAlreadyInUse = 'error/email-already-in-use',
  InvalidCredential = 'error/invalid-credential',
  UserDisabled = 'error/user-disabled',
  WrongPassword = 'error/wrong-password',
  UserNotFound = 'error/user-not-found',
  TooManyRequests = 'error/too-many-requests',
  OperationNotAllowed = 'error/operation-not-allowed',
  AccountExistsWithDifferentCredential = 'error/account-exists-with-different-credential',
  InvalidPhoneVerificationCode = 'error/invalid-phone-verification-code',
  InvalidVerificationId = 'error/invalid-verification-id',
  InvalidPhoneNumber = 'error/invalid-phone-number',
  NoSuchProvider = 'error/no-such-provider',
  PhoneVerificationTimeout = 'error/phone-verification-timeout',
  InternalPhoneAuthError = 'error/internal-phone-auth-error',
  FirebaseAuthUserNullOrUndefined = 'error/firebase-auth-user-null-or-undefined',
  PhoneNumberAlreadyInUse = 'error/phone-number-already-in-use',
  UnableToUnlinkPhoneNumberWhileSignedIn = 'error/unable-to-unlink-phone-number-while-signed-in',
  InvalidGoogleLogin = 'error/invalid-google-login',
  InvalidFacebookLogin = 'error/invalid-facebook-login',

  // API
  ApiInternalError = 'error/api-internal-error',

  // PAYMENT
  PaymentInternalError = 'payment-error/internal-error',
  PaymentOrderNotFound = 'payment-error/order-not-found',
  PaymentOrderNotValid = 'payment-error/order-not-valid',
  PaymentOrderAlreadyPayed = 'payment-error/order-already-payed',
  PaymentAlreadyStarted = 'payment-error/payment-already-started',
  PaymentCurrencyNotSupported = 'payment-error/order-currency-not-supported',
  PaymentAccountNotConfigured = 'payment-error/payment-account-not-configured',
  PaymentAccountNotSupported = 'payment-error/payment-account-not-supported',
  PaymentBillingDetailsNotValid = 'payment-error/billing-details-not-valid',
  PaymentTicketIsSoldOut = 'payment-error/ticket-is-sold-out',
  PaymentTooManyAttempts = 'payment-error/too-many-attempts',
  PaymentTypeNotSupported = 'payment-error/payment-type-not-supported',

  // FIRESTORE
  DocumentNotFound = 'error/document-not-found',
  DatabaseError = 'error/database-error',

  // STORAGE
  FailedToDeleteFileFromStorage = 'error/failed-to-delete-file-from-storage',
  FailedToUploadFileToStorage = 'error/failed-to-upload-file-to-storage',

  // CLOUD FUNCTIONS
  FailedToCallCloudFunction = 'error/failed-to-call-cloud-function',

  // SEATING
  MustNotLeaveHolesInSeatingSelection = 'error/must-not-leave-holes-in-seating-selection',
  MustSelectAtLeastOneSeat = 'error/seating-selection-empty',
}

export function parsePaymentError(error: string): ErrorType {
  switch(error) {
  case 'payment-error/order-not-found':
    return ErrorType.PaymentOrderNotFound;
  case 'payment-error/order-not-valid':
    return ErrorType.PaymentOrderNotValid;
  case 'payment-error/order-already-payed':
    return ErrorType.PaymentOrderAlreadyPayed;
  case 'payment-error/payment-already-started':
    return ErrorType.PaymentAlreadyStarted;
  case 'payment-error/order-currency-not-supported':
    return ErrorType.PaymentCurrencyNotSupported;
  case 'payment-error/payment-account-not-configured':
    return ErrorType.PaymentAccountNotConfigured;
  case 'payment-error/payment-account-not-supported':
    return ErrorType.PaymentAccountNotSupported;
  case 'payment-error/billing-details-not-valid':
    return ErrorType.PaymentBillingDetailsNotValid;
  case 'payment-error/ticket-is-sold-out':
    return ErrorType.PaymentTicketIsSoldOut;
  case 'payment-error/too-many-payment-attempts':
    return ErrorType.PaymentTooManyAttempts;
  default:
    return ErrorType.PaymentInternalError;
  }
}

export function parseInTimeApiError(error: string): ErrorType {
  switch(error) {
  case 'api/unavailable':
    return ErrorType.ApiInternalError;
  case 'api/internal-error':
    return ErrorType.ApiInternalError;
  case 'api/not-authorized':
    return ErrorType.NotAuthorized;
  case 'api/not-implemented':
    return ErrorType.NotImplemented;
  case 'api/not-supported':
    return ErrorType.NotSupported;
  case 'api/bad-request':
    return ErrorType.HttpBadRequest;
  case 'api/database-error':
    return ErrorType.DatabaseError;
  case 'api/document-not-found':
    return ErrorType.DocumentNotFound;
  case 'api/ticket-is-sold-out':
    return ErrorType.TicketIsSoldOut;
  case 'api/order-not-valid':
    return ErrorType.OrderNotValid;
  case 'api/order-has-expired':
    return ErrorType.OrderHasExpired;
  case 'api/seats-already-reserved':
    return ErrorType.SeatsAlreadyReserved;
  case 'api/currency-not-supported':
    return ErrorType.CurrencyNotSupported;
  case 'api/no-longer-eligible':
    return ErrorType.NoLongerEligible;
  default:
    return ErrorType.Unknown;
  }
}
