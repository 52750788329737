import { toEnumFromString } from 'in-time-core';

export enum AuthenticatorType {
  EmailAndPassword = 'password',
  Google = 'google.com',
  Facebook = 'facebook.com',
  Apple = 'apple.com',
  Phone = 'phone',
}

export function getAuthenticatorTypeFromProviderId(providerId: string): AuthenticatorType {
  return toEnumFromString(providerId, AuthenticatorType);
}
