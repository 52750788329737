import { DocumentReference, CollectionReference, Firestore, collection, doc } from '@angular/fire/firestore';
import { BusinessModuleRef , BusinessModuleType , fromEnumAsString } from 'in-time-core';

export class FirestoreDocumentLocator {
  constructor(private readonly firestore: Firestore) {
  }

  get customerAccounts(): CollectionReference {
    return collection(this.firestore, 'db/v2/data/customer/accounts');
  }

  get partnerAccounts(): CollectionReference {
    return collection(this.firestore, 'db/v2/data/partner/accounts');
  }

  get businessRoutes(): CollectionReference {
    return collection(this.firestore, 'db/v2/data/client-web/routes');
  }

  get businesses(): CollectionReference {
    return collection(this.firestore, 'db/v2/data/client/businesses');
  }

  get eventProfiles(): CollectionReference {
    return collection(this.firestore, 'db/v2/data/client/events');
  }

  get redeemableCodes(): CollectionReference {
    return collection(this.firestore, 'db/v2/data/client/redeemable');
  }

  get metadata(): CollectionReference {
    return collection(this.firestore, 'db/v2/data/metadata');
  }

  get discover(): CollectionReference {
    return collection(this.firestore, '/db/v2/data/client-web/discover');
  }

  get news(): CollectionReference {
    return collection(this.firestore, '/db/v2/data/client-web/news');
  }

  get emailVerificationCodes(): CollectionReference {
    return collection(this.firestore, 'db/v2/data/cloud/email_verification_codes');
  }

  get featuredBusinesses(): DocumentReference {
    return doc(this.firestore, 'db/v2/data/metadata/misc/featured');
  }

  get organizations(): CollectionReference {
    return collection(this.firestore, 'db/v2/data/admin/organizations');
  }

  private organization(organizationId: string): DocumentReference {
    return doc(this.organizations, organizationId);
  }

  private businessModule(businessRef: BusinessModuleRef): DocumentReference {
    if(businessRef.moduleType === BusinessModuleType.Legacy) {
      throw new Error(`BusinessModuleRef ${businessRef} is not supported with modular Firestore document locator.`);
    }

    if(!businessRef.organizationId) {
      throw new Error(`organizationId is not defined for ${businessRef}`);
    }

    const org = this.organization(businessRef.organizationId);
    return doc(collection(org, 'admin-'+fromEnumAsString(businessRef.moduleType)), businessRef.uniqueId);
  }

  private businessProfileMetadata(businessRef: BusinessModuleRef, documentId: string): DocumentReference {
    return doc(collection(this.businessModule(businessRef), 'metadata'), documentId);
  }

  private eventBackendMetadata(businessRef: BusinessModuleRef, eventId: string, documentId: string): DocumentReference {
    return doc(this.businessModule(businessRef), `events/${eventId}/backend/v1/metadata/${documentId}`);
  }

  private eventMetadata(businessRef: BusinessModuleRef, eventId: string, documentId: string): DocumentReference {
    return doc(this.businessModule(businessRef), `events/${eventId}/metadata/${documentId}`);
  }

  private get payments(): CollectionReference {
    return collection(this.firestore, 'db/v2/data/cloud/payments');
  }

  userAgentLog(document: string): DocumentReference {
    return doc(this.firestore, `db/v2/logs/in-time/user_agents/${document}`);
  }

  redeemableCode(redeemableCodeId: string): DocumentReference {
    return doc(this.redeemableCodes, redeemableCodeId);
  }

  business(businessId: string): DocumentReference {
    return doc(this.businesses, businessId);
  }

  customerAccount(userId: string): DocumentReference {
    return doc(this.customerAccounts, userId);
  }

  cloudSaveData(userId: string): DocumentReference {
    return doc(collection(this.customerAccount(userId), 'sync'), 'cloud_data');
  }

  cloudPrefs(userId: string): DocumentReference {
    return doc(this.firestore, `db/v2/data/cloud/user_prefs/${userId}`);
  }

  private customerAccountMetadata(userId: string): CollectionReference {
    return collection(this.customerAccount(userId), 'metadata');
  }

  customerSubscriptions(userId: string): DocumentReference {
    return doc(this.customerAccountMetadata(userId), 'customer_profiles')
  }

  customerInvites(userId: string): DocumentReference {
    return doc(this.customerAccountMetadata(userId), 'invites')
  }

  partnerAccount(userId: string): DocumentReference {
    return doc(this.partnerAccounts, userId);
  }

  businessRoute(routeId: string): DocumentReference {
    return doc(this.businessRoutes, routeId);
  }

  staffMembers(businessRef: BusinessModuleRef): CollectionReference {
    return collection(this.businessModule(businessRef), 'staff');
  }

  services(businessRef: BusinessModuleRef): CollectionReference {
    return collection(this.businessModule(businessRef), 'services');
  }

  customers(businessRef: BusinessModuleRef): CollectionReference {
    return collection(this.businessModule(businessRef), 'customers');
  }

  customer(businessRef: BusinessModuleRef, customerId: string): DocumentReference {
    return doc(this.customers(businessRef), customerId);
  }

  staffSyncEvents(businessRef: BusinessModuleRef): CollectionReference {
    return collection(this.businessModule(businessRef), 'sync_events');
  }

  staffSyncEvent(businessRef: BusinessModuleRef, eventId: string): DocumentReference {
    return doc(this.staffSyncEvents(businessRef), eventId);
  }

  customerOrders(userId: string): CollectionReference {
    return collection(doc(this.customerAccounts, userId), 'orders');
  }

  customerOrder(userId: string, orderId: string): DocumentReference {
    return doc(this.customerOrders(userId), orderId);
  }

  customerSyncEvents(userId: string): CollectionReference {
    return collection(doc(this.customerAccounts, userId), 'sync_events');
  }

  customerSyncEvent(userId: string, eventId: string): DocumentReference {
    return doc(this.customerSyncEvents(userId), eventId);
  }

  reviews(businessRef: BusinessModuleRef): CollectionReference {
    return collection(this.businessModule(businessRef), 'reviews');
  }

  review(businessRef: BusinessModuleRef, reviewId: string): DocumentReference {
    return doc(this.reviews(businessRef), reviewId);
  }

  reviewStatistics(businessRef: BusinessModuleRef): DocumentReference {
    return this.businessProfileMetadata(businessRef, 'review_statistics');
  }

  eventProfile(eventId: string): DocumentReference {
    return doc(this.eventProfiles, eventId);
  }

  dynamicPricingSettings(businessRef: BusinessModuleRef, eventId: string): DocumentReference {
    return this.eventMetadata(businessRef, eventId, 'dynamic_pricing_settings');
  }

  productPaymentSettings(businessRef: BusinessModuleRef): DocumentReference {
    return this.businessProfileMetadata(businessRef, 'product_payment_settings');
  }

  private tickets(businessRef: BusinessModuleRef, eventId: string): CollectionReference {
    return collection(this.businessModule(businessRef), `events/${eventId}/tickets`);
  }

  ticket(businessRef: BusinessModuleRef, eventId: string, ticketId: string): DocumentReference {
    return doc(this.tickets(businessRef, eventId), ticketId);
  }

  seatingLayout(businessRef: BusinessModuleRef, eventId: string, layoutId: string): DocumentReference {
    return doc(collection(this.businessModule(businessRef), `events/${eventId}/seating`), layoutId);
  }

  ticketPool(businessRef: BusinessModuleRef, eventId: string): DocumentReference {
    return this.eventBackendMetadata(businessRef, eventId, 'ticket_pool');
  }

  seatSelectionLock(businessRef: BusinessModuleRef, eventId: string): DocumentReference {
    return this.eventBackendMetadata(businessRef, eventId, 'seat_selection_lock');
  }

  private businessOrders(businessRef: BusinessModuleRef): CollectionReference {
    return collection(this.businessModule(businessRef), 'orders');
  }

  businessOrder(businessRef: BusinessModuleRef, orderId: string): DocumentReference {
    return doc(this.businessOrders(businessRef), orderId);
  }

  private eventOrders(businessRef: BusinessModuleRef, eventId: string): CollectionReference {
    return collection(this.businessModule(businessRef), `events/${eventId}/orders`);
  }

  eventOrder(businessRef: BusinessModuleRef, eventId: string, orderId: string): DocumentReference {
    return doc(this.eventOrders(businessRef, eventId), orderId);
  }

  emailVerificationCode(uniqueId: string): DocumentReference {
    return doc(this.emailVerificationCodes, uniqueId);
  }

  paymentIntent(eventId: string): DocumentReference {
    return doc(this.payments, eventId);
  }

  promoCodes(businessRef: BusinessModuleRef): CollectionReference {
    return collection(this.businessModule(businessRef), 'promo_codes');
  }

  promoCode(businessRef: BusinessModuleRef, promoCode: string): DocumentReference {
    return doc(this.promoCodes(businessRef), promoCode);
  }

  private vouchers(businessRef: BusinessModuleRef): CollectionReference {
    return collection(this.businessModule(businessRef), 'vouchers');
  }

  voucher(businessRef: BusinessModuleRef, promoCode: string): DocumentReference {
    return doc(this.vouchers(businessRef), promoCode);
  }

  get homePageInfo(): DocumentReference {
    return doc(this.firestore, 'db/v2/data/client-web/metadata/home_page_info');
  }

  get searchSnapshot(): DocumentReference {
    return doc(this.firestore, 'db/v2/data/client-web/metadata/search_snapshot');
  }

  get taxIdentificationRequests(): CollectionReference {
    return collection(this.firestore, 'db/v2/data/cloud/tax_identification_requests');
  }

  taxIdentificationRequest(uniqueId: string): DocumentReference {
    return doc(this.taxIdentificationRequests, uniqueId);
  }
}
