export const GA_EVT_STD_PAGE_VIEW = 'page_view';
export const GA_EVT_STD_ADD_TO_CART = 'add_to_cart';
export const GA_EVT_STD_PURCHASE = 'purchase';
export const GA_EVT_CTM_COOKIES_ACCEPTED = 'cookies_accepted';
export const GA_EVT_CTM_COOKIES_DECLINED = 'cookies_declined';
export const GA_EVT_CTM_PROMO_CODE_ENTERED = 'promo_code_entered';
export const GA_EVT_CTM_SIGN_UP = 'sign_up';
export const GA_EVT_CTM_INITIATE_CHECKOUT = 'initiate_checkout';
export const GA_EVT_CTM_INITIATE_PURCHASE = 'initiate_purchase';
export const GA_EVT_CTM_CONTENT_VIEW = 'view_content';
export const GA_EVT_CTM_SEARCH = 'search';
export const GA_EVT_CTM_EXPLORE = 'explore';
export const GA_EVT_CTM_COMPLETE_REGISTRATION = 'complete_registration';
export const GA_EVT_CTM_PURCHASE_FAILED = 'purchase_failed';
export const GA_EVT_CTM_PURCHASE_CANCELED = 'purchase_canceled';

export type GA_EVT = typeof GA_EVT_CTM_COOKIES_ACCEPTED
| typeof GA_EVT_CTM_COOKIES_DECLINED
| typeof GA_EVT_STD_PAGE_VIEW
| typeof GA_EVT_STD_ADD_TO_CART
| typeof GA_EVT_STD_PURCHASE
| typeof GA_EVT_CTM_PROMO_CODE_ENTERED
| typeof GA_EVT_CTM_SIGN_UP
| typeof GA_EVT_CTM_INITIATE_CHECKOUT
| typeof GA_EVT_CTM_INITIATE_PURCHASE
| typeof GA_EVT_CTM_CONTENT_VIEW
| typeof GA_EVT_CTM_SEARCH
| typeof GA_EVT_CTM_COMPLETE_REGISTRATION
| typeof GA_EVT_CTM_PURCHASE_FAILED
| typeof GA_EVT_CTM_PURCHASE_CANCELED
| typeof GA_EVT_CTM_EXPLORE;

// https://developers.facebook.com/docs/meta-pixel/reference
export const FB_EVT_STD_INITIATE_CHECKOUT = 'InitiateCheckout';
export const FB_EVT_STD_ADD_TO_CART = 'AddToCart';
export const FB_EVT_STD_PURCHASE = 'Purchase';
export const FB_EVT_STD_SUBSCRIBE = 'Subscribe';
export const FB_EVT_STD_SEARCH = 'Search';
export const FB_EVT_STD_LEAD = 'Lead';
export const FB_EVT_STD_COMPLETE_REGISTRATION = 'CompleteRegistration';
export const FB_EVT_STD_VIEW_CONTENT = 'ViewContent';
export const FB_EVT_STD_PAGE_VIEW = 'PageView';
export const FB_EVT_CTM_INITIATE_PURCHASE = 'InitiatePurchase';

export type FB_EVT = typeof FB_EVT_STD_INITIATE_CHECKOUT
| typeof FB_EVT_STD_ADD_TO_CART
| typeof FB_EVT_STD_PURCHASE
| typeof FB_EVT_STD_SUBSCRIBE
| typeof FB_EVT_STD_SEARCH
| typeof FB_EVT_STD_LEAD
| typeof FB_EVT_STD_COMPLETE_REGISTRATION
| typeof FB_EVT_STD_VIEW_CONTENT
| typeof FB_EVT_STD_PAGE_VIEW
| typeof FB_EVT_CTM_INITIATE_PURCHASE;

// https://docs.barion.com/Barion_Pixel_API_reference
export const BARION_EVT_STD_ADD_TO_CART = 'addToCart';
export const BARION_EVT_STD_INITIATE_CHECKOUT = 'initiateCheckout';
export const BARION_EVT_STD_INITIATE_PURCHASE = 'initiatePurchase';
export const BARION_EVT_STD_PURCHASE = 'purchase';

export type BARION_EVT = typeof  BARION_EVT_STD_ADD_TO_CART
| typeof BARION_EVT_STD_INITIATE_CHECKOUT
| typeof BARION_EVT_STD_INITIATE_PURCHASE
| typeof BARION_EVT_STD_PURCHASE;

// https://business-api.tiktok.com/portal/docs?rid=5ipocbxyw8v&id=1739585702922241
export const TTK_EVT_CTM_PROMO_CODE = 'PromoCode';
export const TTK_EVT_STD_SEARCH = 'Search';
export const TTK_EVT_STD_VIEW_CONTENT = 'ViewContent';
export const TTK_EVT_STD_ADD_TO_CART = 'AddToCart';
export const TTK_EVT_STD_INITIATE_CHECKOUT = 'InitiateCheckout';
export const TTK_EVT_STD_COMPLETE_REGISTRATION = 'CompleteRegistration';
export const TTK_EVT_STD_INITIATE_PURCHASE = 'PlaceAnOrder';
export const TTK_EVT_STD_PURCHASE = 'CompletePayment';

export type TTK_EVT = typeof  TTK_EVT_CTM_PROMO_CODE
| typeof TTK_EVT_STD_SEARCH
| typeof TTK_EVT_STD_VIEW_CONTENT
| typeof TTK_EVT_STD_ADD_TO_CART
| typeof TTK_EVT_STD_INITIATE_CHECKOUT
| typeof TTK_EVT_STD_COMPLETE_REGISTRATION
| typeof TTK_EVT_STD_INITIATE_PURCHASE
| typeof TTK_EVT_STD_PURCHASE;